import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Chip, SxProps, Theme, Typography, useTheme } from '@mui/material';

import CommonButton from '../../../components/buttons/CommonButton';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { getSettlementDetails, paySettlement } from '../../../api/paymentsApi/settlementsApi';
import { useParams } from 'react-router-dom';
import { SettlementDetailsData } from '../types';
import Loader from '../../../components/Loader';
import useSnackBar from '../../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  coloredHeadlineText: {
    color: theme.palette.info.main,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    fontFamily: 'Poppins',
  },
  settledAmountText: {
    color: theme.palette.common.white,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    fontFamily: 'Poppins',
  },
  accountText: {
    color: theme.palette.common.white,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    fontFamily: 'Poppins',
  },
  commonText: {
    color: theme.palette.common.white,
    fontWeight: 500,
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  fontSize12: {
    fontSize: '12px',
  },
  fontSize14: {
    fontSize: '14px',
  },
  fontSize16: {
    fontSize: '16px',
  },
  fontSize20: {
    fontSize: '20px',
  },
  fontSize24: {
    fontSize: '24px',
  },
  fontSize32: {
    fontSize: '32px',
  },
  fontFamilyPoppins: {
    fontFamily: 'Poppins',
  },
  fontFamilyInter: {
    fontFamily: 'Inter',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  textGrayColor: {
    color: theme.palette.common.white,
    opacity: 0.5,
  },
  detailsInnerContainer: {
    display: 'flex',
    flexBasis: '1100px',
  },
  settlementDetailsContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  settlementDetailsSection: {
    display: 'flex',
    padding: '32px',
    background: theme.palette.primary.light,
    borderRadius: '16px',
    boxSizing: 'border-box',
    flexBasis: '908px',
    gap: '32px',

    '@media (max-width: 1300px)': {
      flexDirection: 'column',
      gap: '64px',
    },

    '@media (max-width: 900px)': {
      padding: '12px',
      flexBasis: 0,
    },
  },
  accountInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flexBasis: '300px',
    width: '100%',

    '@media (max-width: 1300px)': {
      flexBasis: 0,
    },
  },
  statusChip: {
    padding: '4px 12px',
    borderRadius: '12px',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '120%',
    maxHeight: '25px',

    '& .MuiChip-label': {
      padding: 0,
    },
  },
  distributionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '480px',
    width: '100%',

    '@media (max-width: 1300px)': {
      flexBasis: 0,
    },
  },
  distributionContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: '10px',
  },
  distributionStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  distributionsSectionDivider: {
    height: '1px',
    background: theme.palette.secondary.dark,
    margin: '20px 0',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '168px',
    width: '100%',
  },
  formSectionsDivider: {
    width: '1px',
    background: theme.palette.secondary.dark,
    height: '100%',

    '@media (max-width: 1300px)': {
      display: 'none',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '400px',
    width: '100%',
    justifyContent: 'center',
  },
  noDetailsTextBlock: {
    display: 'flex',
    minHeight: '400px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: '#35394B',
    borderRadius: '16px',
    padding: '32px',
  },
  noDetailsText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    color: theme.palette.common.white,
  },
  responsiveDistributionContainer: {
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },

    '@media (max-width: 550px)': {
      flexDirection: 'row',
    },
  },
  errorMessageContainer: {
    display: 'flex',
    padding: '16px',
    background: theme.palette.primary.light,
    width: '100%',
    margin: '12px 0',
    boxSizing: 'border-box',
    borderRadius: '16px',
    wordBreak: 'break-word',
  },
  errorMessageText: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '120%',
    color: theme.palette.error.main,
  },
}));

const SettlementsDetailsView = () => {
  const theme = useTheme();
  const { settlementId } = useParams();
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [settlementDetails, setSettlementDetails] = useState<SettlementDetailsData>();
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState<boolean>(false);
  const [isPaymentDetailsFetching, setIsPaymentDetailsFetching] = useState<boolean>(true);

  const FundingStatusChipColor = {
    NotStarted: theme.palette.warning.main,
    Error: theme.palette.error.main,
    CriticalError: theme.palette.error.main,
    InProgress: theme.palette.warning.main,
    Completed: theme.palette.success.main,
  };

  const DistributionStatusChipColor = {
    Unpaid: theme.palette.warning.main,
    PaymentInProgress: theme.palette.warning.main,
    CriticalError: theme.palette.error.main,
    Error: theme.palette.error.main,
    Paid: theme.palette.success.main,
  };

  const getFundingStatusChipStyles = () => {
    const chipColor =
      FundingStatusChipColor[
        settlementDetails?.funding.status as keyof typeof FundingStatusChipColor
      ];

    return {
      color: chipColor,
      border: `1px solid ${chipColor}`,
      background: `${chipColor}1A`,
    } as SxProps<Theme> | undefined;
  };

  const getDistributionStatusChipStyles = (status: string) => {
    const chipColor =
      DistributionStatusChipColor[status as keyof typeof DistributionStatusChipColor];

    return {
      color: chipColor,
      border: `1px solid ${chipColor}`,
      background: `${chipColor}1A`,
    } as SxProps<Theme> | undefined;
  };

  const handleRetryPayProcess = () => {
    setIsPaymentDetailsFetching(true);

    const mappedDeductions = settlementDetails?.distributions.deductions?.map((deduction) =>
      Number(deduction.id),
    );

    paySettlement(settlementId as string, mappedDeductions as number[])
      .then((res) => {
        if (res.status === 202) {
          setIsPaymentDetailsFetching(false);
          setAlert((prev) => ({
            ...prev,
            message: 'The settlement payment process has been initiated.',
            type: 'success',
          }));

          setTimeout(() => window.location.reload(), 500);
        }
      })
      .catch((err) => {
        setIsPaymentDetailsFetching(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  useEffect(() => {
    getSettlementDetails(settlementId as string)
      .then((res) => {
        if (res.data) {
          setSettlementDetails(res.data);
          setIsPaymentDetailsFetching(false);
        }
      })
      .catch(() => {
        setIsPaymentDetailsFetching(false);
        setSettlementDetails(undefined);
      });
  }, [settlementId]);

  if (isPaymentDetailsFetching) {
    return (
      <Box className={classes.loaderContainer}>
        <Loader colorType="warning" />
      </Box>
    );
  }

  return (
    <>
      {!!settlementDetails?.error?.reason.length && (
        <Box className={classes.errorMessageContainer}>
          <Typography className={classes.errorMessageText}>
            {settlementDetails.error.reason}
          </Typography>
        </Box>
      )}
      <Box className={classes.pageContainer}>
        {settlementDetails === undefined ? (
          <Box className={classes.noDetailsTextBlock}>
            <Typography className={classes.noDetailsText}>
              No data is currently available. Transaction details will be displayed once a
              settlement is initiated for payment.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.detailsInnerContainer}>
            <Box className={classes.settlementDetailsContainer}>
              <Box className={classes.settlementDetailsSection}>
                <Box className={classes.accountInfoContainer}>
                  <Box display="flex" justifyContent="space-between" pb="7px" alignItems="center">
                    <Typography
                      className={clsx(
                        classes.commonText,
                        classes.fontSize20,
                        classes.fontFamilyPoppins,
                        classes.fontWeight700,
                      )}
                      lineHeight="120%"
                    >
                      Funding
                    </Typography>
                    <Chip
                      className={classes.statusChip}
                      label={settlementDetails?.funding.status}
                      sx={getFundingStatusChipStyles()}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="12px" pb="8px">
                    <Typography className={classes.coloredHeadlineText}>Case Account</Typography>
                    <Typography className={classes.accountText}>
                      {settlementDetails?.funding.caseAccountNumber}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    <Typography
                      className={clsx(
                        classes.settledAmountText,
                        classes.fontSize14,
                        classes.textGrayColor,
                      )}
                    >
                      Settled Amount
                    </Typography>
                    <Typography
                      className={clsx(
                        classes.accountText,
                        classes.fontSize32,
                        classes.fontWeight700,
                      )}
                      lineHeight="normal"
                    >
                      {numberToUSDCurrency(settlementDetails?.settledAmount)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.formSectionsDivider} />

                <Box className={classes.distributionsContainer}>
                  <Typography
                    className={clsx(
                      classes.commonText,
                      classes.fontSize20,
                      classes.fontWeight700,
                      classes.fontFamilyPoppins,
                    )}
                    lineHeight="120%"
                    pb="7px"
                    mb="20px"
                  >
                    Distributions
                  </Typography>
                  <Box className={classes.distributionContainer}>
                    <Typography
                      className={clsx(
                        classes.commonText,
                        classes.fontSize20,
                        classes.fontWeight700,
                        classes.fontFamilyInter,
                      )}
                      lineHeight="32px"
                    >
                      Attorneys’ Fees
                    </Typography>
                    <Box className={classes.distributionStatusContainer}>
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize20,
                          classes.fontWeight700,
                          classes.fontFamilyPoppins,
                        )}
                        lineHeight="120%"
                      >
                        {numberToUSDCurrency(
                          settlementDetails?.distributions.attorneysFees?.totalAmount,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className={clsx(
                      classes.distributionContainer,
                      classes.responsiveDistributionContainer,
                    )}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize14,
                          classes.fontWeight700,
                          classes.fontFamilyInter,
                        )}
                        lineHeight="120%"
                      >
                        {settlementDetails?.distributions.attorneysFees?.leadFirm?.name}
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize14,
                          classes.fontWeight500,
                          classes.fontFamilyPoppins,
                          classes.textGrayColor,
                        )}
                        lineHeight="normal"
                      >
                        Lead Counsel
                      </Typography>
                    </Box>

                    <Box className={classes.distributionStatusContainer}>
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize16,
                          classes.fontWeight700,
                          classes.fontFamilyPoppins,
                        )}
                        lineHeight="120%"
                      >
                        {numberToUSDCurrency(
                          settlementDetails?.distributions.attorneysFees?.leadFirm?.amount,
                        )}
                      </Typography>
                      <Chip
                        className={classes.statusChip}
                        label={settlementDetails?.distributions.attorneysFees?.leadFirm?.status}
                        sx={getDistributionStatusChipStyles(
                          settlementDetails?.distributions.attorneysFees?.leadFirm
                            ?.status as string,
                        )}
                      />
                    </Box>
                  </Box>
                  {settlementDetails?.distributions.attorneysFees?.secondaryFirms?.map((firm) => (
                    <Box
                      key={firm.id}
                      className={clsx(
                        classes.distributionContainer,
                        classes.responsiveDistributionContainer,
                      )}
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography
                          className={clsx(
                            classes.commonText,
                            classes.fontSize14,
                            classes.fontWeight700,
                            classes.fontFamilyInter,
                          )}
                          lineHeight="120%"
                        >
                          {firm.name}
                        </Typography>
                        <Typography
                          className={clsx(
                            classes.commonText,
                            classes.fontSize14,
                            classes.fontWeight500,
                            classes.fontFamilyPoppins,
                            classes.textGrayColor,
                          )}
                          lineHeight="normal"
                        >
                          {firm.role}
                        </Typography>
                      </Box>

                      <Box className={classes.distributionStatusContainer}>
                        <Typography
                          className={clsx(
                            classes.commonText,
                            classes.fontSize16,
                            classes.fontWeight700,
                            classes.fontFamilyPoppins,
                          )}
                          lineHeight="120%"
                        >
                          {numberToUSDCurrency(firm.amount)}
                        </Typography>
                        <Chip
                          className={classes.statusChip}
                          label={firm.status}
                          sx={getDistributionStatusChipStyles(firm.status)}
                        />
                      </Box>
                    </Box>
                  ))}

                  <Box className={classes.distributionsSectionDivider} />

                  <Box className={classes.distributionContainer}>
                    <Typography
                      className={clsx(
                        classes.commonText,
                        classes.fontSize20,
                        classes.fontWeight700,
                        classes.fontFamilyInter,
                      )}
                      lineHeight="32px"
                    >
                      Deductions
                    </Typography>

                    <Box className={classes.distributionStatusContainer}>
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize20,
                          classes.fontWeight700,
                          classes.fontFamilyPoppins,
                        )}
                        lineHeight="120%"
                      >
                        {numberToUSDCurrency(
                          settlementDetails?.distributions.deductionsTotalAmount,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  {settlementDetails?.distributions.deductions?.map((deduction) => (
                    <Box
                      key={deduction.id}
                      className={clsx(
                        classes.distributionContainer,
                        classes.responsiveDistributionContainer,
                      )}
                    >
                      <Typography className={clsx(classes.commonText, classes.fontSize14)}>
                        {deduction.type}
                      </Typography>

                      <Box className={classes.distributionStatusContainer}>
                        <Typography
                          className={clsx(
                            classes.commonText,
                            classes.fontSize16,
                            classes.fontWeight700,
                            classes.fontFamilyPoppins,
                          )}
                          lineHeight="120%"
                        >
                          {numberToUSDCurrency(deduction.amount)}
                        </Typography>
                        <Chip
                          className={classes.statusChip}
                          label={deduction.status}
                          sx={getDistributionStatusChipStyles(deduction.status)}
                        />
                      </Box>
                    </Box>
                  ))}

                  <Box className={classes.distributionsSectionDivider} />

                  <Box
                    className={clsx(
                      classes.distributionContainer,
                      classes.responsiveDistributionContainer,
                    )}
                  >
                    <Typography
                      className={clsx(
                        classes.commonText,
                        classes.fontSize20,
                        classes.fontWeight700,
                        classes.fontFamilyInter,
                      )}
                      lineHeight="32px"
                    >
                      Net Settlement
                    </Typography>

                    <Box className={classes.distributionStatusContainer}>
                      <Typography
                        className={clsx(
                          classes.commonText,
                          classes.fontSize20,
                          classes.fontWeight700,
                          classes.fontFamilyPoppins,
                        )}
                        lineHeight="120%"
                      >
                        {numberToUSDCurrency(
                          settlementDetails?.distributions.netSettlement?.amount,
                        )}
                      </Typography>
                      {settlementDetails?.distributions.netSettlement?.status && (
                        <Chip
                          className={classes.statusChip}
                          label={settlementDetails?.distributions.netSettlement?.status}
                          sx={getDistributionStatusChipStyles(
                            settlementDetails?.distributions.netSettlement?.status as string,
                          )}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.actionsContainer}>
                <Typography
                  className={clsx(
                    classes.commonText,
                    classes.fontSize20,
                    classes.fontFamilyPoppins,
                    classes.fontWeight700,
                  )}
                  pb="12px"
                >
                  Actions
                </Typography>
                <Box display="flex" flexDirection="column" gap="20px">
                  <CommonButton
                    onButtonClick={() => setIsCancelPopupOpen(true)}
                    type="infoMain"
                    buttonText="Cancel Settlement"
                    maxWidth="auto"
                    disabled={!settlementDetails.cancelAvailable}
                  />
                  <CommonButton
                    type="infoDark"
                    buttonText="Retry"
                    maxWidth="auto"
                    onButtonClick={handleRetryPayProcess}
                    disabled={!settlementDetails.cancelAvailable}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Popup
          isOpen={isCancelPopupOpen}
          headlineText={POPUP_DESCRIPTIONS.cancelSettlementPay.headlineText}
          contentText={POPUP_DESCRIPTIONS.cancelSettlementPay.contentText}
          onMainButtonClick={() => setIsCancelPopupOpen(false)}
          onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
        />
      </Box>
    </>
  );
};

export default SettlementsDetailsView;
