import api from '../service/api.service';

import { ActiveSortField, FirmTrustAccountData, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1';

export const getFirmsList = async ({
  size,
  page,
  count,
  match,
  filters,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const targetUrl = `${BASE_URL}/firms?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}`;

  return await api.get(targetUrl);
};

export const createFirmRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/firms`;

  return await api.post(targetUrl, data);
};

export const getFirmRecord = async (firmId: string) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.get(targetUrl);
};

export const updateFirmRecord = async (firmId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/firms/${firmId}`;

  return await api.put(targetUrl, data);
};

export const getFirmTrustAccounts = async (
  { size, page, count, activeSortField }: PaginationQueryParams,
  firmId: string,
) => {
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);
  const targetUrl = `${BASE_URL}/firms/${firmId}/banking/trust-accounts?size=${size}&page=${page}&count=${count}${sortFilters}`;

  return await api.get<{
    resultSet: FirmTrustAccountData[];
    totalCount: number | null;
  }>(targetUrl);
};
