import { makeStyles } from 'tss-react/mui';

import { Box, MenuItem, Pagination, Select, Theme, Typography, useTheme } from '@mui/material';

import { ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { calcShownCurrentPageRows } from '../../helpers/calcShownCurrentPageRows';
import usePagination from '../../hooks/usePagination';

const useStyles = makeStyles()((theme: Theme) => ({
  paginationContainer: {
    '& .MuiPaginationItem-root': {
      color: theme.palette.common.white,

      '&.Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: 'rgba(255, 255, 255, 0.16)',

        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
        },
      },
    },
  },
  defaultSelect: {
    color: theme.palette.text.secondary,

    '& .MuiOutlinedInput-input': {
      padding: '0px 10px 2px 5px',
    },

    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
      top: '-1px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const TablePaginationContainer = () => {
  const theme = useTheme();
  const styles = useStyles();

  const {
    onChangePageNumber,
    onChangeRowsPerPage,
    rowsPerPage,
    totalCount,
    pageNumber,
    totalPages,
  } = usePagination();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap="16px"
      pb="10px"
      pt="10px"
      bgcolor={theme.palette.primary.light}
      borderRadius="0px 0px 10px 10px"
      minWidth="1200px"
    >
      <Box display="flex" ml="16px">
        <Typography mr="10px" color={theme.palette.text.secondary} fontFamily="Inter">
          Rows per page:
        </Typography>
        <Select
          size="small"
          className={styles.classes.defaultSelect}
          value={rowsPerPage}
          label={String(rowsPerPage)}
          onChange={onChangeRowsPerPage}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,

                  li: {
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.08)',

                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      },
                    },

                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                  },
                },
              },
            },
          }}
        >
          {ROWS_PER_PAGE_OPTIONS.map((option, idx) => (
            <MenuItem key={idx} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box display="flex" color={theme.palette.text.secondary}>
        <Typography>{calcShownCurrentPageRows(pageNumber, rowsPerPage, totalCount)}</Typography>
      </Box>
      <Pagination
        className={styles.classes.paginationContainer}
        count={totalPages}
        page={pageNumber}
        onChange={onChangePageNumber}
      />
    </Box>
  );
};

export default TablePaginationContainer;
