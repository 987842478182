import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V1 = '/v1/signature-requests';
const BASE_URL_V2 = '/v2/signature-requests';

export const getSignaturesList = async ({
  size,
  page,
  count,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V2}?size=${size}&page=${page}&count=${count}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const createSignatureRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V2, data);
};

export const getSignatureRecord = async (signatureId: string) => {
  const targetUrl = `${BASE_URL_V2}/${signatureId}`;

  return await api.get(targetUrl);
};

export const getSignatureFile = async (signatureId: string) => {
  const targetUrl = `${BASE_URL_V1}/${signatureId}/file-url`;

  return await api.get(targetUrl);
};

export const updateSignatureRecord = async (signatureId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V2}/${signatureId}`;

  return await api.put(targetUrl, data);
};

export const getSignatureTimeline = async (signatureId: string) => {
  const targetUrl = `${BASE_URL_V2}/${signatureId}/timeline`;

  return await api.get(targetUrl);
};

export const cancelSignatureRequest = async (signatureId: string) => {
  const targetUrl = `${BASE_URL_V1}/${signatureId}/cancel`;

  return await api.put(targetUrl, {});
};
