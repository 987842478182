import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { Box, Button, Theme, Typography, useTheme } from '@mui/material';

const useStyles = makeStyles<{ bgColor: string; maxWidth?: string; minWidth?: string }>()(
  (theme: Theme, { bgColor, minWidth, maxWidth }) => ({
    default: {
      textTransform: 'capitalize',
      background: bgColor,
      width: '100%',
      color: theme.palette.common.white,
      borderRadius: '12px',
      border: `2px solid ${bgColor}`,
      minHeight: '48px',
      minWidth: minWidth || 'inherit',
      ...(maxWidth && { maxWidth }),

      '&:hover': {
        background: bgColor,
        color: theme.palette.common.white,
      },
    },
    buttonDisabled: {
      '&.Mui-disabled': {
        border: 'none',
        color: theme.palette.text.disabled,
        background: 'rgba(255, 255, 255, 0.08)',
      },
    },
    buttonText: {
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
    },
  }),
);

interface ButtonProps {
  type: string;
  buttonText: string;
  onButtonClick?: () => void;
  maxWidth?: string;
  buttonIcon?: JSX.Element;
  disabled?: boolean;
  minWidth?: string;
}

const CommonButton = ({
  type,
  buttonText,
  disabled,
  maxWidth,
  buttonIcon,
  onButtonClick,
  minWidth,
}: ButtonProps) => {
  const theme = useTheme();

  const buttonColors = {
    info: theme.palette.common.white,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
    infoDark: theme.palette.info.dark,
    infoMain: theme.palette.info.main,
  };

  const activeColor = buttonColors[type as keyof typeof buttonColors];

  const styles = useStyles({ bgColor: activeColor, maxWidth, minWidth });

  return (
    <Box>
      <Button
        className={clsx(styles.classes.default, disabled && styles.classes.buttonDisabled)}
        onClick={onButtonClick}
        disabled={disabled}
        disableRipple
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap="4px">
          {buttonIcon && buttonIcon}
          {!!buttonText.length && (
            <Typography className={styles.classes.buttonText}>{buttonText}</Typography>
          )}
        </Box>
      </Button>
    </Box>
  );
};

export default CommonButton;
