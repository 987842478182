import { Dispatch, SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, IconButton, Theme, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';

import { mapFeesFormValues } from './helpers';
import { CaseFeesBillCheck, FIRM_ROLES } from './types';

import { getCaseFeesBillCheck } from '../../api/casesApi/casesApi';
import CommonButton from '../../components/buttons/CommonButton';
import Loader from '../../components/Loader';

import clsx from 'clsx';
import { ReactComponent as LogoImage } from './sign-logo.svg';
import moment from 'moment';
import { printCheckInlineStyles } from '../../helpers/print-check-inline-styles';
import { numberToUSDCurrency } from '../../helpers/numberToUSDCurrency';
import { printCheck } from '../../helpers/printCheck';
import useWindowWidth from '../../hooks/useWindowWidth';

const useStyles = makeStyles()((theme: Theme) => ({
  feesSummaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '425px',
    height: '100%',
  },
  feesSummaryHeadline: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '32px',
    paddingRight: '10px',
  },
  feesSummaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    alignItems: 'center',
    color: theme.palette.common.white,

    button: {
      p: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
      },
    },
  },
  feesSummaryHeaderPrintBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  feesSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    background: theme.palette.primary.light,
    minHeight: '400px',
    borderRadius: '16px',

    '@media (max-width: 650px)': {
      padding: '16px',
    },
  },
  feesSummaryTotalGrossHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
  },
  feesSummarySectionHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  },
  feesSummarySectionInnerHeadline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 10px 34px',
    color: theme.palette.common.white,

    p: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
  },
  feesSummaryBlockDivider: {
    height: '1px',
    background: theme.palette.secondary.dark,
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '10px',
  },
  firmNameText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.8px',
    color: theme.palette.common.white,
    flexBasis: 'auto',
    overflow: 'hidden',
    minWidth: 0,
    textOverflow: 'ellipsis',
    maxWidth: '130px',
    whiteSpace: 'nowrap',
  },
  printButton: {
    color: 'white',
    background: theme.palette.info.dark,
    padding: '12px',
    borderRadius: '12px',

    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: '80px',
  },
  secondaryFirmsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
}));

interface CasesFeesSummaryProps {
  isBillCheckUpdating: boolean;
  billCheck?: CaseFeesBillCheck;
  setBillCheck: Dispatch<SetStateAction<CaseFeesBillCheck | undefined>>;
  handleCalculateCheckBill: (data: unknown) => void;
  caseSystemName: string;
}

const CasesFeesSummary = ({
  isBillCheckUpdating,
  billCheck,
  setBillCheck,
  handleCalculateCheckBill,
  caseSystemName,
}: CasesFeesSummaryProps) => {
  const { caseId } = useParams();
  const styles = useStyles();

  const { getValues } = useFormContext();

  const windowWidth = useWindowWidth();

  const sortedSecondaryFirms = billCheck?.attorneysFees?.secondaryFirms
    ?.sort((a, b) => a.role.localeCompare(b.role))
    .map((firm) => ({
      firmName: firm.name,
      firmRole: FIRM_ROLES[firm.role as keyof typeof FIRM_ROLES],
    }));

  const currentDateTime = moment().format('hh:mm A, MM/DD/YYYY').split(',');

  const onCalculateBIll = () => {
    const values = getValues();

    handleCalculateCheckBill(mapFeesFormValues(values));
  };

  useEffect(() => {
    getCaseFeesBillCheck(caseId as string).then((res) => {
      if (res.data) {
        setBillCheck(res.data);
      }
    });
  }, []);

  return (
    <>
      <Box className={styles.classes.feesSummaryWrapper}>
        <Box className={styles.classes.feesSummaryHeader}>
          <Typography className={styles.classes.feesSummaryHeadline}>Fee Statement</Typography>
          <Box className={styles.classes.feesSummaryHeaderPrintBlock}>
            <IconButton
              className={styles.classes.printButton}
              onClick={() => printCheck(printCheckInlineStyles)}
              disableRipple
              disabled={isBillCheckUpdating}
            >
              <PrintIcon />
            </IconButton>
            <CommonButton
              onButtonClick={onCalculateBIll}
              buttonText={windowWidth <= 650 ? '' : 'Recalculate'}
              disabled={isBillCheckUpdating}
              type="infoDark"
              minWidth={windowWidth <= 650 ? '48px' : 'inherit'}
              buttonIcon={<RefreshIcon />}
            />
          </Box>
        </Box>
        <Box className={styles.classes.feesSummaryContainer} id="printContent">
          {isBillCheckUpdating || !billCheck ? (
            <Box className={styles.classes.loaderContainer}>
              <Loader colorType="info" />
            </Box>
          ) : (
            <Box className="feesSummaryContainer">
              {/* {print header} */}
              <Box className="print-header" display="none">
                <Box className="logo-image-wrapper">
                  <LogoImage />
                </Box>
                <Typography className="print-case-system-name">{caseSystemName}</Typography>
                <Typography>{`${billCheck.attorneysFees?.leadFirm?.name} (Lead Counsel)`}</Typography>
                {sortedSecondaryFirms?.map((firm) => (
                  <Typography
                    key={firm.firmName}
                  >{`${firm.firmName} (${firm.firmRole})`}</Typography>
                ))}
              </Box>

              <Box
                className={clsx(
                  styles.classes.feesSummaryTotalGrossHeadline,
                  'print-feesSummaryTotalGrossHeadline',
                )}
              >
                <Typography>Gross Settlement</Typography>
                <Typography>{numberToUSDCurrency(billCheck?.totalGrossSettlement)}</Typography>
              </Box>

              <Box
                className={clsx(
                  styles.classes.feesSummarySectionContainer,
                  'print-feesSummarySectionContainer',
                )}
                mt="20px"
              >
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionHeadline,
                    'print-feesSummarySectionHeadline',
                  )}
                >
                  <Typography>Attorneys’ Fees</Typography>
                  <Typography>{numberToUSDCurrency(billCheck?.attorneysFees?.total)}</Typography>
                </Box>

                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionInnerHeadline,
                    'print-feesSummarySectionInnerHeadline',
                  )}
                >
                  <Box className={styles.classes.secondaryFirmsInfoContainer}>
                    <Typography className={clsx(styles.classes.firmNameText, 'print-firmNameText')}>
                      {billCheck.attorneysFees?.leadFirm?.name}
                    </Typography>
                    <Typography>Lead Counsel</Typography>
                  </Box>
                  <Typography>
                    {numberToUSDCurrency(billCheck.attorneysFees?.leadFirm?.fee)}
                  </Typography>
                </Box>

                {!!billCheck?.attorneysFees?.secondaryFirms?.length && (
                  <>
                    {billCheck.attorneysFees.secondaryFirms.map((firmData, idx) => (
                      <Box
                        key={idx}
                        className={clsx(
                          styles.classes.feesSummarySectionInnerHeadline,
                          'print-feesSummarySectionInnerHeadline',
                        )}
                      >
                        <Box className={styles.classes.secondaryFirmsInfoContainer}>
                          <Typography
                            className={clsx(styles.classes.firmNameText, 'print-firmNameText')}
                          >
                            {firmData.name}
                          </Typography>
                          <Typography>
                            {FIRM_ROLES[firmData.role as keyof typeof FIRM_ROLES]}
                          </Typography>
                        </Box>
                        <Typography>{numberToUSDCurrency(firmData.fee)}</Typography>
                      </Box>
                    ))}
                  </>
                )}

                <Box
                  className={clsx(
                    styles.classes.feesSummaryBlockDivider,
                    'print-feesSummaryBlockDivider',
                  )}
                ></Box>
              </Box>

              {billCheck?.deductions && (
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionContainer,
                    'print-feesSummarySectionContainer',
                  )}
                >
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionHeadline,
                      'print-feesSummarySectionHeadline',
                    )}
                  >
                    <Typography>Deductions</Typography>
                    <Typography>{numberToUSDCurrency(billCheck.deductions.total)}</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionInnerHeadline,
                      'print-feesSummarySectionInnerHeadline',
                    )}
                  >
                    <Typography>Advances</Typography>
                    <Typography>{numberToUSDCurrency(billCheck.deductions.advances)}</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummarySectionInnerHeadline,
                      'print-feesSummarySectionInnerHeadline',
                    )}
                  >
                    <Typography>Liens</Typography>
                    <Typography>{numberToUSDCurrency(billCheck.deductions.liens)}</Typography>
                  </Box>
                  <Box
                    className={clsx(
                      styles.classes.feesSummaryBlockDivider,
                      'print-feesSummaryBlockDivider',
                    )}
                  ></Box>
                </Box>
              )}

              <Box
                className={clsx(
                  styles.classes.feesSummarySectionContainer,
                  'print-feesSummarySectionContainer',
                )}
              >
                <Box
                  className={clsx(
                    styles.classes.feesSummarySectionHeadline,
                    'print-feesSummarySectionHeadline',
                  )}
                >
                  <Typography>Net Settlement</Typography>
                  <Typography>{numberToUSDCurrency(billCheck?.netSettlement?.amount)}</Typography>
                </Box>
              </Box>

              <Box className="print-date-time" display="none">
                <Typography>{`Generated at ${currentDateTime[0]} on ${currentDateTime[1]}`}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CasesFeesSummary;
