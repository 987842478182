import { useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { CaseTypeData } from '../../types';

import CaseTypesSearchSection from './CaseTypesSearchSection';
import QuestionnaireEditSection from './QuestionnairesEditSection';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: '16px 0px 16px 0px',
    gap: '64px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  headline: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23px',
    color: theme.palette.common.white,
  },
  height: {
    height: '100%',
  },
}));

const QuestionnairesView = () => {
  const { classes } = useStyles();
  const firmId = localStorage.getItem('firmId');

  const [selectedCaseType, setSelectedCaseType] = useState<CaseTypeData | null>(null);

  return (
    <Box className={classes.container}>
      {firmId && (
        <Box
          className={clsx({
            [classes.section]: true,
            [classes.height]: !selectedCaseType,
          })}
        >
          <Typography className={classes.headline}>{'Case Types'}</Typography>
          <CaseTypesSearchSection
            firmId={firmId}
            selectedCaseType={selectedCaseType}
            handleSelect={(caseType) => setSelectedCaseType(caseType)}
          />
        </Box>
      )}

      {firmId && selectedCaseType && (
        <Box className={classes.section}>
          <Typography className={classes.headline}>{'Questionnaires'}</Typography>
          <QuestionnaireEditSection firmId={firmId} caseType={selectedCaseType} />
        </Box>
      )}
    </Box>
  );
};

export default QuestionnairesView;
