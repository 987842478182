import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Box, IconButton, Theme } from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import AppRoutes from '../../api/AppRoutes';
import Sidebar from '../../components/Sidebar';
import TopBar from '../../components/TopBar/TopBar';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles<{ isLandingPage?: boolean }>()((theme: Theme, { isLandingPage }) => ({
  layoutWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: '100%',
  },
  layoutContainer: {
    display: isLandingPage ? 'block' : 'flex',
    height: '100%',

    '@media (max-width: 550px)': {
      height: isLandingPage ? 'auto' : '100%',
      gap: 0,
      flexDirection: 'column',
      position: 'relative',
    },
  },
  layoutRightContainer: {
    color: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    ...(!isLandingPage && { overflowX: 'hidden' }),
    paddingRight: !isLandingPage ? '24px' : '0px',
    paddingBottom: !isLandingPage ? '32px' : '0px',
    paddingLeft: !isLandingPage ? '24px' : '0px',

    '@media (max-width: 550px)': {
      marginTop: !isLandingPage ? '72px' : 0,
      padding: !isLandingPage ? '0px 16px 16px 16px' : '0px',
    },
  },
  collapseSidebarIcon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '24px',
    border: '1px solid #00667C',
    backgroundColor: theme.palette.primary.light,
    right: '-16px',
    top: '74px',

    svg: {
      color: '#00667C',
    },
  },
  dividerBlock: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '1px',
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const MainLayoutView = () => {
  const location = useLocation();
  const routesForHideControlElements = [
    '/',
    '/privacy-policy',
    '/terms-of-use',
    '/terms-and-conditions',
    '/data-processing-addendum',
    '/data-privacy-framework-notice',
    '/sign-in',
    '/changePassword',
    '/forgotPassword',
    '/resetPassword',
  ];

  const routesToHandleAppBgColor = [
    '/',
    '/privacy-policy',
    '/terms-of-use',
    '/terms-and-conditions',
    '/data-processing-addendum',
    '/data-privacy-framework-notice',
  ];

  const isFrontPages = Boolean(
    routesForHideControlElements.some((route) => route.includes(location.pathname)),
  );

  const styles = useStyles({ isLandingPage: isFrontPages });
  const windowWidth = useWindowWidth();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const handleCollapseSidebar = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  return (
    <>
      <Box className={styles.classes.layoutWrapper}>
        <Box className={styles.classes.layoutContainer}>
          {!isFrontPages && <Sidebar isSidebarCollapsed={isSidebarCollapsed} />}

          {!isFrontPages && windowWidth > 550 && (
            <Box className={styles.classes.dividerBlock}>
              <IconButton
                onClick={handleCollapseSidebar}
                disableRipple
                className={styles.classes.collapseSidebarIcon}
              >
                {isSidebarCollapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
              </IconButton>
            </Box>
          )}

          <Box
            className={styles.classes.layoutRightContainer}
            bgcolor={
              routesToHandleAppBgColor.some((route) => route.includes(location.pathname))
                ? '#FFFFFF'
                : '#282B3A'
            }
          >
            {!isFrontPages && <TopBar />}

            <AppRoutes />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainLayoutView;
