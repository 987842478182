import moment from 'moment';
import * as yup from 'yup';

import { QuestionnaireItemFormat } from '../../types';
import { ExpectedAnswer } from '../../constants/constants';

const validateDateField = (minDateValue?: string, maxDateValue?: string) => {
  return yup
    .mixed()
    .test('isValidDate', 'Invalid date format', function (value) {
      if (value === null || value === '') {
        return this.createError({
          message: 'Date field is required',
          path: this.path,
        });
      }

      return yup.date().isValidSync(value);
    })
    .test('isWithinRange', 'Invalid date', function (value) {
      const minDate = new Date(minDateValue || '1900-01-01');
      const maxDate = new Date(maxDateValue || '2100-01-01');

      if (value === null || value === '') {
        // Skip validation if the field is null or empty, as it's already handled in the previous test
        return true;
      }

      const date = new Date(value as string);

      return date >= minDate && date <= maxDate;
    });
};

export const createAdminFormSchema = yup.object({
  id: yup.string(),
  role: yup.string().required('Required *'),
  firstName: yup.string().required('Required *'),
  lastName: yup.string().required('Required *'),
  email: yup.string().required('Required *'),
  phone: yup.string().required('Required *'),
});

export const manageAccountFormSchema = yup.object({
  id: yup.string().required('Required *'),
  role: yup.string().required('Required *'),
  firstName: yup.string().required('Required *'),
  lastName: yup.string().required('Required *'),
  email: yup.string().required('Required *'),
  phone: yup.string().required('Required *'),
});

export const createUserFormSchema = yup.object({
  id: yup.string(),
  firstName: yup.string().required('Required *'),
  lastName: yup.string().required('Required *'),
  email: yup.string().required('Required *'),
  phone: yup.string().required('Required *'),
  role: yup.string().required('Required *'),
  firmId: yup.string().required('Required *'),
});

export const defendantFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup.string().required('Required *'),
  name: yup.string().required('Required *'),
  firmId: yup.string().required('Required *'),
});

export const clientCreateFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup.string().required('Required *'),
  firstName: yup.string().required('Required *'),
  middleName: yup.string(),
  lastName: yup.string().required('Required *'),
  email: yup.string().required('Required *'),
  phone: yup.string().required('Required *'),
  address1: yup.string().required('Required *'),
  address2: yup.string(),
  city: yup.string().required('Required *'),
  state: yup.string().required('Required *'),
  zip: yup
    .string()
    .matches(
      /^(?!0{5})(\d{5}|\d{5}-\d{4})$/,
      'Invalid Zip Code. Use the following format: XXXXX or XXXXX-XXXX',
    )
    .required('Required *'),
  birthdate: yup
    .date()
    .typeError('Invalid date')
    .required('Required *')
    .nullable()
    .min('01/01/1900', 'Date is too early')
    .max(moment(), 'Date is too far'),
  ssn: yup
    .string()
    .matches(
      /^(?!0{3})\d{3}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
      'Invalid SSN. Use the following format: ХХХ-ХХ-ХХХХ',
    )
    .required('Required *'),
});

export const clientEditFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup.string().required('Required *'),
  firstName: yup.string().required('Required *'),
  middleName: yup.string(),
  lastName: yup.string().required('Required *'),
  email: yup.string().required('Required *'),
  phone: yup.string().required('Required *'),
  address1: yup.string().required('Required *'),
  address2: yup.string(),
  city: yup.string().required('Required *'),
  state: yup.string().required('Required *'),
  zip: yup
    .string()
    .matches(
      /^(?!0{5})(\d{5}|\d{5}-\d{4})$/,
      'Invalid Zip Code. Use the following format: XXXXX or XXXXX-XXXX',
    )
    .required('Required *'),
  birthdate: yup
    .date()
    .typeError('Invalid date')
    .nullable()
    .min('01/01/1900', 'Date is too early')
    .max(moment(), 'Date is too far'),
  ssn: yup.string(),
});

export const casesFormSchema = yup.object({
  id: yup.string(),
  referenceId: yup.string().required('Required *'),
  caseTypeCategory: yup.string().required('Required *'),
  type: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  firmId: yup.string().required('Required *'),
  filedAt: validateDateField(),
  sol: validateDateField(),
  clientId: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  defendant: yup.array().of(
    yup.object().shape({
      defendantId: yup.object().shape({
        id: yup.string().required('Required *'),
        label: yup.string().required('Required *'),
      }),
      gross: yup.string(),
    }),
  ),
  secondaryFirms: yup.array().of(
    yup.object().shape({
      firmId: yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      }),
      firmRole: yup.string(),
    }),
  ),
});

export const firmCodesFormSchema = yup.object({
  code: yup.string().required('Required *'),
  name: yup.string().required('Required *'),
  dataFormat: yup.string().required('Required *'),
  category: yup.string().nullable(),
  prompt: yup.string().required('Required *'),
});

export const casesEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  referenceId: yup.string().required('Required *'),
  type: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  filedAt: validateDateField(),
  sol: validateDateField(),
  clientId: yup
    .object()
    .shape({ id: yup.string().required('Required *'), label: yup.string().required('Required *') }),
  defendant: yup.array().of(
    yup.object().shape({
      defendantId: yup.object().shape({
        id: yup.string().required('Required *'),
        label: yup.string().required('Required *'),
      }),
      gross: yup.string(),
    }),
  ),
  secondaryFirms: yup.array().of(
    yup.object().shape({
      firmId: yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      }),
      firmRole: yup.string(),
    }),
  ),
});

export const deductionsCreateFormSchema = yup.object({
  id: yup.string(),
  isAssigned: yup.bool(),
  amount: yup.string().required('Required *'),
  type: yup.string().required('Required *'),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
});

export const settlementsCreateFormSchema = yup.object({
  id: yup.string(),
  amount: yup.string().required('Required *'),
  defendant: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
});

export const deductionsEditFormSchema = yup.object({
  id: yup.string(),
  caseReferenceId: yup.string(),
  caseSystemName: yup.string(),
  firm: yup.string(),
  isAssigned: yup.bool(),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
  amount: yup.string().required('Required *'),
  type: yup.string().required('Required *'),
});

export const settlementsEditFormSchema = yup.object({
  id: yup.string(),
  caseReferenceId: yup.string(),
  caseSystemName: yup.string(),
  firm: yup.string(),
  plaintiff: yup.object({
    id: yup.string(),
    fullName: yup.string(),
  }),
  defendant: yup.object({
    id: yup.string(),
    label: yup.string(),
  }),
  deduction: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      createdAt: yup.string(),
      type: yup.string(),
      amount: yup.number(),
      isAssigned: yup.string(),
      isAdded: yup.boolean(),
    }),
  ),
});

export const documentsCreateFormSchema = yup.object({
  name: yup.string().required('Required *'),
});

export const documentsEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string().required('Required *'),
  case: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
    referenceId: yup.string().required('Required *'),
  }),
  plaintiffFullName: yup.string().required('Required *'),
  plaintiffId: yup.string(),
  fileId: yup.string(),
  documentUrl: yup.string(),
});

export const signaturesCreateFormSchema = yup.object({
  caseId: yup.object().shape({
    id: yup.string().required('Required *'),
    label: yup.string().required('Required *'),
  }),
  name: yup.string().required('Required *'),
  documentId: yup.string().required('Required *'),
});

export const signaturesEditFormSchema = yup.object({
  id: yup.string(),
  name: yup.string().required('Required *'),
  documentId: yup.string().required('Required *'),
  case: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
  }),
  firm: yup.object().shape({
    id: yup.string().required('Required *'),
    name: yup.string().required('Required *'),
  }),
  plaintiffFullName: yup.string(),
  documentUrl: yup.string(),
  fileId: yup.string(),
});

export const dataCreateFormSchema = yup.object({
  caseId: yup.number().min(1).required(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  notes: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  message: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
});

const itemSchema = yup.object().shape({
  id: yup.string().required('Required *'),
  code: yup.string().max(100, 'Max length 100').required('Required *'),
  systemName: yup.string().max(100, 'Max length 100').required('Required *'),
  questionText: yup.string().max(100, 'Max length 100').required('Required *'),
  immutable: yup.boolean().required('Required *'),
  dependsOnItem: yup.object().shape({
    booleanItemId: yup.string(),
    expectedValue: yup
      .string()
      .when('booleanItemId', ([booleanItemId], schema) =>
        booleanItemId ? schema.oneOf(Object.values(ExpectedAnswer)).required('Required *') : schema,
      ),
  }),
  format: yup.string().oneOf(Object.values(QuestionnaireItemFormat)).required('Required *'),
  placeholder: yup.string().max(100, 'Max length 100'),
  allowPartial: yup.boolean(),
});

export const questionnaireFormSectionSchema = yup.object().shape({
  title: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  items: yup
    .array()
    .of(itemSchema)
    .min(1, 'You must add at least 1 item')
    .max(1000, 'The maximum number of items is 1000')
    .required('Required *'),
  immutable: yup.boolean().required('Required *'),
});

export const questionnaireFormSchema = yup.object().shape({
  sections: yup
    .array()
    .of(questionnaireFormSectionSchema)
    .min(1, 'You must add at least 1 section')
    .max(500, 'The maximum number of sections is 500')
    .required('Required *'),
});

export const questionnaireEditFormSchema = yup.object().shape({
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  form: questionnaireFormSchema,
});

export const dataRequestQuestionnaireEditFormSchema = yup.object().shape({
  form: questionnaireFormSchema,
});

export const formSnippetSchema = yup.object().shape({
  items: yup
    .array()
    .of(itemSchema)
    .min(1, 'You must add at least 1 item')
    .max(1000, 'The maximum number of items is 1000')
    .required('Required *'),
});

export const formSnippetEditFormSchema = yup.object().shape({
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  data: formSnippetSchema,
});

export const dataEditFormSchema = yup.object().shape({
  form: questionnaireFormSchema.nullable(),
  answers: yup
    .array()
    .of(
      yup.object().shape({
        itemId: yup.string().required(),
        format: yup.string().oneOf(Object.values(QuestionnaireItemFormat)).required(),
        value: yup.mixed().nullable(),
      }),
    )
    .required(),
  status: yup.string().required(),
  id: yup.number().required(),
  name: yup.string().max(100, 'Must be less or equal to 100 characters').required('Required *'),
  case: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    referenceId: yup.string().required(),
    type: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  }),
  firm: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
  plaintiff: yup.object().shape({
    id: yup.number().required(),
    firstName: yup.string().required(),
    middleName: yup.string(),
    lastName: yup.string().required(),
  }),
  plaintiffFullName: yup.string().required(),
  notes: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  message: yup.string().max(1000, 'Must be less or equal to 1000 characters'),
  formItemsCount: yup.number().required(),
  formItemAnswersCount: yup.number().required(),
});

export const signInFormSchema = yup.object({
  email: yup.string().email('Email must be valid').required('Required *'),
  password: yup.string().required('Required *'),
});

export const changePasswordFormSchema = yup.object({
  newPassword: yup.string().required('Required *'),
  repeatNewPassword: yup.string().required('Required *'),
});

export const forgotPasswordFormSchema = yup.object({
  email: yup.string().email('Email must be valid').required('Required *'),
});

export const resetPasswordFormSchema = yup.object({
  code: yup
    .string()
    .matches(new RegExp('^.{6}$'), 'Invalid verification code')
    .required('Required *'),
  newPassword: yup.string().required('Required *'),
  repeatNewPassword: yup.string().required('Required *'),
});

export const caseAttorneysFeesFormSchema = yup.object({
  caseFeesConfig: yup.object().shape({
    variableFeePercent: yup.string(),
    maximumAmount: yup.string(),
    variableFeeBasis: yup.string(),
  }),
  secondaryFirms: yup.array().of(
    yup.object().shape({
      role: yup.string(),
      name: yup.string(),
      id: yup.string(),
      config: yup.object().shape({
        fixedFeeAmount: yup.string(),
        variableFeePercent: yup.string(),
        variableFeeMaximumAmount: yup.string(),
        variableFeeBasis: yup.string(),
      }),
    }),
  ),
});

export const requestDemoSchema = yup.object({
  userName: yup.string().required('* Required'),
  email: yup.string().email('Must be a valid email').required('* Required'),
  message: yup.string().required('* Required'),
  isAgreed: yup
    .boolean()
    .required('Agreement must be accepted')
    .oneOf([true], 'Agreement must be accepted'),
});
