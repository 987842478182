import { Box } from '@mui/material';

import { manageAccountTabs } from '../../constants/tabRoutes';

import TabsPanel from '../../components/TabsPanel';

const ManageAccountView = () => {
  return (
    <Box display="flex" width="100%">
      <TabsPanel tabsList={manageAccountTabs} />
    </Box>
  );
};

export default ManageAccountView;
