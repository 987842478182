import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { DocumentCreateFormSubmitProps } from '../types';

import { getCaseRecord } from '../../../api/casesApi/casesApi';
import { createDocumentRecord } from '../../../api/requestsApi/documentsApi';
import FindCaseButton from '../../../components/buttons/FindCaseButton';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import FormTextField from '../../../components/formFields/FormTextField';
import SearchCasePopup from '../../../components/SearchCasePopup';
import { DOCUMENTS_CREATE_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { documentsCreateFormSchema } from '../../../helpers/formSchemas/formSchemas';
import useSnackBar from '../../../hooks/useSnackBar';
import { CaseData } from '../../../types';
import usePageTitle from '../../../hooks/usePageTitle';
import CaseInfoPreview from '../../../components/CaseInfoPreview';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: '100vh',
  },
  formWrapper: {
    display: 'flex',
  },
  formContainer: {
    padding: '32px',
    flexBasis: '416px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 550px)': {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formInfoBlock: {
    display: 'flex',
    flexBasis: '416px',
    flexDirection: 'column',

    '@media (max-width: 550px)': {
      flexBasis: '100%',
    },
  },
}));

const CreateDocumentFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();
  const { setPageTitle } = usePageTitle();

  const caseId = new URLSearchParams(window.location.search).get('caseId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [caseDetails, setCaseDetails] = useState<CaseData | null>(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(documentsCreateFormSchema),
    defaultValues: DOCUMENTS_CREATE_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const handleSetCaseValue = (item: CaseData) => {
    setIsPopupOpen(false);
    setCaseDetails(item);
  };

  const onSubmitForm = (values: DocumentCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      caseId: Number(caseDetails?.id),
      name: values.name,
    };

    createDocumentRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/requests/document-request/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Document request created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    reset();
    !caseId && setCaseDetails(null);
  };

  useEffect(() => {
    return () => setPageTitle && setPageTitle('Requests');
  }, []);

  useEffect(() => {
    caseId &&
      getCaseRecord(caseId as string).then((res) => {
        setCaseDetails(res.data);
      });
  }, [caseId]);

  return (
    <Box className={styles.classes.container}>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.formContainer}>
            <Box display="flex">
              <Box className={styles.classes.formInfoBlock}>
                <Typography className={styles.classes.fieldHeadline}>Case</Typography>
                <Box mt="16px" display="flex" gap="16px" flexDirection="column">
                  {caseDetails && <CaseInfoPreview caseItem={caseDetails} />}
                  {!caseId && <FindCaseButton onButtonClick={() => setIsPopupOpen(true)} />}
                </Box>

                <Typography className={styles.classes.fieldHeadline} mt="32px">
                  Document
                </Typography>
                <Box mt="16px">
                  <FormTextField name="name" label="Document Name *" />
                </Box>
              </Box>
            </Box>

            <Box display="flex" mt="32px" gap="16px" flexDirection="column">
              <FormSubmitButton
                disabled={!isValid || !caseDetails || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                fontFamily="Inter"
                onClick={handleSubmit(onSubmitForm)}
              />
              <FormCancelButton
                disabled={caseId ? !isDirty : !(isDirty || caseDetails)}
                onClick={handleCancelChanges}
              />
            </Box>
          </form>
        </FormProvider>
        <SearchCasePopup
          isOpen={isPopupOpen}
          onMainButtonClick={handleSetCaseValue}
          onSecondaryButtonClick={() => setIsPopupOpen(false)}
        />
      </Box>
    </Box>
  );
};

export default CreateDocumentFormContainer;
