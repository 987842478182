import { makeStyles } from 'tss-react/mui';

import { Box, Chip, Theme, Typography, useTheme } from '@mui/material';

import { CLIENT_WALLET_ACCOUNT_STATUSES, ClientBankgingWalletAccountData } from '../types';

import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { capitalizeText } from '../../../helpers/capitalizeText';

const useStyles = makeStyles<{ chipColor?: string }>()((theme: Theme, { chipColor }) => ({
  systemAccountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  systemAccountsColoredLabel: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.info.main,
  },
  systemAccountsSmallLabel: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#93959E',
    fontFamily: 'Inter',
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsBalanceText: {
    fontSize: '34px',
    fontWeight: 600,
    lineHeight: 'normal',
    fontFamily: 'Poppins',
    color: theme.palette.common.white,
  },
  noAccountText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.3)',
    maxWidth: '260px',
  },
  detailedInfoHeadlineText: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#93959E',
  },
  statusPillBar: {
    background: `${chipColor}14`,
    border: `1px solid ${chipColor}`,
    fontSize: '14px',
    padding: 0,
    borderRadius: '12px',
    height: '25px',

    '& .MuiChip-label': {
      fontFamily: 'Inter',
      fontWeight: '500',
      color: chipColor,
      textTransform: 'capitalize',
      padding: '0px 12px 0px 12px',
    },
  },
}));

interface ClientBankingWalletAccountContainerProps {
  accountData: ClientBankgingWalletAccountData | null;
}

const ClientBankingWalletAccountContainer = ({
  accountData,
}: ClientBankingWalletAccountContainerProps) => {
  const styles = useStyles({ chipColor: '' });

  return (
    <>
      {!accountData ? (
        <Box className={styles.classes.systemAccountWrapper}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography className={styles.classes.noAccountText}>
              No information about this account, or it has not been created yet.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={styles.classes.systemAccountWrapper}>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography className={styles.classes.detailedInfoHeadlineText}>
                Current Balance
              </Typography>
              <SystemAccountStatusChip status={accountData.status} />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography className={styles.classes.systemAccountsSmallLabel}>
                {`PRN: ${accountData.accountNumber}`}
              </Typography>
              <Typography className={styles.classes.systemAccountsSmallLabel}>
                {`ABA: ${accountData.routingNumber}`}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Typography className={styles.classes.systemAccountsBalanceText}>
                {numberToUSDCurrency(accountData.balance)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

interface SystemAccountStatusChipProps {
  status: string;
}

const SystemAccountStatusChip = ({ status }: SystemAccountStatusChipProps) => {
  const theme = useTheme();

  const SystemAccountStatusesChipColor = {
    CLOSED: theme.palette.error.main,
    BLOCKED: theme.palette.error.main,
    CLOSURE_INITIATED: theme.palette.warning.main,
    INACTIVE: theme.palette.warning.main,
    ACTIVE: theme.palette.success.main,
  };

  const currentChipColor =
    SystemAccountStatusesChipColor[status as keyof typeof CLIENT_WALLET_ACCOUNT_STATUSES];

  const styles = useStyles({ chipColor: currentChipColor });

  return (
    <Chip
      label={capitalizeText(
        CLIENT_WALLET_ACCOUNT_STATUSES[status as keyof typeof CLIENT_WALLET_ACCOUNT_STATUSES],
      )}
      className={styles.classes.statusPillBar}
    />
  );
};

export default ClientBankingWalletAccountContainer;
