export const manageAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
];

export const usersAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
];

export const defendantsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
];

export const clientsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Cases',
    path: 'cases',
    matchUrls: ['cases'],
  },
  {
    name: 'Banking',
    path: 'banking',
    matchUrls: ['banking'],
  },
];

export const casesAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Attorneys’ fees',
    path: 'attorneys-fees',
    matchUrls: ['attorneys-fees'],
  },
  {
    name: 'Document requests',
    path: 'document-requests',
    matchUrls: ['document-requests'],
  },
  {
    name: 'Signature requests',
    path: 'signature-requests',
    matchUrls: ['signature-requests'],
  },
  {
    name: 'Data requests',
    path: 'data-requests',
    matchUrls: ['data-requests'],
  },
  {
    name: 'Deductions',
    path: 'deductions',
    matchUrls: ['deductions'],
  },
  {
    name: 'Settlements',
    path: 'settlements',
    matchUrls: ['settlements'],
  },
];

export const requestsAccountTabs = [
  {
    name: 'Documents',
    path: 'document-requests',
    matchUrls: ['document-requests'],
  },
  {
    name: 'Signatures',
    path: 'signature-requests',
    matchUrls: ['signature-requests'],
  },
  {
    name: 'Data',
    path: 'data-requests',
    matchUrls: ['data-requests'],
  },
];

export const documentsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const signaturesAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const dataAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const paymentsAccountTabs = [
  {
    name: 'Deductions',
    path: 'deductions',
    matchUrls: ['deductions'],
  },
  {
    name: 'Settlements',
    path: 'settlements',
    matchUrls: ['settlements'],
  },
];

export const deductionsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];

export const settlementsAccountTabs = [
  {
    name: 'Home',
    path: 'home',
    matchUrls: ['home'],
  },
  {
    name: 'Details',
    path: 'details',
    matchUrls: ['details'],
  },
  {
    name: 'Timeline',
    path: 'timeline',
    matchUrls: ['timeline'],
  },
];
