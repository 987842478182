import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import TabsPanel from '../../../components/TabsPanel';
import { dataAccountTabs } from '../../../constants/tabRoutes';
import useSnackBar from '../../../hooks/useSnackBar';
import { getDataRequest } from '../../../api/requestsApi/dataApi';

const DataAccountView = () => {
  const { dataId } = useParams<string>();
  const { setAlert } = useSnackBar();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    if (!dataId) return;

    getDataRequest(dataId).then((response) => {
      if (response.status === 200) {
        setEntityRecordData({
          ...response.data,
          notes: response.data.notes || '',
          message: response.data.message || '',
          plaintiffFullName: `${response.data.plaintiff.firstName} ${
            response.data.plaintiff.middleName || ''
          } ${response.data.plaintiff.lastName}`,
        });
      } else {
        setAlert((prev) => ({
          ...prev,
          message: response.statusText,
          type: 'error',
        }));
      }
    });
  }, [dataId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={dataAccountTabs}
          locationSlicePosition={4}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default DataAccountView;
