import { useFormContext, Controller } from 'react-hook-form';
import clsx from 'clsx';
import {
  Box,
  FilledInputProps,
  InputAdornment,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { makeStyles } from 'tss-react/mui';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { getTextFieldErrorMessage } from '../../helpers/getTextFieldErrorMessage';

export const useStyles = makeStyles<{ maxWidth?: string; disabled?: boolean; minWidth?: string }>()(
  (theme: Theme, { maxWidth, disabled, minWidth }) => ({
    defaultTextField: {
      ...(maxWidth && { maxWidth }),
      ...(minWidth && { minWidth }),
      background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      input: {
        padding: '13.5px 16px',
        color: theme.palette.common.white,
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '120%',
        fontWeight: 500,

        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          WebkitTextFillColor: theme.palette.text.disabled,
        },
      },

      '& .MuiOutlinedInput-root': {
        color: theme.palette.text.disabled,
        borderRadius: '12px',

        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: theme.palette.error.main,
          },
        },

        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: theme.palette.info.main,
          },
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.secondary.dark,
          borderRadius: '12px',
        },

        '&:hover': {
          border: 'none',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: theme.palette.info.main,
          },
        },

        '&.Mui-disabled': {
          color: theme.palette.text.disabled,

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'none',
          },
        },
      },

      '& .MuiInputLabel-root': {
        color: theme.palette.text.disabled,

        '&[data-shrink="false"]': {
          top: '-3px',
        },

        '&[data-shrink="true"]': {
          color: theme.palette.text.disabled,
        },

        '&.Mui-error': {
          color: theme.palette.error.main,
        },

        '&.Mui-focused': {
          color: theme.palette.common.white,
        },

        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },

      '& .MuiFormHelperText-root': {
        position: 'absolute',
        bottom: '-20px',
        marginLeft: '14px',
      },
    },
    readOnlyText: {
      '& .MuiInput-root': {
        maxWidth: '470px',
        color: theme.palette.primary.light,
      },
    },
    multilinePadding: {
      textarea: {
        color: theme.palette.common.white,
      },
    },
    inputStartAdornment: {
      marginRight: 0,
      height: '100%',
    },
    inputValueDefinitionContainer: {
      marginRight: 0,
      color: theme.palette.common.white,
      paddingRight: '4px',
    },
    onlyText: {
      input: {
        padding: 0,
        color: theme.palette.common.white,
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '120%',
        fontWeight: 500,
      },

      '& .MuiOutlinedInput-root': {
        border: 'none',
        borderRadius: 0,

        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none',
        },
      },
      '& .MuiInputLabel-root': {
        display: 'none',
      },
    },
  }),
);

interface FormTextFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
  isReadOnly?: boolean;
  displayHidden?: boolean;
  handleChange?: (value: string) => void;
  isMultiline?: boolean;
  inputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseProps['inputProps'];
  maxRows?: number;
  minWidth?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  valueDefinition?: string;
  isOnlyText?: boolean;
  onBlur?: (value: string) => void;
}

const FormTextField = ({
  name,
  label,
  handleChange,
  readOnly,
  isReadOnly,
  disabled,
  maxWidth,
  displayHidden,
  isMultiline,
  inputLabelProps,
  inputProps,
  maxRows,
  InputProps,
  minWidth,
  valueDefinition,
  isOnlyText,
  onBlur,
}: FormTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles({ maxWidth, disabled, minWidth });

  const textFieldKeyName = `${name}.message`;

  const helperText = errors && getTextFieldErrorMessage(errors, textFieldKeyName);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            fullWidth
            type={displayHidden ? 'password' : 'text'}
            className={clsx(
              isOnlyText ? styles.classes.onlyText : styles.classes.defaultTextField,
              isReadOnly && styles.classes.readOnlyText,
              isMultiline && styles.classes.multilinePadding,
            )}
            multiline={isMultiline}
            minRows={4}
            maxRows={maxRows}
            variant="outlined"
            label={label}
            onBlur={(e) => onBlur?.(e.target.value)}
            onChange={(e) =>
              handleChange
                ? handleChange(e.target.value)
                : onChange(validateTextFieldValue(e.target.value))
            }
            helperText={helperText}
            value={value}
            error={!!helperText}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={{
              readOnly: readOnly,
              ...InputProps,
              ...(valueDefinition && {
                startAdornment: (
                  <InputAdornment position="start" className={styles.classes.inputStartAdornment}>
                    <Box className={styles.classes.inputValueDefinitionContainer}>
                      <Typography>{valueDefinition}</Typography>
                    </Box>
                  </InputAdornment>
                ),
              }),
            }}
            InputLabelProps={inputLabelProps}
          />
        );
      }}
    />
  );
};

export default FormTextField;
