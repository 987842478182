import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  createCaseType,
  deleteCaseType,
  getCaseTypes,
  linkTrustAccount,
  updateCaseType,
} from '../../api/casesApi/caseTypesApi';
import Loader from '../../components/Loader';
import { CaseTypeData, ErrorData, PaginationQueryParams } from '../../types';
import useSnackBar from '../../hooks/useSnackBar';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import FormSearchField from '../../components/formFields/FormSearchField';
import { CaseTypesCategories } from '../../constants/constants';

import CreateCaseTypePopup from './CreateCaseTypePopup';
import EditCaseTypePopup from './EditCaseTypePopup';
import CaseTypeList from './CaseTypeList';
import DefaultTrustAccountPopup from './DefaultTrustAccountPopup';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
}));

interface CaseTypesEditSectionProps {
  readonly firmId: string;
}

interface FormSubmitProps {
  name: string;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required *'),
  })
  .required();

const CaseTypesEditSection = ({ firmId }: CaseTypesEditSectionProps) => {
  const { setAlert } = useSnackBar();
  const { classes } = useStyles();

  const [caseTypesList, setCaseTypesList] = useState<Array<CaseTypeData>>([]);
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [editPopupData, setEditPopupData] = useState<CaseTypeData | null>(null);
  const [deletePopupData, setDeletePopupData] = useState<CaseTypeData | null>(null);
  const [trustAccountPopupData, setTrustAccountPopupData] = useState<CaseTypeData | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 15;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  const { setValue, reset, watch } = formMethods;

  const searchValue = watch('name');

  const getFetchParams = ({
    page,
    count,
    match,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match?.trim() || '',
    };

    return queryParams;
  };

  const handleCancelButtonClick = () => {
    setIsLoading(true);
    reset();
    fetchCaseTypes(getFetchParams({}));
  };

  const fetchCaseTypes = (queryParams: PaginationQueryParams) => {
    getCaseTypes({
      page: queryParams.page,
      size: queryParams.size,
      count: queryParams.count,
      match: queryParams.match,
      filters: { firmId },
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setCaseTypesList(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setCaseTypesList(Array.from(new Set([...caseTypesList, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleLoadMoreButtonClick = () => {
    if (totalCount !== caseTypesList.length) {
      setIsFetching(true);
      fetchCaseTypes(getFetchParams({ page: page + 1, count: false, match: searchValue }));
    }
  };

  const handleSearchCaseType = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      setIsLoading(true);
      fetchCaseTypes(getFetchParams({ match: value }));
    }
  };

  const handleCreateCaseType = (name: string) => {
    setIsLoading(true);
    setIsCreatePopupOpen(false);

    const data = {
      firmId: Number(firmId),
      category: CaseTypesCategories.Custom,
      name: name.trim(),
    };

    createCaseType(data)
      .then((response) => {
        if (response.status === 201) {
          setAlert((prev) => ({
            ...prev,
            message: 'Case type created successfully.',
            type: 'success',
          }));

          fetchCaseTypes(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleUpdateCaseType = (name: string) => {
    if (!editPopupData) return;

    setIsLoading(true);
    setEditPopupData(null);

    updateCaseType(editPopupData?.id, { name: name.trim() })
      .then((response) => {
        if (response.status === 200) {
          setAlert((prev) => ({
            ...prev,
            message: 'Case type updated successfully.',
            type: 'success',
          }));

          fetchCaseTypes(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleDeleteCaseType = () => {
    if (!deletePopupData) return;

    setIsLoading(true);
    setDeletePopupData(null);

    deleteCaseType(deletePopupData.id)
      .then((response) => {
        if (response.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'Case type deleted successfully.',
            type: 'success',
          }));

          fetchCaseTypes(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const linkDefaultTrustAccount = (caseTypeId: number, trustAccountId: number) => {
    setIsLoading(true);

    linkTrustAccount(caseTypeId, { trustAccountId })
      .then((response) => {
        if (response.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'Default trust account added successfully.',
            type: 'success',
          }));

          setTrustAccountPopupData(null);
          fetchCaseTypes(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCaseTypes(getFetchParams({}));
  }, [firmId]);

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form>
          <FormSearchField
            name="name"
            searchValue={searchValue}
            handleChange={handleSearchCaseType}
            handleResetSearch={handleCancelButtonClick}
          />
        </form>
      </FormProvider>

      {isLoading ? (
        <Loader colorType="warning" />
      ) : (
        <CaseTypeList
          data={caseTypesList}
          isFetching={isFetching}
          totalCount={totalCount}
          handleCreateNewCaseTypeClick={() => setIsCreatePopupOpen(true)}
          handleDeleteCaseTypeClick={(caseType) => setDeletePopupData(caseType)}
          handleEditCaseTypeClick={(caseType) => setEditPopupData(caseType)}
          handleDefaultTrustAccountClick={(caseType) => setTrustAccountPopupData(caseType)}
          handleLoadMoreButtonClick={handleLoadMoreButtonClick}
        />
      )}

      <CreateCaseTypePopup
        isOpen={isCreatePopupOpen}
        onMainButtonClick={handleCreateCaseType}
        onSecondaryButtonClick={() => setIsCreatePopupOpen(false)}
      />
      <EditCaseTypePopup
        caseType={editPopupData}
        onMainButtonClick={handleUpdateCaseType}
        onSecondaryButtonClick={() => setEditPopupData(null)}
      />
      <Popup
        isOpen={deletePopupData !== null}
        headlineText={POPUP_DESCRIPTIONS.caseTypeDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.caseTypeDelete.contentText}
        onMainButtonClick={handleDeleteCaseType}
        onSecondaryButtonClick={() => setDeletePopupData(null)}
      />
      <DefaultTrustAccountPopup
        isOpen={trustAccountPopupData !== null}
        data={trustAccountPopupData}
        firmId={firmId}
        isFormSubmitting={isLoading}
        onMainButtonClick={(caseTypeId, trustAccountId) => {
          linkDefaultTrustAccount(caseTypeId, trustAccountId);
        }}
        onSecondaryButtonClick={() => setTrustAccountPopupData(null)}
      />
    </Box>
  );
};

export default CaseTypesEditSection;
