import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../../helpers/formatDate';
import { numberToUSDCurrency } from '../../../helpers/numberToUSDCurrency';
import { ClientTransactionsData } from '../../../types';

interface AdminsTableDataRowProps {
  tableData: ClientTransactionsData[];
  isRelatedToOtherEntity?: boolean;
}

const ClientBankingTableDataRow = ({ tableData }: AdminsTableDataRowProps) => {
  const formattedClientsList =
    tableData &&
    tableData.map((transaction) => ({
      createdAt: transaction.lastUpdatedOn ? formatDate(transaction.lastUpdatedOn) : '-',
      accountId: transaction.accountId,
      transactionId: transaction.transactionId,
      method: transaction.method,
      memo: transaction.memo,
      type: transaction.type,
      amount: numberToUSDCurrency(transaction.amount),
      runningBalance: numberToUSDCurrency(transaction.runningBalance),
    }));

  return (
    <>
      {formattedClientsList.map((tableItem, idx) => (
        <TableRow key={idx}>
          {Object.values(tableItem).map((item, idx) => (
            <TableCell key={idx}>{item}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default ClientBankingTableDataRow;
