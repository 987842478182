export interface ClientSubmitFormProps {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  referenceId?: string;
  email: string;
  phone: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  birthdate?: string | Date | null;
  ssn?: string;
}

export type IntegrationProvisioningData = {
  ofac?: {
    status: string;
    reason: string | null;
  };
  provisioning: {
    error?: {
      status: string;
      reason: string | null;
    };
    status: keyof typeof OFAC_STATUSES | keyof typeof INTEGRATION_STASUSES;
  };
  cip?: {
    status: string;
    reason: string | null;
  };
  isOk: boolean;
};

export enum INTEGRATION_STASUSES {
  InProgress = 'In Progress',
  Error = 'Error',
  CriticalError = 'Critical Error',
  Completed = 'Completed',
}

export enum OFAC_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export enum CIP_STATUSES {
  PENDING_VERIFICATION = 'Pending Verification',
  VERIFIED = 'Verified',
  UNVERIFIED = 'Unverified',
  UNDER_REVIEW = 'Under Review',
  REJECTED = 'Rejected',
  IGNORED = 'Ignored',
}

export interface ClientBankgingWalletAccountData {
  accountNumber: string;
  routingNumber: string;
  balance: string;
  status: string;
}

export enum CLIENT_WALLET_ACCOUNT_STATUSES {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  CLOSURE_INITIATED = 'CLOSURE_INITIATED',
  CLOSED = 'CLOSED',
}

export interface ClientBankingData {
  account: ClientBankgingWalletAccountData;
  canStartIntegration: boolean;
  plaintiff: {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string;
  };
  integration: IntegrationProvisioningData;
}
