import moment from 'moment';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, useTheme, Theme, Typography } from '@mui/material';

import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetFirms,
  handleGetPlaintiffs,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapFirmsQueryResults,
  mapPlaintiffsQueryResults,
} from './helpers';
import { CaseCreateFormSubmitProps, DefendantField, SecondaryFirmsField } from './types';

import { createCaseRecord } from '../../api/casesApi/casesApi';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { CASES_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { casesFormSchema } from '../../helpers/formSchemas/formSchemas';
import AddButton from '../../components/buttons/AddButton';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import useSnackBar from '../../hooks/useSnackBar';
import useWindowWidth from '../../hooks/useWindowWidth';
import {
  CASE_TYPES_CATEGORIES_OPTIONS,
  CaseTypesCategories,
  SECONDARY_FIRM_ROLES_OPTIONS,
} from '../../constants/constants';

const useStyles = makeStyles()((theme: Theme) => ({
  caseCreateForm: {
    padding: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 1100px)': {
      flexBasis: 'auto',
    },

    '@media (max-width: 725px)': {
      flexBasis: '520px',
      padding: '16px',
    },
  },
  formContainer: {
    display: 'flex',
    width: '100%',
    gap: '32px',

    '@media (max-width: 1100px)': {
      flexDirection: 'column',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  dateFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '16px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
      gap: '24px',
    },
  },
  dateFieldWrapper: {
    width: '100%',
  },
  balanceFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '16px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
      gap: '24px',
    },
  },
  defendantFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '16px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
    },
  },
  formWrapper: {
    display: 'flex',
  },
}));

const CasesCreateFormContainer = () => {
  const styles = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setAlert } = useSnackBar();
  const windowWidth = useWindowWidth();

  const firmId = localStorage.getItem('firmId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(casesFormSchema),
    defaultValues: { ...CASES_FORM_DEFAULT_VALUES, firmId: firmId || '' },
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const caseTypeCategory = watch('caseTypeCategory');

  const { fields, append, remove } = useFieldArray({
    name: 'defendant',
    control,
  });

  const handleAddDefendant = () => {
    append({
      defendantId: { id: '', label: '' },
      gross: '',
    });
  };

  const {
    fields: secondaryFirmsFields,
    append: secondaryFirmAppend,
    remove: secondaryFirmRemove,
  } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const handleAddSecondaryFirm = () => {
    secondaryFirmAppend({
      firmId: { id: '', label: '' },
      firmRole: '',
    });
  };

  const onSubmitForm = (values: CaseCreateFormSubmitProps) => {
    setIsFormSubmitting(true);

    const mappedDefendants = values?.defendant?.map((defendant: DefendantField) => ({
      id: Number(defendant.defendantId.id),
      grossSettlement: defendant.gross || null,
    }));

    const mappedFirms = values?.secondaryFirms?.map((firm: SecondaryFirmsField) => ({
      role: firm.firmRole,
      id: firm?.firmId?.id,
    }));

    const submitData = {
      referenceId: values.referenceId,
      caseTypeId: Number(values.type),
      plaintiffId: Number(values.clientId.id),
      firmId,
      defendants: mappedDefendants,
      sol: moment(values.sol).format('MM-DD-YYYY'),
      filedAt: moment(values.filedAt).format('MM-DD-YYYY'),
      secondaryFirms: mappedFirms || [],
    };

    createCaseRecord(submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate(`/cases/${res.data.id}/home`);

          setAlert((prev) => ({
            ...prev,
            message: 'Case created successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  return (
    <>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form className={styles.classes.caseCreateForm}>
            <Box className={styles.classes.formContainer}>
              <Box display="flex" flexDirection="column" width="100%" maxWidth="470px">
                <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                <Box mt="16px">
                  <FormTextField name="referenceId" label="Reference Id *" />
                </Box>
                <Box mt="24px">
                  <FormSelectField
                    options={CASE_TYPES_CATEGORIES_OPTIONS}
                    width="100%"
                    name="caseTypeCategory"
                    label="Case Type Category *"
                    customOnChange={(value) => {
                      setValue('caseTypeCategory', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      setValue(
                        'type',
                        { id: '', label: '' },
                        { shouldDirty: true, shouldValidate: true },
                      );
                    }}
                  />
                </Box>
                <Box mt="24px">
                  <FormAutocompleteField
                    disabled={!caseTypeCategory}
                    name={'type'}
                    label="Case Type *"
                    width="100%"
                    filters={{
                      category: caseTypeCategory,
                      ...(caseTypeCategory === CaseTypesCategories.Custom && {
                        firmId: Number(firmId),
                      }),
                    }}
                    optionsQuery={handleGetCaseTypes}
                    mapQueryResults={mapCaseTypesQueryResults}
                  />
                </Box>

                <Typography className={styles.classes.fieldHeadline} mt="32px">
                  Key Dates
                </Typography>
                <Box className={styles.classes.dateFieldsContainer}>
                  <Box width="100%">
                    <FormDateField name="filedAt" label="Date Filed *" />
                  </Box>
                  <Box width="100%">
                    <FormDateField name="sol" label="SOL Date *" />
                  </Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" maxWidth="520px">
                <Typography className={styles.classes.fieldHeadline}>Plaintiff</Typography>
                <Box mt="16px">
                  <FormAutocompleteField
                    maxWidth="468px"
                    name="clientId"
                    label="Client *"
                    width="100%"
                    optionsQuery={handleGetPlaintiffs}
                    mapQueryResults={mapPlaintiffsQueryResults}
                    filters={{
                      firmId: Number(firmId),
                    }}
                  />
                </Box>
                <Box mt="16px" display="flex">
                  <AddButton buttonText="Create new client" path="/clients/create-client" />
                </Box>

                <Typography className={styles.classes.fieldHeadline} mt="32px">
                  Defendants
                </Typography>

                {fields.map((field, idx) => (
                  <Box className={styles.classes.defendantFieldsContainer} key={field.id}>
                    <FormAutocompleteField
                      name={`defendant.${idx}.defendantId`}
                      label="Defendant *"
                      maxWidth={windowWidth <= 725 ? '100%' : '272px'}
                      optionsQuery={handleGetDefendants}
                      mapQueryResults={mapDefendantsQueryResults}
                      width="100%"
                      filters={{
                        firmId: Number(firmId),
                      }}
                    />

                    <Box display="flex" width={windowWidth <= 725 ? '100%' : 'inherit'} gap="16px">
                      <FormTextField
                        name={`defendant.${idx}.gross`}
                        label="Gross Settlement"
                        maxWidth={windowWidth <= 725 ? '100%' : '180px'}
                      />
                      {idx !== 0 && (
                        <IconButton
                          sx={{
                            padding: 0,
                            color: theme.palette.error.main,
                          }}
                          onClick={() => remove(idx)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ))}

                <Box
                  mt="16px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  maxWidth="468px"
                  gap="16px"
                >
                  <AddButton
                    buttonText="Create new defendant"
                    path="/defendants/create-defendant"
                  />
                  <AddButton buttonText="Add defendant" handleClick={handleAddDefendant} />
                </Box>

                <Typography className={styles.classes.fieldHeadline} mt="32px">
                  Firms
                </Typography>

                {secondaryFirmsFields.map((field, idx) => (
                  <Box className={styles.classes.defendantFieldsContainer} key={field.id}>
                    <FormAutocompleteField
                      name={`secondaryFirms.${idx}.firmId`}
                      label="Firm *"
                      maxWidth={windowWidth <= 725 ? '100%' : '272px'}
                      optionsQuery={handleGetFirms}
                      mapQueryResults={mapFirmsQueryResults}
                      width="100%"
                    />

                    <Box display="flex" width={windowWidth <= 725 ? '100%' : 'inherit'} gap="16px">
                      <FormSelectField
                        name={`secondaryFirms.${idx}.firmRole`}
                        label="Role"
                        maxWidth={windowWidth <= 725 ? '100%' : '180px'}
                        options={SECONDARY_FIRM_ROLES_OPTIONS}
                      />
                      <IconButton
                        sx={{
                          padding: 0,
                          color: theme.palette.error.main,
                        }}
                        onClick={() => secondaryFirmRemove(idx)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

                <Box
                  mt="16px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  maxWidth="468px"
                  gap="16px"
                >
                  <AddButton buttonText="Add firm" handleClick={handleAddSecondaryFirm} />
                </Box>
              </Box>
            </Box>

            <Box display="flex" width="100%" justifyContent="center">
              <Box
                display="flex"
                mt="32px"
                maxWidth="416px"
                flexDirection="column"
                gap="16px"
                width="100%"
              >
                <FormSubmitButton
                  disabled={!isValid || isFormSubmitting}
                  buttonIcon={<SaveIcon />}
                  buttonText="Save"
                  onClick={handleSubmit(onSubmitForm)}
                />
                <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default CasesCreateFormContainer;
