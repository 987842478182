import { BaseSyntheticEvent, Fragment, useEffect, useState } from 'react';
import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import { CaseTypeData, ErrorData, FirmTrustAccountData, PaginationQueryParams } from '../../types';
import { getFirmTrustAccounts } from '../../api/firmsApi/firmsApi';
import useSnackBar from '../../hooks/useSnackBar';
import Loader from '../../components/Loader';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    padding: '0px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0px 0px 0px',
    gap: '20px',
  },
  defaultTrustAccountInfo: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    padding: '10px',
    gap: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',

    '@media (max-width: 550px)': {
      paddingRight: '10px',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33%',
    whiteSpace: 'nowrap',
  },
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '300px',
    overflowY: 'auto',

    '@media (max-width: 550px)': {
      paddingRight: '10px',
    },

    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  searchListItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(144, 202, 249, 0.08)',
    },
  },
  activeSearchListItem: {
    backgroundColor: 'rgba(144, 202, 249, 0.08)',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '12px',
    color: '#93959E',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '0px',
    marginTop: '32px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  buttonDefault: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  saveButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface DefaultTrustAccountPopupProps {
  readonly isOpen: boolean;
  readonly data: CaseTypeData | null;
  readonly firmId: string;
  readonly isFormSubmitting: boolean;
  readonly onMainButtonClick: (caseTypeId: number, trustAccountId: number) => void;
  readonly onSecondaryButtonClick: () => void;
}

const DefaultTrustAccountPopup = ({
  isOpen,
  data,
  firmId,
  isFormSubmitting,
  onMainButtonClick,
  onSecondaryButtonClick,
}: DefaultTrustAccountPopupProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [trustAccounts, setTrustAccount] = useState<FirmTrustAccountData[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<FirmTrustAccountData | null>(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 10;

  const getFetchParams = ({ page, count }: { page?: number; count?: boolean }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
    };

    return queryParams;
  };

  const fetchFirmTrustAccounts = (queryParams: PaginationQueryParams) => {
    if (queryParams.page === 1) setIsLoading(true);
    else setIsFetching(true);

    getFirmTrustAccounts(
      {
        page: queryParams.page,
        size: PAGE_SIZE,
        count: queryParams.count,
        match: queryParams.match,
      },
      firmId,
    )
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setTrustAccount(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setTrustAccount(Array.from(new Set([...trustAccounts, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const loadMoreResults = () => {
    if (totalCount !== trustAccounts.length) {
      fetchFirmTrustAccounts(getFetchParams({ page: page + 1 }));
    }
  };

  const handleSelectItem = (account: FirmTrustAccountData) => {
    setSelectedAccount(account);
  };

  const handleScroll = (event: BaseSyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const renderDefaultTrustAccountInfo = () => {
    return (
      <Box className={classes.defaultTrustAccountInfo}>
        <Box>
          <Typography className={classes.label}>{'Name'}</Typography>
          <Typography className={classes.text}>{data?.trustAccounts[0]?.name || '-'}</Typography>
        </Box>
        <Box>
          <Typography className={classes.label}>{'Account Number'}</Typography>
          <Typography className={classes.text}>
            {data?.trustAccounts[0]?.accountNumber || '-'}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.label}>{'Routing Number'}</Typography>
          <Typography className={classes.text}>
            {data?.trustAccounts[0]?.routingNumber || '-'}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderTrustAccountList = () => {
    if (isLoading) {
      return <Loader colorType="info" />;
    }

    if (!trustAccounts.length) {
      return (
        <Box
          display={'flex'}
          flexDirection={'column'}
          minHeight={'100px'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography fontSize="20px">{'No results.'}</Typography>
        </Box>
      );
    }

    return (
      <>
        <Box className={classes.searchResultsBlock} onScroll={handleScroll}>
          {trustAccounts.map((account) => (
            <Box
              key={account.id}
              className={clsx({
                [classes.searchListItem]: true,
                [classes.activeSearchListItem]: account.id === selectedAccount?.id,
              })}
              onClick={() => handleSelectItem(account)}
            >
              <Box className={classes.section}>
                <Typography className={classes.label}>{'Name'}</Typography>
                <Typography className={classes.text}>{account.name}</Typography>
              </Box>
              <Box className={classes.section}>
                <Typography className={classes.label}>{'Account Number'}</Typography>
                <Typography className={classes.text}>{account.accountNumber}</Typography>
              </Box>
              <Box className={classes.section}>
                <Typography className={classes.label}>{'Routing Number'}</Typography>
                <Typography className={classes.text}>{account.routingNumber}</Typography>
              </Box>
            </Box>
          ))}

          {isFetching && (
            <Box className={classes.searchListItem}>
              <Loader colorType="info" size={25} />
            </Box>
          )}
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (!isOpen) return;

    setSelectedAccount(null);
    fetchFirmTrustAccounts(getFetchParams({}));
  }, [isOpen]);

  return (
    <Fragment>
      <Dialog
        className={classes.dialogContainer}
        open={isOpen}
        onClose={onSecondaryButtonClick}
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>{'Default Trust Account'}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {renderDefaultTrustAccountInfo()}
          <Box>
            <Typography>{'Available Accounts'}</Typography>
            {renderTrustAccountList()}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={clsx(classes.buttonDefault, classes.saveButton)}
            onClick={() => {
              if (data?.id && selectedAccount?.id) {
                onMainButtonClick(data.id, selectedAccount.id);
              }
            }}
            disabled={!selectedAccount || isLoading || isFormSubmitting}
          >
            {'Save'}
          </Button>
          <Button
            className={clsx(classes.buttonDefault, classes.cancelButton)}
            onClick={onSecondaryButtonClick}
          >
            {'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DefaultTrustAccountPopup;
