import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CaseTypeData } from '../../types';
import AddButton from '../../components/buttons/AddButton';
import Loader from '../../components/Loader';

import CaseTypeButton from './CaseTypeButton';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  caseTypesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    gap: '16px',
  },
  loaderWrapper: {
    marginTop: '16px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
}));

interface CaseTypeListProps {
  readonly data: CaseTypeData[];
  readonly isFetching: boolean;
  readonly totalCount: number | null;
  readonly handleCreateNewCaseTypeClick: () => void;
  readonly handleDeleteCaseTypeClick: (caseType: CaseTypeData) => void;
  readonly handleEditCaseTypeClick: (caseType: CaseTypeData) => void;
  readonly handleLoadMoreButtonClick: () => void;
  readonly handleDefaultTrustAccountClick: (caseType: CaseTypeData) => void;
}

const CaseTypeList = ({
  data,
  isFetching,
  totalCount,
  handleCreateNewCaseTypeClick,
  handleDeleteCaseTypeClick,
  handleEditCaseTypeClick,
  handleLoadMoreButtonClick,
  handleDefaultTrustAccountClick,
}: CaseTypeListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.caseTypesContainer}>
        {data.map((caseType) => (
          <CaseTypeButton
            key={caseType.id}
            buttonText={caseType.name}
            onPress={() => handleEditCaseTypeClick(caseType)}
            onDelete={() => handleDeleteCaseTypeClick(caseType)}
            onDefaultTrustAccountClick={() => handleDefaultTrustAccountClick(caseType)}
          />
        ))}
        {!isFetching && (
          <Box className={classes.actions}>
            {totalCount !== null && data.length < totalCount && (
              <AddButton
                buttonText="Load More"
                handleClick={handleLoadMoreButtonClick}
                disabled={isFetching}
              />
            )}
            <AddButton
              buttonText="Create New Case Type"
              handleClick={handleCreateNewCaseTypeClick}
            />
          </Box>
        )}
      </Box>
      {isFetching && (
        <Box className={classes.loaderWrapper}>
          <Loader colorType="warning" size={60} />
        </Box>
      )}
    </Box>
  );
};

export default CaseTypeList;
