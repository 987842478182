import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import SettlementsTableDataRow from './SettlementsTableDataRow';

import { getSettlementsList } from '../../../api/paymentsApi/settlementsApi';
import Loader from '../../../components/Loader';
import NoDataText from '../../../components/NoDataText';
import TableContainer from '../../../components/Table/TableContainer';
import { SETTLEMENTS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, FilterTypes, SettlementData } from '../../../types';
import TableFilters from '../../../components/TableFilters/TableFilters';
import { downloadCsv } from '../../../helpers/downloadCsv';
import TableActions from '../../../components/Table/TableActions';
import { SETTLEMENTS_STATUS_FILTER_OPTIONS } from '../../../components/TableFilters/constants';
import useSnackBar from '../../../hooks/useSnackBar';
import { handleSortTableData } from '../../../helpers/handleSortTableData';

const SettlementsView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [settlementsList, setSettlementsList] = useState<SettlementData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
    plaintiffId: '',
    defendantId: '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    caseName: {
      order: '',
      fieldName: 'caseName',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
    paidAt: {
      order: '',
      fieldName: 'paidAt',
    },
  };

  const hasParams = !!Object.entries(params).length;

  useEffect(() => {
    getSettlementsList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setSettlementsList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv('/v1/settlements/export', 'POST', 'Settlements_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Settlements exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !settlementsList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink={`/payments/create-settlement${
              params.caseId ? `/?caseId=${params.caseId}` : '/'
            }`}
            exportList={exportList}
            match={match}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            setMatch={setMatch}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              plaintiff
              defendant
              timeFilter={'ENTRY DATE'}
              statusFilter={SETTLEMENTS_STATUS_FILTER_OPTIONS}
              match={match}
            />
          )}

          {!isDataLoading && settlementsList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={SETTLEMENTS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <SettlementsTableDataRow tableData={settlementsList} />
            </TableContainer>
          )}
        </>
      )}
      <Outlet />
    </>
  );
};

export default SettlementsView;
