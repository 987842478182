import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme, Typography } from '@mui/material';

import { ReactComponent as LogoIcon } from './assets/landing-logo-white.svg';
import { ReactComponent as AppStoreImage } from './assets/app-store.svg';
import { ReactComponent as PlayMarketImage } from './assets/play-market.svg';

import { APP_STORE_DOWNLOAD_LINK } from '../../constants/constants';

const DOCUMENT_LINKS = [
  { name: 'Terms and Conditions', path: '/terms-and-conditions' },
  { name: 'Terms of Use', path: '/terms-of-use' },
  { name: 'Privacy Policy', path: '/privacy-policy' },
  { name: 'Data Processing Addendum', path: '/data-processing-addendum' },
  { name: 'Data Privacy Framework Notice', path: '/data-privacy-framework-notice' },
];

const useStyles = makeStyles()((theme: Theme) => ({
  footerContainer: {
    display: 'flex',
    width: '100%',
    padding: '96px 40px 0px 40px',
    boxSizing: 'border-box',
    height: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    justifyContent: 'space-between',

    '@media (max-width: 768px)': {
      padding: '0px 40px',
    },

    '@media (max-width: 700px)': {
      flexDirection: 'column',
      gap: '48px',
    },

    '@media (max-width: 425px)': {
      padding: '0px 20px',
    },
  },
  linksContainer: {
    display: 'flex',
    gap: '40px',

    '@media (max-width: 700px)': {
      flexDirection: 'column',
      gap: '20px',
      alignItems: 'flex-end',
    },
  },
  footerLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '16px',
    textDecoration: 'none',
    color: theme.palette.common.white,

    p: {
      margin: 0,
    },
  },
  rightFooterContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '48px',
    },
  },
  footerStoresContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    '@media (max-width: 768px)': {
      alignItems: 'flex-end',
    },
  },
  footerBottomContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0px 40px',
    marginTop: '48px',
    paddingBottom: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width: 970px)': {
      flexDirection: 'column',
      gap: '12px',
      alignItems: 'center',
    },
  },
  footerDocLinksContainer: {
    display: 'flex',
    gap: '32px',

    '@media (max-width: 970px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '12px',
    },

    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  copyrightText: {
    color: '#93959E',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  },
  documentLink: {
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '120%',
    fontSize: '12px',
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  footerLeftContainer: {
    display: 'flex',
    maxHeight: '48px',
    gap: '40px',
    alignItems: 'center',

    '@media (max-width: 700px)': {
      justifyContent: 'space-between',
      maxHeight: 'none',
      alignItems: 'flex-start',
    },
  },
}));

const Footer = () => {
  const styles = useStyles();
  const isLandingPageRoute = useLocation().pathname === '/';

  const handleNavigateToSection = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const target = event.target as HTMLAnchorElement;
    const id = target.getAttribute('id')?.replace('#', '');
    const element = document.getElementById(String(id));

    const offset = id === 'howitworks' ? 200 : 150;

    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      });
    }
  };

  return (
    <>
      <Box className={styles.classes.footerContainer}>
        <Box className={styles.classes.footerLeftContainer}>
          <LogoIcon />
          {isLandingPageRoute && (
            <Box
              className={styles.classes.linksContainer}
              onClick={(e: React.SyntheticEvent) => handleNavigateToSection(e)}
            >
              <a className={styles.classes.footerLink} href="#whyv" id="#whyv">
                <p id="#whyv">
                  Why <strong id="#whyv">v</strong>?
                </p>
              </a>
              <a className={styles.classes.footerLink} id="#features" href="#features">
                Features
              </a>
              <a className={styles.classes.footerLink} href="#howitworks" id="#howitworks">
                How It Works
              </a>
            </Box>
          )}
        </Box>

        <Box className={styles.classes.footerStoresContainer}>
          <Link to={APP_STORE_DOWNLOAD_LINK} target="_blank">
            <AppStoreImage />
          </Link>
          <PlayMarketImage />
        </Box>
      </Box>
      <Box className={styles.classes.footerBottomContainer}>
        <Box className={styles.classes.footerDocLinksContainer}>
          {DOCUMENT_LINKS.map((link) => (
            <Link className={styles.classes.documentLink} key={link.name} to={link.path}>
              {link.name}
            </Link>
          ))}
        </Box>
        <Typography className={styles.classes.copyrightText}>©2024 v.law, LLC</Typography>
      </Box>
    </>
  );
};

export default Footer;
