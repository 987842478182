import { SelectOption } from '../../types';
import { AnyObject } from 'yup';

export interface CaseCreateFormSubmitProps {
  id?: string;
  name?: string;
  referenceId: string;
  caseTypeCategory: string;
  type: SelectOption;
  firmId?: string;
  status?: string;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined;
  filedAt: AnyObject | undefined;
  secondaryFirms: SecondaryFirmsField[] | undefined;
}

export interface CaseEditFormSubmitProps {
  id?: string;
  name?: string;
  referenceId: string;
  type: SelectOption;
  firmId?: string;
  status?: string;
  defendant: DefendantField[] | undefined;
  clientId: SelectOption;
  sol: AnyObject | undefined;
  filedAt: AnyObject | undefined;
  secondaryFirms: SecondaryFirmsField[] | undefined;
}

export type DefendantField = {
  defendantId: { id: string; label: string };
  gross?: string | undefined;
};

export type SecondaryFirmsField = {
  firmId?: { id?: string; label?: string };
  firmRole?: string | undefined;
};

export interface SecondaryFirmAttorneysFeesConfig {
  id?: string;
  role?: string;
  name?: string;
  config?: {
    fixedFeeAmount?: string | undefined;
    variableFeePercent?: string | undefined;
    variableFeeMaximumAmount?: string | undefined;
    variableFeeBasis?: string | undefined;
  };
}

export interface CaseAttorneyFeesConfigSubmitProps {
  caseFeesConfig?: {
    variableFeePercent?: string | undefined;
    maximumAmount?: string | undefined;
    variableFeeBasis?: string | undefined;
  };
  secondaryFirms?: SecondaryFirmAttorneysFeesConfig[];
}

export interface CaseFeesBillCheck {
  attorneysFees?: {
    leadFirm?: {
      id: string;
      name: string;
      fee: string | number;
    };
    secondaryFirms?: {
      fee: string | number;
      id: string;
      name: string;
      role: string;
    }[];
    total: string | number;
  };
  deductions?: {
    advances: number | string;
    liens: number | string;
    total: number | string;
  };
  totalGrossSettlement?: number | string;
  netSettlement?: {
    plaintiffId: number;
    amount: number;
  };
}

export enum FIRM_ROLES {
  LeadCounsel = 'Lead Counsel',
  CoCounsel = 'Co-counsel',
  ReferringCounsel = 'Referring Counsel',
}
