import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL_V1 = '/v1/plaintiffs';
const BASE_URL_V3 = '/v3/plaintiffs';

export const createClientRecord = async (data: unknown) => {
  return await api.post(BASE_URL_V3, data);
};

export const getClientsList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V3}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getClientRecord = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V3}/${clientId}`;

  return await api.get(targetUrl);
};

export const updateClientRecord = async (clientId: string, data: unknown) => {
  const targetUrl = `${BASE_URL_V3}/${clientId}`;

  return await api.put(targetUrl, data);
};

export const resetClientAccount = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/reset`;

  return await api.post(targetUrl, null);
};

export const getClientBankingInfo = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking`;

  return await api.get(targetUrl);
};

export const startClientProvisioning = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/start-provisioning`;

  return await api.put(targetUrl);
};

export const getClientTransactionList = async (
  { size, page, filters, activeSortField }: PaginationQueryParams,
  clientId: string,
) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V1}/${clientId}/banking/ledger?size=${size}&page=${page}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getClientBirthDate = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V3}/${clientId}/birthdate`;

  return await api.get(targetUrl);
};

export const getClientSSN = async (clientId: string) => {
  const targetUrl = `${BASE_URL_V3}/${clientId}/ssn`;

  return await api.get(targetUrl);
};
