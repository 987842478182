import { Chip, Theme, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ colorByType?: string }>()((theme: Theme, { colorByType }) => ({
  statusPillBar: {
    background: `${colorByType}14`,
    border: `1px solid ${colorByType}`,
    fontSize: '14px',
    padding: 0,
    borderRadius: '12px',
    marginBottom: '12px',
    height: '25px',
    width: 'fit-content',

    '& .MuiChip-label': {
      fontFamily: 'Inter',
      fontWeight: '500',
      color: colorByType,
      textTransform: 'capitalize',
      padding: '0px 12px 0px 12px',
    },
  },
}));

interface StatusPillBarProps {
  status: string;
}

const StatusPillBar = ({ status }: StatusPillBarProps) => {
  const theme = useTheme();

  const statusColors = {
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
  };

  const statusPillBarTypes = {
    Active: 'success',
    Draft: 'warning',
    Pending: 'warning',
    Closed: 'error',
    Abandoned: 'error',
    Archived: 'error',
    Created: 'warning',
    Deactivated: 'error',
    Completed: 'success',
    Unopened: 'warning',
    Opened: 'info',
    Rejected: 'error',
    Canceled: 'error',
    Paid: 'success',
    Unpaid: 'warning',
    CancelInProgress: 'error',
    Error: 'error',
  };

  const currentStatus = statusPillBarTypes[status as keyof typeof statusPillBarTypes];

  const styles = useStyles({
    colorByType: statusColors[currentStatus as keyof typeof statusColors],
  });

  return (
    <Chip
      label={status === 'CancelInProgress' ? 'Cancel In Progress' : status}
      className={styles.classes.statusPillBar}
    />
  );
};

export default StatusPillBar;
