import { Box } from '@mui/material';

import TabsPanel from '../../../components/TabsPanel';
import { documentsAccountTabs } from '../../../constants/tabRoutes';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDocumentFile, getDocumentRecord } from '../../../api/requestsApi/documentsApi';

const DocumentsAccountView = () => {
  const { documentId } = useParams<string>();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDocumentRecord(documentId as string).then((res: any) => {
      if (res.data) {
        if (res.data?.fileId) {
          getDocumentFile(documentId as string).then((fileRes) => {
            setEntityRecordData({
              id: res.data.id,
              name: res.data.name,
              case: {
                id: res.data.case.id,
                name: res.data.case.name,
                referenceId: res.data.case.referenceId,
              },
              plaintiffFullName: `${res.data.plaintiff.firstName} ${
                res.data.plaintiff.middleName || ''
              } ${res.data.plaintiff.lastName}`,
              plaintiffId: res.data.plaintiff.id,
              documentUrl: fileRes?.data,
              status: res.data.status,
            });
          });
        } else {
          setEntityRecordData({
            id: res.data.id,
            name: res.data.name,
            case: {
              id: res.data.case.id,
              name: res.data.case.name,
              referenceId: res.data.case.referenceId,
            },
            plaintiffId: res.data.plaintiff.id,
            plaintiffFullName: `${res.data.plaintiff.firstName} ${
              res.data.plaintiff.middleName || ''
            } ${res.data.plaintiff.lastName}`,
            status: res.data.status,
          });
        }
      }
    });
  }, [documentId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={documentsAccountTabs}
          locationSlicePosition={4}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default DocumentsAccountView;
