import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import ClientActivationsButtonGroup from './ClientActivationsButtonGroup';

import { ClientSubmitFormProps } from './types';

import {
  getClientBirthDate,
  getClientSSN,
  resetClientAccount,
  updateClientRecord,
} from '../../api/clientsApi/clientsApi';
import Loader from '../../components/Loader';
import CommonButton from '../../components/buttons/CommonButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import { CLIENT_ACTIVATION_BUTTON_GROUPS, US_STATES_LIST } from '../../constants/constants';
import { CLIENT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { clientEditFormSchema } from '../../helpers/formSchemas/formSchemas';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../types';
import FormSSNField from '../../components/formFields/FormSSNField';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 800px)': {
      flexBasis: '480px',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 1000px)': {
      flexBasis: '416px',
    },

    '@media (max-width: 800px)': {
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '24px',
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',

    '@media (max-width: 1000px)': {
      gap: '24px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '200px',

    '@media (max-width: 800px)': {
      flexBasis: 0,
    },
  },
}));

const ClientsEditFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { plaintiffId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();

  const [clientAccountStatus, setClientAccountStatus] = useState<string>('');
  const { entityData } = useOutletContext<EntityFlexibleRecord>();
  const [isSSNValueVisible, setIsSSNValueVisible] = useState<boolean>(false);
  const [isBirhtDateValueVisible, setIsBirhtDateValueVisible] = useState<boolean>(false);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(clientEditFormSchema),
    defaultValues: CLIENT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    setValue,
  } = formMethods;

  const onSubmitForm = (values: ClientSubmitFormProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      address2: values.address2?.length ? values.address2 : null,
      middleName: values.middleName?.length ? values.middleName : null,
      referenceId: values.referenceId,
      firstName: values.firstName,
      lastName: values.lastName,
      address1: values.address1,
      city: values.city,
      countryState: values.state,
      zip: values.zip,
    };

    updateClientRecord(plaintiffId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/clients');

          setAlert((prev) => ({
            ...prev,
            message: 'Client updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => {
    setIsBirhtDateValueVisible(false);
    setIsSSNValueVisible(false);
    reset();
  };

  const handleResetClientAccount = () => {
    resetClientAccount(plaintiffId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'App invitation has been successfully sent.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const getSSNFieldValue = () => {
    getClientSSN(plaintiffId as string)
      .then((res) => {
        if (res.data.ssn) {
          setValue('ssn', res.data.ssn);
          setIsSSNValueVisible(true);
        }
      })
      .catch(() => setIsSSNValueVisible(false));
  };

  const getBirhtDateFieldValue = () => {
    getClientBirthDate(plaintiffId as string)
      .then((res) => {
        if (res.data.birthdate) {
          setValue('birthdate', moment(res.data.birthdate) as unknown as Date);
          setIsBirhtDateValueVisible(true);
        }
      })
      .catch(() => setIsBirhtDateValueVisible(false));
  };

  useEffect(() => {
    if (entityData) {
      reset({
        ...entityData,
      });

      setClientAccountStatus(entityData.status as string);
      setPageTitle && setPageTitle(`${entityData.firstName} ${entityData.lastName}`);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      {!entityData ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.pageContainer}>
            <FormProvider {...formMethods}>
              <form className={styles.classes.formContainer}>
                <Box className={styles.classes.formInfoContainer}>
                  <Box className={styles.classes.formInnerWrapper}>
                    <Box className={styles.classes.formWrapper}>
                      <Box display="flex" flexDirection="column" flexBasis="416px">
                        <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                        <Box mt="16px">
                          <FormCopyTextField entityName="Client" name="id" label="Id" isReadOnly />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="referenceId" label="Reference Id *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="firstName" label="First Name *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="middleName" label="Middle Name" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="lastName" label="Last Name *" />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Credentials
                        </Typography>
                        <Box mt="16px">
                          <FormTextField name="email" label="Email Address *" disabled />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="phone" label="Mobile Number *" disabled />
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" flexBasis="416px">
                        <Typography className={styles.classes.fieldHeadline}>Address</Typography>
                        <Box mt="16px">
                          <FormTextField name="address1" label="Address Line 1 *" />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="address2" label="Address Line 2" />
                        </Box>
                        <Box className={styles.classes.formPlaceInfoContainer}>
                          <FormTextField name="city" label="City *" />
                          <FormSelectField
                            options={US_STATES_LIST}
                            name="state"
                            label="State *"
                            width="100%"
                          />
                          <FormTextField name="zip" label="Zip *" />
                        </Box>

                        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                          <Typography className={styles.classes.fieldHeadline} mt="32px">
                            Personal Information
                          </Typography>
                        </Box>
                        <Box mt="16px">
                          <FormDateField
                            name="birthdate"
                            label="Date of Birth *"
                            maxDate={moment()}
                            readonly
                            isEncrypted
                            getEncryptedFieldValue={getBirhtDateFieldValue}
                            isValueVisible={isBirhtDateValueVisible}
                            setIsValueVisible={() =>
                              setIsBirhtDateValueVisible(!isBirhtDateValueVisible)
                            }
                          />
                        </Box>
                        <Box mt="24px">
                          <FormSSNField
                            name="ssn"
                            label="Social Security Number *"
                            isEncrypted
                            getEncryptedFieldValue={getSSNFieldValue}
                            isValueVisible={isSSNValueVisible}
                            setIsValueVisible={() => setIsSSNValueVisible(!isSSNValueVisible)}
                          />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Activations
                        </Typography>
                        <Box mt="12px" width="100%">
                          <ClientActivationsButtonGroup
                            buttonGroupLabel="app"
                            buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.app}
                            maxWidth="100px"
                          />
                        </Box>

                        <Box mt="16px">
                          <ClientActivationsButtonGroup
                            buttonGroupLabel="virtual card"
                            buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.virtualCard}
                            maxWidth="80px"
                          />
                        </Box>

                        <Box mt="16px">
                          <ClientActivationsButtonGroup
                            buttonGroupLabel="physical card"
                            buttonsList={CLIENT_ACTIVATION_BUTTON_GROUPS.physicalCard}
                            maxWidth="90px"
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" width="100%" justifyContent="center">
                      <Box
                        display="flex"
                        mt="32px"
                        maxWidth="416px"
                        flexDirection="column"
                        gap="16px"
                        width="100%"
                      >
                        <FormSubmitButton
                          disabled={!isValid || !isDirty || isFormSubmitting}
                          buttonIcon={<SaveIcon />}
                          buttonText="Save"
                          onClick={handleSubmit(onSubmitForm)}
                        />
                        <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                      </Box>
                    </Box>
                  </Box>

                  <Box className={styles.classes.formActionsWrapper}>
                    <Typography className={styles.classes.fieldHeadline}>Actions</Typography>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="send app invite"
                        buttonIcon={<MobileScreenShareIcon />}
                        onButtonClick={handleResetClientAccount}
                        disabled={clientAccountStatus !== 'Created'}
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="issue virtual card"
                        buttonIcon={<CreditCardIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="issue physical card"
                        buttonIcon={<CreditCardIcon />}
                        disabled
                      />
                    </Box>

                    {/* <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box> */}
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </>
      )}
    </>
  );
};

export default ClientsEditFormContainer;
