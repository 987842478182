import axios from 'axios';
import { cognitoPool } from '../../views/SignInView/cognitoPool';
import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const refreshAccessToken = (cognitoUser: CognitoUser, refreshToken: string) => {
  return new Promise<string>((resolve, reject) => {
    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        reject(err);
      } else {
        const accessToken = session.getAccessToken().getJwtToken();
        localStorage.setItem('access_token', accessToken);
        resolve(accessToken);
      }
    });
  });
};

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const cognitoUser = cognitoPool.getCurrentUser();
          const refreshToken = localStorage.getItem('refresh_token');

          if (cognitoUser && refreshToken) {
            try {
              await refreshAccessToken(cognitoUser, refreshToken);
            } catch (error) {
              console.error('Token refresh failed:', error);
            }
          } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            console.error('Cognito user or refresh token not available');
          }

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
