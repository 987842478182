import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Checkbox, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';

const checkMarkURI =
  // eslint-disable-next-line quotes
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E";

const CheckBoxIcon = styled('span')(() => ({
  borderRadius: 8,
  width: 24,
  height: 24,
  backgroundColor: 'transparent',
  border: '2px solid #4A4E5D',
}));

const CheckedCheckBoxIcon = styled(CheckBoxIcon)({
  backgroundColor: '#00667C',
  border: '2px solid #00667C',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: `url("${checkMarkURI}")`,
    content: '""',
  },
});

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginLeft: 0,
    '& .MuiCheckbox-root': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.common.white,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '17px',
      letterSpacing: '0px',
    },
  },
}));

interface FormCheckboxFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
}

const FormCheckboxField = ({ name, label, disabled }: FormCheckboxFieldProps) => {
  const { control } = useFormContext();

  const styles = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          className={styles.classes.root}
          control={
            <Checkbox
              icon={<CheckBoxIcon />}
              checkedIcon={<CheckedCheckBoxIcon />}
              disableRipple
              onChange={onChange}
              checked={value}
              disabled={disabled}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormCheckboxField;
