import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import { formatDate } from '../../helpers/formatDate';
import { translateStatus } from '../../helpers/translateStatus';
import { LawyerData } from '../../types';

interface AdminsTableDataRowProps {
  tableData: LawyerData[];
}

const UsersTableDataRow = ({ tableData }: AdminsTableDataRowProps) => {
  const navigate = useNavigate();

  const onTableRowClick = (id: number) => {
    navigate(`/users/${id}/home`);
  };

  const formattedLawyersList =
    tableData &&
    tableData.map((lawyer) => ({
      id: lawyer.id,
      rowFields: {
        name: `${lawyer.firstName} ${lawyer.lastName}`,
        createdAt: formatDate(lawyer.createdAt),
        role: 'Admin',
        status: translateStatus(true),
        email: lawyer.email,
        phone: lawyer.phone,
      },
    }));

  return (
    <>
      {formattedLawyersList.map((tableItem, idx) => (
        <TableRow key={idx} onClick={() => onTableRowClick(tableItem.id)}>
          {Object.values(tableItem.rowFields).map((rowField, idx) => (
            <TableCell key={idx}>{rowField}</TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default UsersTableDataRow;
