import { makeStyles } from 'tss-react/mui';

import { Box, Chip, Theme, Typography, useTheme } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import CommonButton from '../../../components/buttons/CommonButton';
import {
  CIP_STATUSES,
  INTEGRATION_STASUSES,
  IntegrationProvisioningData,
  OFAC_STATUSES,
} from '../types';

const useStyles = makeStyles<{ chipColor?: string }>()((theme: Theme, { chipColor }) => ({
  integrationAccountContainer: {
    display: 'flex',
    padding: '12px 20px 20px 20px',
    background: theme.palette.primary.light,
    flexDirection: 'column',
    maxWidth: '280px',
    width: '100%',
    gap: '24px',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },
  systemAccountsColoredLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  systemAccountsSmallLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    color: theme.palette.secondary.main,
  },
  systemAccountsLabel: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  systemAccountsBlockLabel: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  integrationErrorReasonText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusPillBar: {
    background: `${chipColor}14`,
    border: `1px solid ${chipColor}`,
    fontSize: '14px',
    padding: 0,
    borderRadius: '12px',
    height: '25px',

    '& .MuiChip-label': {
      fontFamily: 'Inter',
      fontWeight: '500',
      color: chipColor,
      textTransform: 'capitalize',
      padding: '0px 12px 0px 12px',
    },
  },
}));

interface ClientBankingIntergrationContainerProps {
  canStartIntegration?: boolean;
  handleIntegrationSubmit: () => void;
  integrationData?: IntegrationProvisioningData;
}

const ClientBankingIntergrationContainer = ({
  canStartIntegration,
  handleIntegrationSubmit,
  integrationData,
}: ClientBankingIntergrationContainerProps) => {
  const styles = useStyles({ chipColor: '' });

  return (
    <Box className={styles.classes.integrationAccountContainer}>
      <Typography className={styles.classes.systemAccountsBlockLabel}>
        Integration Details
      </Typography>
      {canStartIntegration ? (
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography fontSize="14px">
            Integration with the payment system does not exist has not yet been initiated
          </Typography>

          <CommonButton
            onButtonClick={handleIntegrationSubmit}
            type="info"
            buttonText="START"
            minWidth="180px"
          />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="flex" flexDirection="column" gap="10px">
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography className={styles.classes.systemAccountsColoredLabel}>
                Provisioning
              </Typography>
              <IntegrationStatusChip
                status={integrationData?.provisioning.status as keyof typeof INTEGRATION_STASUSES}
                statusType="provisioning"
              />
            </Box>

            {integrationData?.provisioning?.error && (
              <Box display="flex" flexDirection="column" gap="8px" padding="0 12px">
                <Typography className={styles.classes.integrationErrorReasonText}>
                  {integrationData?.provisioning?.error?.reason}
                </Typography>
                <CommonButton
                  type="info"
                  onButtonClick={handleIntegrationSubmit}
                  buttonText="Retry"
                  buttonIcon={<SyncIcon />}
                  maxWidth="95px"
                />
              </Box>
            )}
          </Box>
          {integrationData?.ofac && (
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography className={styles.classes.systemAccountsColoredLabel}>OFAC</Typography>
              <IntegrationStatusChip
                status={integrationData?.ofac?.status as keyof typeof OFAC_STATUSES}
                statusType="ofac"
              />
            </Box>
          )}

          {integrationData?.cip && (
            <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
              <Typography className={styles.classes.systemAccountsColoredLabel}>CIP</Typography>
              <IntegrationStatusChip
                status={integrationData?.cip?.status as keyof typeof OFAC_STATUSES}
                statusType="cip"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

interface IntegrationStatusChipProps {
  status:
    | keyof typeof OFAC_STATUSES
    | keyof typeof INTEGRATION_STASUSES
    | keyof typeof CIP_STATUSES;
  statusType: string;
}

const IntegrationStatusChip = ({ status, statusType }: IntegrationStatusChipProps) => {
  const theme = useTheme();

  const ProvisioningStatusesChipColor = {
    Error: theme.palette.error.main,
    CriticalError: theme.palette.error.main,
    InProgress: theme.palette.warning.main,
    Completed: theme.palette.success.main,
  };

  const OfacStatusesChipColor = {
    IGNORED: theme.palette.error.main,
    REJECTED: theme.palette.error.main,
    PENDING_VERIFICATION: theme.palette.warning.main,
    UNDER_REVIEW: theme.palette.warning.main,
    VERIFIED: theme.palette.success.main,
  };

  const CipStatusesChipColor = {
    IGNORED: theme.palette.error.main,
    REJECTED: theme.palette.error.main,
    PENDING_VERIFICATION: theme.palette.warning.main,
    UNDER_REVIEW: theme.palette.warning.main,
    VERIFIED: theme.palette.success.main,
    UNVERIFIED: theme.palette.warning.main,
  };

  const getStatusChip = () => {
    switch (statusType) {
      case 'ofac': {
        return {
          chipColor: OfacStatusesChipColor[status as keyof typeof OFAC_STATUSES],
          statusText: OFAC_STATUSES[status as keyof typeof OFAC_STATUSES],
        };
      }
      case 'provisioning': {
        return {
          chipColor: ProvisioningStatusesChipColor[status as keyof typeof INTEGRATION_STASUSES],
          statusText: INTEGRATION_STASUSES[status as keyof typeof INTEGRATION_STASUSES],
        };
      }
      case 'cip': {
        return {
          chipColor: CipStatusesChipColor[status as keyof typeof CIP_STATUSES],
          statusText: CIP_STATUSES[status as keyof typeof CIP_STATUSES],
        };
      }
    }
  };

  const styles = useStyles({ chipColor: getStatusChip()?.chipColor });

  // return <Box className={styles.classes.integrationChip}>{getStatusChip()?.statusText}</Box>;
  return <Chip label={getStatusChip()?.statusText} className={styles.classes.statusPillBar} />;
};

export default ClientBankingIntergrationContainer;
