import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RuleIcon from '@mui/icons-material/Rule';
import DrawIcon from '@mui/icons-material/Draw';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Theme, useTheme, Typography } from '@mui/material';

import {
  handleGetCaseTypes,
  handleGetDefendants,
  handleGetFirms,
  handleGetPlaintiffs,
  mapCaseTypesQueryResults,
  mapDefendantsQueryResults,
  mapFirmsQueryResults,
  mapPlaintiffsQueryResults,
} from './helpers';
import { CaseEditFormSubmitProps, DefendantField, SecondaryFirmsField } from './types';

import { abandonCase, submitCase, updateCaseRecord } from '../../api/casesApi/casesApi';
import Loader from '../../components/Loader';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormDateField from '../../components/formFields/FormDateField';
import FormTextField from '../../components/formFields/FormTextField';
import FormSelectField from '../../components/formFields/FormSelectField';
import { CASES_EDIT_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import { casesEditFormSchema } from '../../helpers/formSchemas/formSchemas';
import AddButton from '../../components/buttons/AddButton';
import FormAutocompleteField from '../../components/formFields/FormAutocompleteField';
import FormCopyTextField from '../../components/formFields/FormCopyTextField';
import FormLinkButton from '../../components/buttons/FormLinkButton';
import CommonButton from '../../components/buttons/CommonButton';
import usePageTitle from '../../hooks/usePageTitle';
import useSnackBar from '../../hooks/useSnackBar';
import useWindowWidth from '../../hooks/useWindowWidth';
import { EntityFlexibleRecord } from '../../types';
import { resetClientAccount } from '../../api/clientsApi/clientsApi';
import { SECONDARY_FIRM_ROLES_OPTIONS } from '../../constants/constants';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 950px)': {
      flexBasis: '480px',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 1200px)': {
      flexBasis: '416px',
    },

    '@media (max-width: 950px)': {
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 950px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  defendantFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: '16px',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  dateFieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    marginTop: '16px',
    flexDirection: 'column',
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

const CasesEditFormContainer = () => {
  const styles = useStyles();
  const theme = useTheme();
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const { caseId } = useParams();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const firmId = localStorage.getItem('firmId');

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [currentCaseStatus, setCurrentCaseStatus] = useState<string>('');
  const [isAbandonCasePopupOpen, setIsAbandonCasePopupOpen] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(casesEditFormSchema),
    defaultValues: CASES_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const clientFieldValue = watch('clientId');
  const defendantsFieldValue = watch('defendant');
  const plaintiffId = watch('clientId.id');
  const isDraft = currentCaseStatus === 'Draft';
  const canAbandon = entityData?.canBeAbandoned;
  const isAbandon = currentCaseStatus === 'Abandoned';

  const { fields, append, remove } = useFieldArray({
    name: 'defendant',
    control,
  });

  const {
    fields: secondaryFirmsFields,
    append: secondaryFirmAppend,
    remove: secondaryFirmRemove,
  } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const handleAddSecondaryFirm = () => {
    secondaryFirmAppend({
      firmId: { id: '', label: '' },
      firmRole: '',
    });
  };

  const handleAddDefendant = () => {
    append({
      defendantId: { id: '', label: '' },
      gross: '',
    });
  };

  const onSubmitForm = (values: CaseEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const mappedDefendants = values?.defendant?.map((defendant: DefendantField) => ({
      id: Number(defendant.defendantId.id),
      grossSettlement: defendant.gross || null,
    }));

    const mappedFirms = values?.secondaryFirms?.map((firm: SecondaryFirmsField) => ({
      role: firm.firmRole,
      id: firm?.firmId?.id,
    }));

    const submitData = {
      referenceId: values.referenceId,
      plaintiffId: Number(values.clientId.id),
      defendants: mappedDefendants,
      sol: moment(values.sol).format('MM-DD-YYYY'),
      filedAt: moment(values.filedAt).format('MM-DD-YYYY'),
      secondaryFirms: mappedFirms || [],
    };

    updateCaseRecord(caseId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/cases');

          setAlert((prev) => ({
            ...prev,
            message: 'Case updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleSubmitCase = () => {
    submitCase(caseId as string)
      .then(() => {
        navigate('/cases');
        setAlert((prev) => ({
          ...prev,
          message: 'Case submitted successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleAbandonCase = () => {
    abandonCase(caseId as string)
      .then(() => {
        navigate('/cases');
        setAlert((prev) => ({
          ...prev,
          message: 'Case abandoned successfully.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  const handleResetClientAccount = () => {
    resetClientAccount(plaintiffId as string)
      .then(() => {
        setAlert((prev) => ({
          ...prev,
          message: 'App invitation has been successfully sent.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  useEffect(() => {
    if (entityData) {
      setCurrentCaseStatus(entityData.status as string);

      reset(entityData);

      setPageTitle && setPageTitle(entityData.name as string);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <>
      {!entityData ? (
        <Loader colorType="warning" />
      ) : (
        <>
          <Box className={styles.classes.pageContainer}>
            <FormProvider {...formMethods}>
              <form className={styles.classes.formContainer}>
                <Box className={styles.classes.formInfoContainer}>
                  <Box className={styles.classes.formInnerWrapper}>
                    <Box className={styles.classes.formWrapper}>
                      <Box display="flex" flexDirection="column" width="100%" flexBasis="416px">
                        <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                        <Box mt="8px">
                          <FormCopyTextField entityName="Case" name="id" label="Id" isReadOnly />
                        </Box>
                        <Box mt="24px">
                          <FormTextField name="name" label="System Name" readOnly isReadOnly />
                        </Box>
                        <Box mt="24px">
                          <FormTextField
                            name="referenceId"
                            label="Reference Id *"
                            disabled={!isDraft}
                          />
                        </Box>
                        <Box mt="24px">
                          <FormAutocompleteField
                            disabled={!isDraft}
                            name={'type'}
                            label="Case Type *"
                            width="100%"
                            filters={{
                              firmId: Number(firmId),
                            }}
                            optionsQuery={handleGetCaseTypes}
                            mapQueryResults={mapCaseTypesQueryResults}
                          />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Key Dates
                        </Typography>
                        <Box className={styles.classes.dateFieldsContainer}>
                          <Box width="100%">
                            <FormDateField
                              name="filedAt"
                              label="Date Filed *"
                              disabled={!isDraft}
                            />
                          </Box>
                          <Box width="100%">
                            <FormDateField name="sol" label="SOL Date *" disabled={!isDraft} />
                          </Box>
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="column" width="100%" flexBasis="416px">
                        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                          <Typography className={styles.classes.fieldHeadline}>
                            Plaintiff
                          </Typography>
                          <FormLinkButton
                            path={`/clients/${clientFieldValue && clientFieldValue.id}/home`}
                            disabled={!clientFieldValue}
                          />
                        </Box>
                        <Box mt="8px">
                          <FormAutocompleteField
                            disabled={!isDraft}
                            name="clientId"
                            label="Client *"
                            optionsQuery={handleGetPlaintiffs}
                            mapQueryResults={mapPlaintiffsQueryResults}
                            filters={{
                              firmId: Number(firmId),
                            }}
                          />
                        </Box>
                        <Box mt="16px" display="flex">
                          <AddButton buttonText="Create new client" path="/clients/create-client" />
                        </Box>

                        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                          <Typography className={styles.classes.fieldHeadline} mt="32px">
                            Defendants
                          </Typography>
                          <FormLinkButton
                            path={`/defendants/${
                              defendantsFieldValue && defendantsFieldValue[0]?.defendantId?.id
                            }/home`}
                            disabled={
                              !(defendantsFieldValue && defendantsFieldValue[0]?.defendantId?.id)
                            }
                          />
                        </Box>

                        {fields.map((field, idx) => (
                          <Box className={styles.classes.defendantFieldsContainer} key={field.id}>
                            <FormAutocompleteField
                              name={`defendant.${idx}.defendantId`}
                              label="Defendant *"
                              width="100%"
                              maxWidth={windowWidth <= 1200 ? '100%' : '272px'}
                              disabled={!isDraft}
                              optionsQuery={handleGetDefendants}
                              mapQueryResults={mapDefendantsQueryResults}
                              filters={{
                                firmId: Number(firmId),
                              }}
                            />

                            <Box
                              display="flex"
                              width={windowWidth <= 1200 ? '100%' : 'inherit'}
                              gap="16px"
                            >
                              <FormTextField
                                name={`defendant.${idx}.gross`}
                                label="Gross Settlement"
                                maxWidth={windowWidth <= 1200 ? '100%' : '180px'}
                                disabled={!isDraft}
                              />

                              {idx >= 1 ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                    color: theme.palette.error.main,

                                    '&.Mui-disabled': {
                                      color: 'rgba(255, 255, 255, 0.08)',
                                    },
                                  }}
                                  onClick={() => remove(idx)}
                                  disabled={!isDraft}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </Box>
                        ))}

                        <Box
                          mt="16px"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          gap="16px"
                        >
                          <AddButton
                            buttonText="Create new defendant"
                            path="/defendants/create-defendant"
                          />
                          <AddButton
                            buttonText="Add defendant"
                            handleClick={handleAddDefendant}
                            disabled={!isDraft}
                          />
                        </Box>

                        <Typography className={styles.classes.fieldHeadline} mt="32px">
                          Firms
                        </Typography>

                        {secondaryFirmsFields.map((field, idx) => (
                          <Box className={styles.classes.defendantFieldsContainer} key={field.id}>
                            <FormAutocompleteField
                              name={`secondaryFirms.${idx}.firmId`}
                              label="Firm *"
                              maxWidth={windowWidth <= 1200 ? '100%' : '272px'}
                              optionsQuery={handleGetFirms}
                              mapQueryResults={mapFirmsQueryResults}
                              width="100%"
                              minWidth="180px"
                              disabled={!isDraft}
                            />

                            <Box
                              display="flex"
                              width={windowWidth <= 1200 ? '100%' : 'inherit'}
                              gap="16px"
                            >
                              <FormSelectField
                                name={`secondaryFirms.${idx}.firmRole`}
                                label="Role"
                                maxWidth={windowWidth <= 1200 ? '100%' : '180px'}
                                options={SECONDARY_FIRM_ROLES_OPTIONS}
                                width={windowWidth <= 1200 ? '100%' : 'auto'}
                                {...(windowWidth <= 1200 && { minWidth: 'unset' })}
                                disabled={!isDraft}
                              />
                              <IconButton
                                sx={{
                                  padding: 0,
                                  color: theme.palette.error.main,

                                  '&.Mui-disabled': {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                  },
                                }}
                                onClick={() => secondaryFirmRemove(idx)}
                                disabled={!isDraft}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}

                        <Box
                          mt="16px"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          maxWidth="468px"
                          gap="16px"
                        >
                          <AddButton
                            buttonText="Add firm"
                            handleClick={handleAddSecondaryFirm}
                            disabled={!isDraft}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" width="100%" justifyContent="center">
                      <Box
                        display="flex"
                        mt="32px"
                        maxWidth="416px"
                        flexDirection="column"
                        gap="16px"
                        width="100%"
                      >
                        <FormSubmitButton
                          disabled={
                            !isValid || !isDirty || isAbandon || !isDraft || isFormSubmitting
                          }
                          buttonIcon={<SaveIcon />}
                          buttonText="Save"
                          onClick={handleSubmit(onSubmitForm)}
                        />
                        <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="column" width="100%" flexBasis="200px">
                    <Typography className={styles.classes.fieldHeadline}>Actions</Typography>

                    {isDraft && (
                      <Box mt="16px">
                        <CommonButton
                          type="warning"
                          buttonText="submit"
                          buttonIcon={<SaveIcon />}
                          onButtonClick={handleSubmitCase}
                          disabled={
                            !isValid || isDirty || !isDraft || isFormSubmitting || isAbandon
                          }
                        />
                      </Box>
                    )}

                    <Box mt="24px">
                      <CommonButton
                        onButtonClick={handleResetClientAccount}
                        type="success"
                        buttonText="send app invite"
                        buttonIcon={<MobileScreenShareIcon />}
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="issue virtual card"
                        buttonIcon={<CreditCardIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="issue physical card"
                        buttonIcon={<CreditCardIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="request document"
                        buttonIcon={<DocumentScannerIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="request signature"
                        buttonIcon={<DrawIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="info"
                        buttonText="request data"
                        buttonIcon={<RuleIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="add deduction"
                        buttonIcon={<ReceiptIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        type="success"
                        buttonText="process settlement"
                        buttonIcon={<PaymentsIcon />}
                        disabled
                      />
                    </Box>

                    <Box mt="16px">
                      <CommonButton
                        onButtonClick={() => setIsAbandonCasePopupOpen(true)}
                        type="error"
                        buttonText="abandon"
                        buttonIcon={<DoNotDisturbIcon />}
                        disabled={
                          !isValid || isDirty || (!canAbandon as boolean) || isFormSubmitting
                        }
                      />
                    </Box>

                    {/* <Box mt="16px">
                      <CommonButton
                        type="error"
                        buttonText="archive"
                        buttonIcon={<ArchiveIcon />}
                        disabled
                      />
                    </Box> */}
                  </Box>
                </Box>
              </form>
            </FormProvider>
            <Popup
              isOpen={isAbandonCasePopupOpen}
              contentText={POPUP_DESCRIPTIONS.caseAbandon.contentText}
              headlineText={POPUP_DESCRIPTIONS.caseAbandon.headline}
              onMainButtonClick={handleAbandonCase}
              onSecondaryButtonClick={() => setIsAbandonCasePopupOpen(false)}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default CasesEditFormContainer;
