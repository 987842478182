import { BaseSyntheticEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';

import FormTextField from './formFields/FormTextField';
import Loader from './Loader';

import { getCasesList } from '../api/casesApi/casesApi';
import { SearchCaseData } from '../types';

const useStyles = makeStyles()((theme: Theme) => ({
  popupContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',

      '&::-webkit-scrollbar': {
        width: '5px',
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 20,
        backgroundColor: theme.palette.secondary.dark,
      },
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.secondary.main,
    },
  },
  popupActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: 0,
    marginTop: '32px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  popupSelectButton: {
    color: theme.palette.common.white,
    background: theme.palette.info.main,
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    height: '48px',

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '&:hover': {
      background: theme.palette.info.main,
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  popupCancelButton: {
    color: theme.palette.common.white,
    background: theme.palette.info.dark,
    maxWidth: '200px',
    borderRadius: '12px',
    width: '100%',
    height: '48px',

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '&:hover': {
      background: theme.palette.info.dark,
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  popupContent: {
    overflowY: 'hidden',
    marginTop: '32px',
    padding: 0,
    flex: 'none',
  },
  noResultsText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultsHeadline: {
    fontSize: '20px',
    lineHeight: '41px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textAlign: 'center',

    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
  },
  searchResultsBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    maxHeight: '300px',
    overflowY: 'auto',

    '@media (max-width: 550px)': {
      paddingRight: '10px',
    },

    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  resultItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    padding: '10px',
    borderRadius: '5px',
    gap: '50px',

    '@media (max-width: 550px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 255, 255, 0.19)',
    },
  },
  resultItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '33%',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  resultItemColumnLabel: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#93959E',
    fontFamily: 'Inter',
  },
  resultItemColumnText: {
    fontSize: '16px',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
  },
  resultItemActiveBgColor: {
    backgroundColor: 'rgba(255, 255, 255, 0.19)',

    '@media (max-width: 550px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      flexBasis: '33%',
      whiteSpace: 'nowrap',
      minWidth: 0,
    },
  },
  dialogTitle: {
    padding: 0,
    fontSize: '20px',
    lineHeight: '41px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textAlign: 'center',
    marginBottom: '32px',

    '@media (max-width: 600px)': {
      fontSize: '16px',
    },
  },
  formWrapper: {
    '@media (max-width: 550px)': {
      paddingRight: '16px',
    },
  },
}));

interface SearchCasePopupProps {
  isOpen: boolean;
  onMainButtonClick: (item: SearchCaseData) => void;
  onSecondaryButtonClick?: () => void;
}

const SearchCasePopup = ({
  isOpen,
  onMainButtonClick,
  onSecondaryButtonClick,
}: SearchCasePopupProps) => {
  const styles = useStyles();
  const firmId = localStorage.getItem('firmId');

  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchCaseData[]>([]);
  const [totalCount, setTotalCount] = useState();
  const [selectedResultItem, setSelectedResultItem] = useState<SearchCaseData | null>(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchQuery: '',
    },
  });

  const { reset, setValue } = formMethods;

  const handleSearchCase = (value: string) => {
    setSearchInputValue(value);
    setValue('searchQuery', value);
    setIsDataLoading(true);
    handleResetPopup();

    if (value.length > 2) {
      getCasesList({
        page: 1,
        size: 50,
        count: true,
        match: value,
        filters: { firmId: firmId || '' },
      })
        .then((res) => {
          if (res.data.resultSet) {
            setSearchResult(res.data.resultSet);
            setTotalCount(res.data.totalCount);
            setIsDataLoading(false);
          }
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    } else {
      setSearchResult([]);
      setPage(1);
      setIsDataLoading(false);
    }
  };

  const loadMoreResults = async () => {
    if (totalCount !== searchResult.length) {
      setIsDataLoading(true);
      getCasesList({
        page: page + 1,
        size: 10,
        count: false,
        match: searchInputValue,
      })
        .then((res) => {
          if (res.data) {
            setPage(page + 1);
            setSearchResult([...searchResult, ...(res.data.resultSet as [])]);
            setIsDataLoading(false);
          }
        })
        .catch(() => {
          setIsDataLoading(false);
        });
    }
  };

  const handleSelectResultItem = (item: SearchCaseData) => {
    setSelectedResultItem(item);
  };

  const handleScroll = (event: BaseSyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const handleResetPopup = () => {
    setPage(1);
    setSearchResult([]);
    setSelectedResultItem(null);
  };

  const handleClosePopup = () => {
    reset();
    handleResetPopup();
    onSecondaryButtonClick && onSecondaryButtonClick();
  };

  const onSelectResultItem = () => {
    onMainButtonClick(selectedResultItem as SearchCaseData);
    reset();
    handleResetPopup();
  };

  return (
    <Dialog
      className={styles.classes.popupContainer}
      open={isOpen}
      onClose={handleClosePopup}
      fullWidth
    >
      <DialogTitle className={styles.classes.dialogTitle}>
        Search case by Reference ID or System Name
      </DialogTitle>
      <Box className={styles.classes.formWrapper}>
        <FormProvider {...formMethods}>
          <form style={{ width: '100%' }}>
            <FormTextField name="searchQuery" label="Search" handleChange={handleSearchCase} />
          </form>
        </FormProvider>
      </Box>

      <DialogContent
        className={clsx(
          styles.classes.popupContent,
          !searchResult.length && styles.classes.noResultsText,
        )}
      >
        {isDataLoading && !searchResult.length ? (
          <Box position="absolute" top="52%" left="45%">
            <Loader size={50} colorType="info" />
          </Box>
        ) : !isDataLoading && searchResult.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography className={styles.classes.noResultsHeadline}>
              No results. Type at least 3 symbols to search.
            </Typography>
          </Box>
        ) : (
          <Box className={styles.classes.searchResultsBlock} onScroll={handleScroll}>
            {searchResult.map((resultItem) => (
              <Box
                onClick={() => handleSelectResultItem(resultItem)}
                className={clsx(
                  styles.classes.resultItemContainer,
                  resultItem.id === selectedResultItem?.id &&
                    styles.classes.resultItemActiveBgColor,
                )}
                key={resultItem.referenceId}
              >
                <Box className={styles.classes.resultItemColumn}>
                  <Typography className={styles.classes.resultItemColumnLabel}>
                    Reference ID
                  </Typography>
                  <Typography className={styles.classes.resultItemColumnText}>
                    {resultItem.referenceId}
                  </Typography>
                </Box>
                <Box className={styles.classes.resultItemColumn}>
                  <Typography className={styles.classes.resultItemColumnLabel}>
                    System Name
                  </Typography>
                  <Typography className={styles.classes.resultItemColumnText}>
                    {resultItem.name}
                  </Typography>
                </Box>
                <Box className={styles.classes.resultItemColumn}>
                  <Typography className={styles.classes.resultItemColumnLabel}>Firm</Typography>
                  <Typography className={styles.classes.resultItemColumnText}>
                    {resultItem.firm.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions className={styles.classes.popupActions}>
        <Button
          className={styles.classes.popupSelectButton}
          onClick={onSelectResultItem}
          disabled={!selectedResultItem}
        >
          Select
        </Button>
        <Button className={styles.classes.popupCancelButton} onClick={handleClosePopup}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchCasePopup;
