import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import UsersTableDataRow from './UsersTableDataRow';

import { getLawyersList } from '../../api/usersApi/usersApi';
import TableContainer from '../../components/Table/TableContainer';
import Loader from '../../components/Loader';
import { LAWYERS_LIST_TABLE_LABELS } from '../../constants/tableLabels';
import usePagination from '../../hooks/usePagination';
import { ActiveSortField, FilterTypes, LawyerData } from '../../types';
import NoDataText from '../../components/NoDataText';
import TableFilters from '../../components/TableFilters/TableFilters';
import TableActions from '../../components/Table/TableActions';
import { downloadCsv } from '../../helpers/downloadCsv';
import useSnackBar from '../../hooks/useSnackBar';
import { handleSortTableData } from '../../helpers/handleSortTableData';

const UsersView = () => {
  const params = useParams();
  const firmId = localStorage.getItem('firmId');

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();
  const { setAlert } = useSnackBar();

  const [lawyersList, setLawyersList] = useState<LawyerData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const hasParams = !!Object.entries(params).length;

  const [filters, setFilters] = useState<FilterTypes>({
    firmId: firmId || '',
  });
  const [match, setMatch] = useState<string>('');
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    name: {
      order: '',
      fieldName: 'name',
    },
    createdAt: {
      order: '',
      fieldName: 'createdAt',
    },
  };

  useEffect(() => {
    getLawyersList({
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: { ...filters, ...(hasParams && params) },
      activeSortField,
    }).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setLawyersList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, match, activeSortField]);

  const exportList = () => {
    downloadCsv('/v1/lawyers/export', 'POST', 'Lawyers_List', {
      page: pageNumber,
      size: rowsPerPage,
      count: true,
      match: match,
      filters: filters,
    }).then((res) => {
      if (res?.data) {
        setAlert({
          message: 'Users exported successfully.',
          type: 'success',
        });
      }
    });
  };

  return (
    <>
      {isDataLoading && !lawyersList.length ? (
        <Box position="absolute" top="50%" left="50%">
          <Loader colorType="warning" />
        </Box>
      ) : (
        <>
          <TableActions
            hasParams={hasParams}
            createLink="/users/create-user"
            exportList={exportList}
            match={match}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            setMatch={setMatch}
          />

          {showFilters && (
            <TableFilters
              filters={filters}
              setFilters={setFilters}
              setMatch={setMatch}
              timeFilter={'CREATED'}
              match={match}
              rolesFilter={[
                { id: 'Admin', label: 'Admin' },
                { id: 'Support', label: 'Support' },
                { id: 'Finance', label: 'Finance' },
              ]}
            />
          )}

          {!isDataLoading && lawyersList.length === 0 ? (
            <NoDataText />
          ) : (
            <TableContainer
              headerLabels={LAWYERS_LIST_TABLE_LABELS}
              sortableFields={sortableFields}
              activeSortField={activeSortField}
              setActiveSortField={setActiveSortField}
              handleSortTableData={handleSortTableData}
            >
              <UsersTableDataRow tableData={lawyersList} />
            </TableContainer>
          )}
        </>
      )}

      <Outlet />
    </>
  );
};

export default UsersView;
