import {
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      minHeight: 'auto',
      padding: '32px',
      borderRadius: '16px',
    },

    '& .MuiDialogContentText-root': {
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    textAlign: 'center',
    padding: '0px',
  },
  dialogContent: {
    marginTop: '16px',
    padding: '0px',
  },
  dialogContentText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    padding: '0px',
    marginTop: '32px',

    '@media (max-width: 550px)': {
      flexDirection: 'column',
      gap: '16px',

      '@media (max-width: 550px)': {
        paddingRight: '16px',
      },
    },
  },
  buttonDefault: {
    height: '48px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '12px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    color: theme.palette.common.white,

    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.08)',
      background: 'rgba(255, 255, 255, 0.08)',
    },

    '@media (max-width: 550px)': {
      maxWidth: '100%',
    },
  },
  selectButton: {
    background: theme.palette.info.main,

    '&:hover': {
      background: theme.palette.info.main,
    },
  },
  cancelButton: {
    background: theme.palette.info.dark,

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
}));

interface PopupProps {
  isOpen: boolean;
  headlineText: string;
  contentText: string;
  onMainButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const Popup = ({
  isOpen,
  headlineText,
  onMainButtonClick,
  onSecondaryButtonClick,
  contentText,
}: PopupProps) => {
  const { classes } = useStyles();

  return (
    <Dialog className={classes.dialogContainer} open={isOpen} onClose={onSecondaryButtonClick}>
      <DialogTitle className={classes.dialogTitle}>{headlineText}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={clsx(classes.buttonDefault, classes.selectButton)}
          onClick={onMainButtonClick}
        >
          {'Ok'}
        </Button>
        {onSecondaryButtonClick && (
          <Button
            className={clsx(classes.buttonDefault, classes.cancelButton)}
            onClick={onSecondaryButtonClick}
          >
            {'Cancel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
