import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';
import jwtDecode from 'jwt-decode';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import { getLawyerRecord, updateLawyerRecord } from '../../../api/usersApi/usersApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import { MANAGE_ACCOUNT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import { manageAccountFormSchema } from '../../../helpers/formSchemas/formSchemas';
import useAuth from '../../../hooks/useAuth';
import FormSelectField from '../../../components/formFields/FormSelectField';
import { ROLE_OPTIONS } from '../../../constants/constants';
import useSnackBar from '../../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  formWrapper: {
    display: 'flex',
  },
  formMainContainer: {
    padding: '32px',
    flexBasis: '896px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',

    '@media (max-width: 725px)': {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 725px)': {
      flexDirection: 'column',
      gap: 0,
    },
  },
  formInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 725px)': {
      flexBasis: 0,
    },
  },
  formControlPanel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    gap: '16px',
    maxWidth: '416px',

    '@media (max-width: 725px)': {
      maxWidth: 'none',
    },
  },
  formSecondInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 725px)': {
      flexBasis: 0,
      marginTop: '32px',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
}));

export interface ManageAccountFormSubmitProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
}

const ManageAccountHomeTab = () => {
  const styles = useStyles();
  const { token } = useAuth();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [userReferenceId, setUserReferenceId] = useState<string | null>(null);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(manageAccountFormSchema),
    defaultValues: MANAGE_ACCOUNT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
  } = formMethods;

  const userIdValue = watch('id');

  const onSubmitForm = (values: ManageAccountFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    updateLawyerRecord(userIdValue, submitData)
      .then((res) => {
        if (!res.data.error) {
          setAlert((prev) => ({
            ...prev,
            message: 'Account updated successfully',
            type: 'success',
          }));

          reset({
            id: res.data.id,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            role: ROLE_OPTIONS[0].id,
          });
        }

        setIsFormSubmitting(false);
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    if (token) {
      const userTokenData: { [x: string]: string } = jwtDecode(token);

      setUserReferenceId(userTokenData.username);

      if (userReferenceId) {
        getLawyerRecord(userReferenceId).then((res) => {
          if (res.data) {
            reset({
              id: res.data.id,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              email: res.data.email,
              phone: res.data.phone,
              role: ROLE_OPTIONS[0].id,
            });
          }
        });
      }
    }
  }, [token, userReferenceId]);

  return (
    <Box className={styles.classes.formWrapper}>
      <FormProvider {...formMethods}>
        <form className={styles.classes.formMainContainer}>
          <Box className={styles.classes.formInfoContainer}>
            <Box className={styles.classes.formInfoBlock}>
              <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
              <Box mt="16px">
                <FormCopyTextField entityName="Account" name="id" label="Id" isReadOnly />
              </Box>
              <Box mt="24px">
                <FormTextField name="firstName" label="First Name *" />
              </Box>
              <Box mt="24px">
                <FormTextField name="lastName" label="Last Name *" />
              </Box>

              <Typography className={styles.classes.fieldHeadline} mt="32px">
                Credentials
              </Typography>

              <Box mt="16px">
                <FormTextField name="email" label="Email Address *" disabled />
              </Box>
              <Box mt="24px">
                <FormTextField name="phone" label="Mobile Number *" disabled />
              </Box>
            </Box>

            <Box className={styles.classes.formSecondInfoBlock}>
              <Typography className={styles.classes.fieldHeadline}>Role</Typography>

              <Box mt="16px">
                <FormSelectField width="100%" options={ROLE_OPTIONS} name="role" label="Role *" />
              </Box>
            </Box>
          </Box>

          <Box display="flex" width="100%" justifyContent="center">
            <Box
              display="flex"
              mt="32px"
              maxWidth="416px"
              flexDirection="column"
              gap="16px"
              width="100%"
            >
              <FormSubmitButton
                disabled={!isValid || !isDirty || isFormSubmitting}
                buttonIcon={<SaveIcon />}
                buttonText="Save"
                onClick={handleSubmit(onSubmitForm)}
              />
              <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ManageAccountHomeTab;
