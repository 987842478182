import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getCaseTypes } from '../../api/casesApi/caseTypesApi';
import Loader from '../../components/Loader';
import { CaseTypeData, ErrorData, PaginationQueryParams } from '../../types';
import useSnackBar from '../../hooks/useSnackBar';
import FormToggleField from '../../components/formFields/FormToggleField';
import FormSearchField from '../../components/formFields/FormSearchField';

import CaseTypeList from './CaseTypeList';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  toggleWrapper: {
    marginLeft: '2px',
  },
}));

interface CaseTypesSearchSectionProps {
  readonly firmId: string;
  readonly selectedCaseType: CaseTypeData | null;
  readonly handleSelect: (caseType: CaseTypeData | null) => void;
}

interface FormSubmitProps {
  includeGlobal: boolean;
  name: string;
}

const schema = yup
  .object()
  .shape({
    includeGlobal: yup.boolean().required('Required *'),
    name: yup.string().required('Required *'),
  })
  .required();

const CaseTypesSearchSection = ({
  firmId,
  selectedCaseType,
  handleSelect,
}: CaseTypesSearchSectionProps) => {
  const { setAlert } = useSnackBar();
  const { classes } = useStyles();

  const [caseTypesList, setCaseTypesList] = useState<Array<CaseTypeData>>([]);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 15;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { includeGlobal: false, name: '' },
  });

  const { setValue, watch } = formMethods;

  const searchValue = watch('name');
  const includeGlobal = watch('includeGlobal');

  const getFetchParams = ({
    page,
    count,
    match,
    includeGlobal,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
    includeGlobal?: boolean;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match?.trim() || '',
      includeGlobal,
    };

    return queryParams;
  };

  const handleCancelButtonClick = () => {
    setIsLoading(true);
    setValue('name', '');
    fetchCaseTypes(getFetchParams({ includeGlobal }));
  };

  const fetchCaseTypes = (queryParams: PaginationQueryParams) => {
    handleSelect(null);

    getCaseTypes({
      page: queryParams.page,
      size: queryParams.size,
      count: queryParams.count,
      match: queryParams.match,
      includeGlobal: queryParams.includeGlobal,
      filters: { firmId },
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setCaseTypesList(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setCaseTypesList(Array.from(new Set([...caseTypesList, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleLoadMoreButtonClick = () => {
    if (totalCount !== caseTypesList.length) {
      setIsFetching(true);
      fetchCaseTypes(
        getFetchParams({ page: page + 1, count: false, match: searchValue, includeGlobal }),
      );
    }
  };

  const handleSearchInputChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      setIsLoading(true);
      fetchCaseTypes(getFetchParams({ match: value, includeGlobal }));
    }
  };

  const handleToggle = (value: boolean) => {
    setIsLoading(true);
    fetchCaseTypes(
      getFetchParams({
        match: searchValue,
        includeGlobal: value,
      }),
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCaseTypes(getFetchParams({}));
  }, [firmId]);

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form className={classes.formContainer}>
          <Box className={classes.toggleWrapper}>
            <FormToggleField
              name="includeGlobal"
              label="Include Global"
              customHandleChange={handleToggle}
            />
          </Box>
          <FormSearchField
            name="name"
            searchValue={searchValue}
            handleChange={handleSearchInputChange}
            handleResetSearch={handleCancelButtonClick}
          />
        </form>
      </FormProvider>

      {isLoading ? (
        <Loader colorType="warning" />
      ) : (
        <CaseTypeList
          data={caseTypesList}
          selectedCaseType={selectedCaseType}
          isFetching={isFetching}
          totalCount={totalCount}
          handleLoadMoreButtonClick={handleLoadMoreButtonClick}
          handleSelect={(caseType) => handleSelect(caseType)}
        />
      )}
    </Box>
  );
};

export default CaseTypesSearchSection;
