import moment from 'moment/moment';

export const FILTER_DATE_OPTIONS = [
  {
    id: 'today',
    label: 'Today',
  },
  {
    id: 'thisWeek',
    label: 'This Week',
  },
  {
    id: 'lastWeek',
    label: 'Last Week',
  },
  {
    id: 'thisMonth',
    label: 'This Month',
  },
  {
    id: 'lastMonth',
    label: 'Last Month',
  },
  {
    id: 'earlier',
    label: 'Earlier',
  },
];

export const DATE_FILTER_VALUES = {
  today: {
    from: moment().startOf('day').format(),
    to: moment().endOf('day').format(),
  },
  thisWeek: {
    from: moment().startOf('week').format(),
    to: moment().endOf('week').format(),
  },
  lastWeek: {
    from: moment().subtract(1, 'week').startOf('week').format(),
    to: moment().subtract(1, 'week').endOf('week').format(),
  },
  thisMonth: {
    from: moment().startOf('month').format(),
    to: moment().endOf('month').format(),
  },
  lastMonth: {
    from: moment().subtract(1, 'month').startOf('month').format(),
    to: moment().subtract(1, 'month').endOf('month').format(),
  },
  earlier: {
    from: moment(0).format(),
    to: moment().subtract(1, 'month').startOf('month').format(),
  },
};

export const DEDUCTION_TYPE_OPTIONS = [
  {
    id: 'Advance',
    label: 'Advance',
  },
  {
    id: 'Lien',
    label: 'Lien',
  },
];

export const CASES_STATUS_FILTER_OPTIONS = [
  {
    id: 'Draft',
    label: 'Draft',
  },
  {
    id: 'Active',
    label: 'Active',
  },
  {
    id: 'Closed',
    label: 'Closed',
  },
  {
    id: 'Abandoned',
    label: 'Abandoned',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const DEDUCTIONS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Paid',
    label: 'Paid',
  },
  {
    id: 'Unpaid',
    label: 'Unpaid',
  },
  {
    id: 'Canceled',
    label: 'Canceled',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const DATA_REQUESTS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Draft',
    label: 'Draft',
  },
  {
    id: 'Unopened',
    label: 'Unopened',
  },
  {
    id: 'Opened',
    label: 'Opened',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Canceled',
    label: 'Canceled',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const DOCUMENT_REQUESTS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Draft',
    label: 'Draft',
  },
  {
    id: 'Unopened',
    label: 'Unopened',
  },
  {
    id: 'Opened',
    label: 'Opened',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Rejected',
    label: 'Rejected',
  },
  {
    id: 'Canceled',
    label: 'Canceled',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const SIGNATURE_REQUESTS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Opened',
    label: 'Opened',
  },
  {
    id: 'Completed',
    label: 'Completed',
  },
  {
    id: 'Rejected',
    label: 'Rejected',
  },
  {
    id: 'CancelInProgress',
    label: 'Cancel In Progress',
  },
  {
    id: 'Canceled',
    label: 'Canceled',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const CLIENTS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Created',
    label: 'Created',
  },
  {
    id: 'Active',
    label: 'Active',
  },
  {
    id: 'Deactivated',
    label: 'Deactivated',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];

export const SETTLEMENTS_STATUS_FILTER_OPTIONS = [
  {
    id: 'Draft',
    label: 'Draft',
  },
  {
    id: 'Paid',
    label: 'Paid',
  },
  {
    id: 'Canceled',
    label: 'Canceled',
  },
  // {
  //   id: 'Archived',
  //   label: 'Archived',
  // },
];
