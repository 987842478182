import api from '../service/api.service';

import { ActiveSortField, CaseTypeData, PaginationQueryParams } from '../../types';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';

const BASE_URL_V1 = '/v1/case-types';
const BASE_URL_V2 = '/v2/case-types';

export const getGlobalCaseTypes = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V2}/global?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters}${sortFilters}`;

  return await api.get<{
    resultSet: CaseTypeData[];
    totalCount: number | null;
  }>(targetUrl);
};

export const getCaseTypes = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
  includeGlobal,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL_V2}/?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters}${sortFilters}&includeGlobal=${includeGlobal}`;

  return await api.get<{
    resultSet: CaseTypeData[];
    totalCount: number | null;
  }>(targetUrl);
};

export const createCaseType = async (data: { firmId?: number | null; name: string }) => {
  return await api.post<CaseTypeData>(BASE_URL_V2, data);
};

export const updateCaseType = async (
  id: number,
  data: {
    name?: string;
  },
) => {
  const targetUrl = `${BASE_URL_V2}/${id}`;

  return await api.put<CaseTypeData>(targetUrl, data);
};

export const deleteCaseType = async (id: number) => {
  const targetUrl = `${BASE_URL_V2}/${id}`;

  return await api.delete<void>(targetUrl);
};

export const linkTrustAccount = async (caseTypeId: number, data: { trustAccountId: number }) => {
  const targetUrl = `${BASE_URL_V1}/${caseTypeId}/link-trust-account`;

  return await api.put<void>(targetUrl, data);
};
