import { Box, Divider, Typography } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import moment from 'moment/moment';

interface ITimeline {
  data: { status: string; date: string }[];
}

const Timeline = ({ data }: ITimeline) => {
  return data?.length ? (
    <Box p="16px" width="100%">
      {data.map((item, index) => (
        <Box key={item.date}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <AccessTime htmlColor="white" />
            <Typography
              fontSize="12px"
              width="100px"
              textTransform="uppercase"
              color="white"
              fontFamily="Inter"
            >
              {item.status}
            </Typography>
            <Typography fontSize="14px" color="white" fontFamily="Inter">
              {moment(item.date).format('MM/DD/YYYY, h:mm:ss A')}
            </Typography>
          </Box>

          {index < data.length - 1 ? (
            <Box display="flex" justifyContent="center" width="24px">
              <Divider
                flexItem
                orientation="vertical"
                sx={{
                  borderRightWidth: 0,
                  background: 'white',
                  height: '24px',
                  width: '1px',
                }}
              />
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  ) : (
    <></>
  );
};

export default Timeline;
