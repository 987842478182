import { Typography, Box, useTheme } from '@mui/material';

import { ReactComponent as NoResultsIcon } from './no-results.svg';

const NoDataText = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="16px"
      marginTop="200px"
    >
      <NoResultsIcon />
      <Typography
        fontSize="20px"
        fontFamily="Inter"
        fontWeight="500"
        color={theme.palette.common.white}
      >
        No results found
      </Typography>
    </Box>
  );
};

export default NoDataText;
