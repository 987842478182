import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ReactNode, createContext } from 'react';
import { useNavigate } from 'react-router';

import { cognitoPool } from '../views/SignInView/cognitoPool';
import { getLawyerRecord } from '../api/usersApi/usersApi';
import jwtDecode from 'jwt-decode';

/* eslint-disable  @typescript-eslint/no-explicit-any */
let sessionUserAttributes: any;

export const AuthContext = createContext<AuthContextProps>({
  token: null,
  refreshToken: null,
  onLogin: undefined,
  onLogOut: undefined,
  handleChangeNewPassword: undefined,
  handleSendForgotPasswordEmail: undefined,
  handleResetPassword: undefined,
});

export function getSession() {
  const cognitoUser = cognitoPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error('No user found'));
      return;
    }
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    });
  });
}

interface AuthContextProps {
  token: string | null;
  refreshToken: string | null;
  onLogin?: (email: string, password: string) => Promise<any>;
  onLogOut?: () => void;
  handleChangeNewPassword?: (newPassword: string) => Promise<any>;
  handleSendForgotPasswordEmail?: (email: string) => Promise<any>;
  handleResetPassword?: (code: string, newPassword: string) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();

  const getUser = async (token: string) => {
    const userTokenData: { [x: string]: string } = jwtDecode(token);

    await getLawyerRecord(userTokenData.username).then((res) => {
      if (res.data) {
        localStorage.setItem('role', res.data.role);
        localStorage.setItem('firmId', res.data.firm.id);
      }
    });
  };

  const signIn = (username: string, password: string) => {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const accessToken = result?.getAccessToken().getJwtToken();
          const token = result?.getRefreshToken().getToken();

          localStorage.setItem('refresh_token', token);
          localStorage.setItem('access_token', accessToken);
          await getUser(accessToken);

          navigate('/cases');

          setTimeout(() => {
            resolve(result);
            console.log('user successfully pass first screen');
          }, 350);
        },

        newPasswordRequired: function (userAttributes) {
          localStorage.setItem('username', username);
          localStorage.setItem('tempPassword', password);
          userAttributes && navigate('changePassword');
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const handleChangeNewPassword = (newPassword: string) => {
    return new Promise((resolve, reject) => {
      const username = localStorage.getItem('username');
      const tempPassword = localStorage.getItem('tempPassword');
      if (!username || !tempPassword) {
        return reject('Something went wrong, please try to sign in again');
      }
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: tempPassword,
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const accessToken = result?.getAccessToken().getJwtToken();
          const token = result?.getRefreshToken().getToken();

          localStorage.setItem('refresh_token', token);
          localStorage.setItem('access_token', accessToken);
          await getUser(accessToken);

          setTimeout(() => {
            navigate('/cases');
            console.log('user successfully pass first screen');
          }, 350);
        },

        newPasswordRequired: function (userAttributes) {
          // userAttributes.name = name;

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.phone_number;
          delete userAttributes.email;

          // store userAttributes on global variable
          sessionUserAttributes = userAttributes;

          cognitoUser.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
            onSuccess: async function (result) {
              // User authentication was successful
              const accessToken = result?.getAccessToken().getJwtToken();
              const token = result?.getRefreshToken().getToken();

              localStorage.setItem('refresh_token', token);
              localStorage.setItem('access_token', accessToken);
              await getUser(accessToken);

              navigate('/cases');
            },
            onFailure: function (err) {
              console.error(err);
            },
          });
        },

        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const handleSendForgotPasswordEmail = (email: string) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: cognitoPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: function () {
          localStorage.setItem('username', email);
          resolve('new password has been sent');
          navigate('resetPassword');
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  const handleResetPassword = (code: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
      const username = localStorage.getItem('username');

      if (!username) {
        return reject('Username not found');
      }

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoPool,
      });

      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess() {
          resolve('Password has been reseted.');
          navigate('/sign-in');
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  };

  const handleLogOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
    localStorage.removeItem('firmId');
    navigate('/sign-in');
  };

  const value = {
    token: localStorage.getItem('access_token'),
    refreshToken: localStorage.getItem('refresh_token'),
    onLogin: signIn,
    onLogOut: handleLogOut,
    handleChangeNewPassword,
    handleSendForgotPasswordEmail,
    handleResetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
