import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Theme } from '@mui/material';

import ClientBankingIntergrationContainer from './ClientBankingIntergrationContainer';
import ClientBankingWalletAccountContainer from './ClientBankingWalletAccountContainer';
import ClientBankingTableDataRow from './ClientBankingTableDataRow';

import { ClientBankingData } from '../types';

import {
  getClientBankingInfo,
  getClientTransactionList,
  startClientProvisioning,
} from '../../../api/clientsApi/clientsApi';
import TableActions from '../../../components/Table/TableActions';
import Loader from '../../../components/Loader';
import TableFilters from '../../../components/TableFilters/TableFilters';
import TableContainer from '../../../components/Table/TableContainer';
import { CLIENTS_TRANSACTIONS_LIST_TABLE_LABELS } from '../../../constants/tableLabels';
import { handleSortTableData } from '../../../helpers/handleSortTableData';
import usePagination from '../../../hooks/usePagination';
import { ActiveSortField, ClientTransactionsData, FilterTypes } from '../../../types';
import NoDataText from '../../../components/NoDataText';

const useStyles = makeStyles()((theme: Theme) => ({
  systemAccountsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '20px',

    '@media (max-width: 775px)': {
      flexDirection: 'column',
    },
  },
  systemAccountContainer: {
    display: 'flex',
    padding: '20px',
    background: theme.palette.primary.light,
    flexDirection: 'column',
    maxWidth: '240px',
    width: '100%',
    borderRadius: '12px',
    boxSizing: 'border-box',
    height: '190px',

    '@media (max-width: 775px)': {
      flexDirection: 'column',
      maxWidth: '280px',
    },
  },
  systemAccountsInnerContainer: {
    height: '100%',
  },
  systemAccountDivider: {
    height: '100%',
    width: '1px',
    background: '#393939',

    '&:last-of-type': {
      display: 'none',
    },
  },
}));

const ClientBankingView = () => {
  const styles = useStyles();
  const { plaintiffId } = useParams();

  const [bankingData, setBankingData] = useState<ClientBankingData>();

  const params = useParams();

  const { pageNumber, rowsPerPage, setTotalCount } = usePagination();

  const [clientTransactionList, setClientTransactionList] = useState<ClientTransactionsData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  const [filters, setFilters] = useState<FilterTypes>({});
  const [activeSortField, setActiveSortField] = useState<ActiveSortField | null>(null);
  const sortableFields = {
    lastUpdatedOn: {
      order: '',
      fieldName: 'lastUpdatedOn',
    },
  };

  const hasParams = !!Object.entries(params).length;

  const fetchBankingData = () => {
    getClientBankingInfo(plaintiffId as string).then((res) => {
      setBankingData(res.data);
    });
  };

  const handleIntegrationSubmit = () => {
    startClientProvisioning(plaintiffId as string)
      .then(() => {
        fetchBankingData();
      })
      .catch(() => {
        fetchBankingData();
      });
  };

  useEffect(() => {
    getClientTransactionList(
      {
        page: pageNumber,
        size: rowsPerPage,
        filters,
        activeSortField,
      },
      plaintiffId as string,
    ).then((res) => {
      if (res.data) {
        setTotalCount && setTotalCount(res.data.totalCount);
        setClientTransactionList(res.data.resultSet);
        setIsDataLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage, filters, activeSortField]);

  useEffect(() => {
    fetchBankingData();
  }, []);

  return (
    <Box pr="8px">
      <Box className={styles.classes.systemAccountsWrapper}>
        <ClientBankingIntergrationContainer
          canStartIntegration={bankingData?.canStartIntegration}
          handleIntegrationSubmit={handleIntegrationSubmit}
          integrationData={bankingData?.integration}
        />
        <Box className={styles.classes.systemAccountContainer}>
          <Box className={styles.classes.systemAccountsInnerContainer}>
            {bankingData?.account && (
              <ClientBankingWalletAccountContainer accountData={bankingData?.account} />
            )}
          </Box>
        </Box>
      </Box>

      <>
        {isDataLoading && !clientTransactionList.length ? (
          <Box position="absolute" top="50%" left="50%">
            <Loader colorType="warning" />
          </Box>
        ) : (
          <>
            <TableActions hasParams={hasParams} setShowFilters={setShowFilters} />

            {showFilters && (
              <TableFilters filters={filters} setFilters={setFilters} timeFilter={'CREATED'} />
            )}

            {!isDataLoading && clientTransactionList.length === 0 ? (
              <NoDataText />
            ) : (
              <TableContainer
                headerLabels={CLIENTS_TRANSACTIONS_LIST_TABLE_LABELS}
                sortableFields={sortableFields}
                activeSortField={activeSortField}
                setActiveSortField={setActiveSortField}
                handleSortTableData={handleSortTableData}
              >
                <ClientBankingTableDataRow tableData={clientTransactionList} />
              </TableContainer>
            )}
          </>
        )}
      </>
    </Box>
  );
};

export default ClientBankingView;
