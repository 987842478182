import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useOutletContext } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from 'tss-react/mui';

// import ArchiveIcon from '@mui/icons-material/Archive';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Theme, Typography } from '@mui/material';

import {
  cancelSignatureRequest,
  updateSignatureRecord,
} from '../../../api/requestsApi/signaturesApi';
import FormTextField from '../../../components/formFields/FormTextField';
import FormSubmitButton from '../../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../../components/buttons/FormCancelButton';
import CommonButton from '../../../components/buttons/CommonButton';
import { signaturesEditFormSchema } from '../../../helpers/formSchemas/formSchemas';
import { SIGNATURES_EDIT_FORM_DEFAULT_VALUES } from '../../../helpers/formSchemas/formDefaultValues';
import FormCopyTextField from '../../../components/formFields/FormCopyTextField';
import { SignaturesEditFormSubmitProps } from '../types';
import FormLinkButton from '../../../components/buttons/FormLinkButton';
import CancelIcon from '@mui/icons-material/Cancel';
import usePageTitle from '../../../hooks/usePageTitle';
import DocumentDownloader from '../../../components/DocumentsDownloader/DocumentDownloader';
import useSnackBar from '../../../hooks/useSnackBar';
import { EntityFlexibleRecord } from '../../../types';
import Popup from '../../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../../constants/popupDescriptions';

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexBasis: '1184px',

    '@media (max-width: 800px)': {
      flexBasis: '100%',
    },
  },
  formWrapper: {
    display: 'flex',
    gap: '64px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: '32px',
    },
  },
  formInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '16px',
    flexBasis: '896px',

    '@media (max-width: 1000px)': {
      flexBasis: '416px',
    },

    '@media (max-width: 800px)': {
      flexBasis: '100%',
      padding: '12px',
    },
  },
  formInfoContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',

    '@media (max-width: 800px)': {
      flexDirection: 'column',
    },
  },
  formPlaceInfoContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',

    '@media (max-width: 1000px)': {
      flexDirection: 'column',
    },
  },
  fieldHeadline: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
  },
  formDocBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '416px',

    '@media (max-width: 1000px)': {
      flexBasis: 0,
    },
  },
  formActionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexBasis: '200px',

    '@media (max-width: 800px)': {
      flexBasis: 0,
    },
  },
}));

const SignaturesEditFormContainer = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { signatureId } = useParams<string>();
  const { setPageTitle } = usePageTitle();
  const { setAlert } = useSnackBar();
  const { entityData } = useOutletContext<EntityFlexibleRecord>();

  const [plaintiffId, setPlaintiffId] = useState<string>();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(signaturesEditFormSchema),
    defaultValues: SIGNATURES_EDIT_FORM_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = formMethods;

  const caseField = watch('case');
  const documentUrl = watch('documentUrl');

  const onSubmitForm = (values: SignaturesEditFormSubmitProps) => {
    setIsFormSubmitting(true);

    const submitData = {
      name: values.name,
      documentId: values.documentId,
    };

    updateSignatureRecord(signatureId as string, submitData)
      .then((res) => {
        if (!res.data.error) {
          setIsFormSubmitting(false);
          navigate('/requests/signature-requests');

          setAlert((prev) => ({
            ...prev,
            message: 'Signature request updated successfully.',
            type: 'success',
          }));
        }
      })
      .catch((err) => {
        setIsFormSubmitting(false);
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelSignatureRequest = () => {
    cancelSignatureRequest(signatureId as string)
      .then(() => {
        navigate(-1);
        setAlert((prev) => ({
          ...prev,
          message: 'Signature request was successfully canceled.',
          type: 'success',
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          message: err.response.data.message,
          type: 'error',
        }));
      });
  };

  const handleCancelChanges = () => reset();

  useEffect(() => {
    if (entityData) {
      setPlaintiffId(entityData.plaintiffId as string);

      if (entityData?.fileId) {
        reset({
          ...entityData,
        });
      } else {
        reset({
          ...entityData,
        });
      }

      setPageTitle && setPageTitle(entityData.name as string);
    }

    return () => setPageTitle && setPageTitle('');
  }, [entityData]);

  return (
    <Box className={styles.classes.pageContainer}>
      <FormProvider {...formMethods}>
        <form className={styles.classes.formContainer}>
          <Box className={styles.classes.formInfoContainer}>
            <Box className={styles.classes.formInnerWrapper}>
              <Box className={styles.classes.formWrapper}>
                <Box display="flex" flexDirection="column" flexBasis="416px">
                  <Typography className={styles.classes.fieldHeadline}>Identity</Typography>
                  <Box mt="16px">
                    <FormCopyTextField
                      entityName="Signature request"
                      name="id"
                      label="Id"
                      isReadOnly
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mt="32px"
                  >
                    <Typography className={styles.classes.fieldHeadline}>Case</Typography>
                    <FormLinkButton path={`/cases/${caseField.id}/home`} />
                  </Box>
                  <Box mt="16px">
                    <FormTextField name="case.referenceId" label="Reference Id" disabled />
                  </Box>
                  <Box mt="24px">
                    <FormTextField name="case.name" label="System Name" disabled />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mt="32px"
                  >
                    <Typography className={styles.classes.fieldHeadline}>Client</Typography>
                    <FormLinkButton path={`/clients/${plaintiffId}/home`} />
                  </Box>
                  <Box mt="16px">
                    <FormTextField name="plaintiffFullName" label="Client" disabled />
                  </Box>

                  <Typography className={styles.classes.fieldHeadline} mt="32px">
                    Document
                  </Typography>
                  <Box mt="16px">
                    <FormTextField name="name" label="Document Name *" />
                  </Box>
                  <Box mt="24px">
                    <FormTextField name="documentId" label="Document Id *" />
                  </Box>
                </Box>

                <Box className={styles.classes.formDocBlock}>
                  <DocumentDownloader url={documentUrl} fileName={'docusign'} />
                </Box>
              </Box>

              <Box display="flex" width="100%" justifyContent="center">
                <Box
                  display="flex"
                  mt="32px"
                  maxWidth="416px"
                  flexDirection="column"
                  gap="16px"
                  width="100%"
                >
                  <FormSubmitButton
                    disabled={!isValid || !isDirty || isFormSubmitting}
                    buttonIcon={<SaveIcon />}
                    buttonText="Save"
                    onClick={handleSubmit(onSubmitForm)}
                  />
                  <FormCancelButton disabled={!isDirty} onClick={handleCancelChanges} />
                </Box>
              </Box>
            </Box>

            <Box className={styles.classes.formActionsWrapper}>
              <Typography className={styles.classes.fieldHeadline}>Actions</Typography>
              <Box mt="16px">
                <CommonButton
                  disabled={entityData?.status === 'Opened' ? false : true}
                  type="error"
                  buttonText="cancel"
                  buttonIcon={<CancelIcon />}
                  onButtonClick={() => setIsCancelPopupOpen(true)}
                />
              </Box>
              {/* <Box mt="16px">
                <CommonButton
                  disabled
                  type="error"
                  buttonText="archive"
                  buttonIcon={<ArchiveIcon />}
                />
              </Box> */}
            </Box>
          </Box>
        </form>
      </FormProvider>
      <Popup
        isOpen={isCancelPopupOpen}
        headlineText={POPUP_DESCRIPTIONS.signatureRequestCancel.headlineText}
        contentText={POPUP_DESCRIPTIONS.signatureRequestCancel.contentText}
        onMainButtonClick={handleCancelSignatureRequest}
        onSecondaryButtonClick={() => setIsCancelPopupOpen(false)}
      />
    </Box>
  );
};

export default SignaturesEditFormContainer;
