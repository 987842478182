import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import TabsPanel from '../../../components/TabsPanel';
import { signaturesAccountTabs } from '../../../constants/tabRoutes';
import { getSignatureFile, getSignatureRecord } from '../../../api/requestsApi/signaturesApi';

const SignaturesAccountView = () => {
  const { signatureId } = useParams<string>();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    getSignatureRecord(signatureId as string).then((res) => {
      if (res.data) {
        if (res.data?.fileId) {
          getSignatureFile(signatureId as string).then((fileRes) => {
            setEntityRecordData({
              plaintiffFullName: `${res.data.plaintiff.firstName} ${
                res.data.plaintiff.middleName || ''
              } ${res.data.plaintiff.lastName}`,
              ...res.data,
              documentUrl: fileRes?.data,
              plaintiffId: res.data.plaintiff.id,
              status: res.data.status,
            });
          });
        } else {
          setEntityRecordData({
            id: res.data.id,
            case: {
              ...res.data.case,
            },
            plaintiffFullName: `${res.data.plaintiff.firstName} ${
              res.data.plaintiff.middleName || ''
            } ${res.data.plaintiff.lastName}`,
            firm: {
              id: res.data.firm.id,
              name: res.data.firm.name,
            },
            name: res.data.name,
            documentId: res.data.documentId,
            plaintiffId: res.data.plaintiff.id,
            status: res.data.status,
          });
        }
      }
    });
  }, [signatureId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={signaturesAccountTabs}
          locationSlicePosition={4}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default SignaturesAccountView;
