import { Box } from '@mui/material';

import TabsPanel from '../../components/TabsPanel';
import { casesAccountTabs } from '../../constants/tabRoutes';
import moment from 'moment';
import { getCaseRecord } from '../../api/casesApi/casesApi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CaseAccountView = () => {
  const { caseId } = useParams();
  const [entityRecordData, setEntityRecordData] = useState<{ [key: string]: unknown }>();

  useEffect(() => {
    getCaseRecord(caseId as string).then((res) => {
      const plaintiff = res.data.plaintiff;
      const defendants = res.data.defendants.map(
        (defendant: { id: string; name: string; grossSettlement: string }) => ({
          defendantId: { id: defendant.id, label: defendant.name },
          gross: defendant.grossSettlement ? defendant.grossSettlement : '',
        }),
      );

      const mappedSecondaryFirms = res.data?.secondaryFirms?.map(
        (firm: { id: string; name: string; role: string }) => ({
          firmId: {
            id: firm.id,
            label: firm.name,
          },
          firmRole: firm.role,
        }),
      );

      setEntityRecordData({
        id: res.data.id,
        referenceId: res.data.referenceId,
        type: {
          id: res.data.type.id,
          label: res.data.type.name,
        },
        clientId: { id: plaintiff.id, label: `${plaintiff.firstName} ${plaintiff.lastName}` },
        defendant: defendants,
        sol: moment(res.data.sol),
        filedAt: moment(res.data.filedAt),
        name: res.data.name,
        secondaryFirms: mappedSecondaryFirms,
        status: res.data.status,
        canBeAbandoned: res.data.canBeAbandoned,
      });
    });
  }, [caseId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={casesAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default CaseAccountView;
