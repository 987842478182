import { Box } from '@mui/material';

import CaseTypesEditSection from './CaseTypesEditSection';

const CaseTypesView = () => {
  const firmId = localStorage.getItem('firmId');

  return (
    <Box padding={'16px 0px 16px 0px'} height={'100vh'}>
      {firmId && <CaseTypesEditSection firmId={firmId} />}
    </Box>
  );
};

export default CaseTypesView;
