import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ClearIcon from '@mui/icons-material/Clear';

import { Box, Button, IconButton } from '@mui/material';

import { DATE_FILTER_VALUES, DEDUCTION_TYPE_OPTIONS, FILTER_DATE_OPTIONS } from './constants';
import { useStyles } from './styles';

import FormAutocompleteField from '../formFields/FormAutocompleteField';
// import FormToggleField from '../formFields/FormToggleField';
import FormSelectField from '../formFields/FormSelectField';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { FilterTypes, SelectOption } from '../../types';
import {
  handleGetDefendants,
  handleGetPlaintiffs,
  mapDefendantsQueryResults,
  mapPlaintiffsQueryResults,
} from '../../views/CasesView/helpers';
import useWindowWidth from '../../hooks/useWindowWidth';
import usePagination from '../../hooks/usePagination';

interface ITableFilters {
  filters: FilterTypes;
  setFilters: React.Dispatch<React.SetStateAction<FilterTypes>>;
  setMatch?: (arg: string) => void;
  searchDebounce?: number;
  timeFilter?: string;
  deductionTypeFilter?: SelectOption[];
  statusFilter?: SelectOption[];
  plaintiff?: boolean;
  defendant?: boolean;
  rolesFilter?: SelectOption[];
  match?: string;
  caseTypesList?: SelectOption[];
}

const TableFilters = ({
  filters,
  setFilters,
  timeFilter,
  deductionTypeFilter,
  statusFilter,
  plaintiff,
  defendant,
  rolesFilter,
  setMatch,
  match,
  caseTypesList,
}: ITableFilters) => {
  const styles = useStyles();
  const isFirstRender = useIsFirstRender();
  const windowWidth = useWindowWidth();
  const { setPageNumber } = usePagination();

  const [, setDateFilter] = useState<string>('');

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      searchQuery: '',
      isArchived: false,
      plaintiffId: { id: '', label: '' },
      defendantId: { id: '', label: '' },
      type: '',
      dateFilter: '',
      status: '',
      role: '',
      caseTypeId: '',
      state: '',
    },
  });

  const {
    watch,
    reset,
    formState: { isDirty },
  } = formMethods;

  const plaintiffFieldValue = watch('plaintiffId');
  const defendantFieldValue = watch('defendantId');
  const roleFieldValue = watch('role');
  const caseTypeFieldValue = watch('caseTypeId');

  useEffect(() => {
    if (isFirstRender) return;

    const newState = {
      plaintiffId: plaintiffFieldValue?.id || '',
      defendantId: defendantFieldValue?.id || '',
      role: roleFieldValue || '',
      caseTypeId: caseTypeFieldValue || '',
    };

    setFilters((prevState) => {
      setPageNumber && setPageNumber(1);
      if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
        return {
          ...prevState,
          ...newState,
        };
      } else {
        return prevState;
      }
    });
  }, [plaintiffFieldValue, defendantFieldValue, roleFieldValue, caseTypeFieldValue]);

  const customHandleDateChange = (value: string) => {
    setPageNumber && setPageNumber(1);
    setDateFilter(value);

    const fromDate = new Date(DATE_FILTER_VALUES[value as keyof typeof DATE_FILTER_VALUES].from);
    const toDate = new Date(DATE_FILTER_VALUES[value as keyof typeof DATE_FILTER_VALUES].to);

    setFilters((prevState) => ({
      ...prevState,
      date: { from: fromDate, to: toDate },
    }));
  };

  const handleFiltersGroupClick = (value: string, name: string) => {
    setPageNumber && setPageNumber(1);
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilters = () => {
    delete filters.date;
    delete filters.type;
    delete filters.plaintiffId;
    delete filters.status;
    delete filters.role;
    delete filters.caseTypeId;
    delete filters.state;

    setMatch && setMatch('');
    reset();
    setDateFilter('');
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.classes.filtersForm}>
        <Box className={styles.classes.filtersContainer}>
          {/* <Box minWidth="175px" ml="5px">
            <FormToggleField name="isArchived" label="Show Archived" disabled />
          </Box> */}

          {plaintiff ? (
            <FormAutocompleteField
              name="plaintiffId"
              label="Plaintiff"
              optionsQuery={handleGetPlaintiffs}
              mapQueryResults={mapPlaintiffsQueryResults}
              filters={{
                firmId: filters.firmId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              minWidth="180px"
            />
          ) : null}

          {defendant ? (
            <FormAutocompleteField
              name="defendantId"
              label="Defendant"
              optionsQuery={handleGetDefendants}
              mapQueryResults={mapDefendantsQueryResults}
              filters={{
                firmId: filters.firmId,
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              minWidth="180px"
            />
          ) : null}

          {caseTypesList?.length ? (
            <FormSelectField
              minWidth="180px"
              options={caseTypesList}
              name="caseTypeId"
              label="Case Type"
              shouldClearable
            />
          ) : null}

          {timeFilter ? (
            <FormSelectField
              name="dateFilter"
              label="Created"
              options={FILTER_DATE_OPTIONS}
              handleChange={customHandleDateChange}
              minWidth="180px"
            />
          ) : null}

          {rolesFilter ? (
            <FormSelectField
              label="Roles"
              options={rolesFilter}
              name="role"
              handleChange={handleFiltersGroupClick}
              minWidth="180px"
            />
          ) : null}

          {deductionTypeFilter ? (
            <FormSelectField
              name="type"
              label="Type"
              options={DEDUCTION_TYPE_OPTIONS}
              minWidth="180px"
              handleChange={handleFiltersGroupClick}
            />
          ) : null}

          {statusFilter ? (
            <FormSelectField
              label="Status"
              options={statusFilter}
              name="status"
              minWidth="180px"
              handleChange={handleFiltersGroupClick}
            />
          ) : null}

          {windowWidth <= 550 ? (
            <Button
              disableRipple
              className={styles.classes.clearFiltersButton}
              disabled={!(isDirty || match?.length)}
              onClick={handleResetFilters}
            >
              Clear filters
            </Button>
          ) : (
            <IconButton
              disableRipple
              className={styles.classes.clearIconContainer}
              disabled={!(isDirty || match?.length)}
              onClick={handleResetFilters}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </form>
    </FormProvider>
  );
};

export default TableFilters;
