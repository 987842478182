import { Navigate } from 'react-router';

import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { token } = useAuth();
  const role = localStorage.getItem('role');

  if (!token) {
    return <Navigate to="/" replace />;
  }

  const baseRouteName = location.pathname.split('/')[1];
  if (baseRouteName === 'users' && role !== 'Admin') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
