import { Fragment, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import theme from '../../theme';

const options = ['Default Trust Account', 'Delete'];

const useStyles = makeStyles()((theme: Theme) => ({
  buttonGroup: {
    width: 'auto',
    maxWidth: '100%',
    borderRadius: '12px',
  },
  default: {
    display: 'flex',
    height: '48px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.08)',
    color: theme.palette.text.disabled,

    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.common.white,
    },
  },
  buttonText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '14px',
  },
}));

interface LocalCaseTypeButtonProps {
  readonly buttonText: string;
  readonly onPress?: () => void;
  readonly onDelete?: () => void;
  readonly onDefaultTrustAccountClick?: () => void;
}

const LocalCaseTypeButton = ({
  buttonText,
  onPress,
  onDelete,
  onDefaultTrustAccountClick,
}: LocalCaseTypeButtonProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    onPress?.();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    switch (index) {
      case 0:
        onDefaultTrustAccountClick?.();
        break;
      case 1:
        onDelete?.();
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup
        className={classes.buttonGroup}
        disableElevation
        ref={anchorRef}
        variant="contained"
      >
        <Button className={clsx(classes.default)} onClick={handleClick}>
          <Typography className={classes.buttonText} noWrap>
            {buttonText}
          </Typography>
        </Button>
        <Button className={clsx(classes.default)} size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              borderRadius: '12px',
              backgroundColor: theme.palette.secondary.dark,
              boxShadow: 'none',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      className={classes.buttonText}
                      disabled={index === 2}
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default LocalCaseTypeButton;
