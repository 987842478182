import api from '../service/api.service';
import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = '/v1';

export const getLawyersList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}/lawyers?size=${size}&page=${page}&count=${count}&match=${
    match || ''
  }${queryFilters || ''}${sortFilters || ''}`;

  return await api.get(targetUrl);
};

export const createLawyerRecord = async (data: unknown) => {
  const targetUrl = `${BASE_URL}/lawyers`;

  return await api.post(targetUrl, data);
};

export const getLawyerRecord = async (lawyerId: string) => {
  const targetUrl = `${BASE_URL}/lawyers/${lawyerId}`;

  return await api.get(targetUrl);
};

export const updateLawyerRecord = async (lawyerId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/lawyers/${lawyerId}`;

  return await api.put(targetUrl, data);
};

export const resetLawyerAccount = async (lawyerId: string) => {
  const targetUrl = `${BASE_URL}/lawyers/${lawyerId}/reset`;

  return await api.post(targetUrl, {});
};
