import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { Box } from '@mui/material';

import { getClientRecord } from '../../api/clientsApi/clientsApi';
import TabsPanel from '../../components/TabsPanel';
import { clientsAccountTabs } from '../../constants/tabRoutes';

const ClientAccountView = () => {
  const { plaintiffId } = useParams();
  const [entityRecordData, setEntityRecordData] = useState();

  useEffect(() => {
    getClientRecord(plaintiffId as string).then((res) => {
      if (res.data) {
        setEntityRecordData({
          ...res.data,
          firmId: { id: res.data.firm.id, label: res.data.firm.name },
          address2: res.data.address2 || '',
          middleName: res.data.middleName || '',
          state: res.data.countryState,
          ssn: '***-**-****',
          birthdate: moment('**/**/****'),
        });
      }
    });
  }, [plaintiffId]);

  return (
    <>
      <Box display="flex" width="100%">
        <TabsPanel
          tabsList={clientsAccountTabs}
          locationSlicePosition={3}
          contextData={entityRecordData}
        />
      </Box>
    </>
  );
};

export default ClientAccountView;
