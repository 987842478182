import { CaseTypesCategories } from './constants/constants';

export interface FilterTypes {
  firmId?: number | string;
  referenceId?: string;
  plaintiffId?: number | string;
  defendantId?: number | string;
  categoryId?: number | string;
  caseId?: number | string;
  isArchived?: boolean;
  date?: {
    from: Date;
    to: Date;
  };
  status?: string;
  role?: string;
  type?: string;
  caseTypeId?: number | string;
  state?: string;
  category?: string;
}

export type AdminsListData = {
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  isSuperAdmin: boolean;
  lastName: string;
  phone: string;
  referenceId: string;
  updatedAt: string;
  isEnabled: boolean;
};

export type FirmData = {
  id?: string;
  name: string;
  tin: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  trustAccountNumber: string;
  trustAccountRoutingNumber: string;
  brandColor: string;
  zendeskBrandName: string;
  docusignAccountId: string;
  sendAppInviteTrigger?: string;
  createWalletTrigger?: string;
  issueVirtualCardTrigger?: string;
  issuePhysicalCardTrigger?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isClientTriggerActive?: boolean;
  logoImageId?: string;
  createdAt?: string;
  isArchived?: string;
};

export type LawyerData = {
  id: number;
  firm: { id: string; name: string };
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  updatedAt: string;
  createdAt: string;
};

export type SelectOption = {
  id: string;
  label: string;
};

export interface PaginationQueryParams {
  size: number;
  page: number;
  count?: boolean;
  match?: string;
  filters?: FilterTypes;
  activeSortField?: ActiveSortField | null;
  includeGlobal?: boolean;
}

export type DefendantData = {
  id: number;
  name: string;
  referenceId: string;
  firm: { id: string; name: string };
  createdAt: string;
};

export type ClientData = {
  id: number;
  referenceId: string;
  firstName: string;
  middleName: string;
  ssn: string;
  lastName: string;
  email: string;
  phone: string;
  firm: { id: string; name: string };
  createdAt: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  birthdate: string;
  deactivatedAt: null | string;
  status: string;
};

export type CaseData = {
  status: 'Draft' | 'Active' | 'Pending' | 'Closed' | 'Abandoned';
  filedAt: string;
  sol: string;
  closedAt: string | null;
  createdAt: string;
  updatedAt: string;
  id: number;
  referenceId: string;
  firm: {
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  };
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  }>;
  plaintiff: {
    id: number;
    firstName: string;
    middleName: string | null;
    lastName: string;
  };
  defendants: { id: number; name: string; grossSettlement: number }[];
  name: string;
  isArchived: boolean;
  type: {
    id: number;
    name: string;
  };
};

export type DeductionData = {
  id: number;
  type: string;
  status: string;
  firm: { id: string; name: string };
  case: { id: number; name: string };
  defendant: { id: number; name: string };
  plaintiff: { id: number; firstName: string; middleName: string; lastName: string };
  createdAt: string;
  paidAt: string;
  amount: number;
  updatedAt: string;
};

export type SettlementData = {
  id: number;
  status: string;
  firm: { id: string; name: string };
  case: { id: number; name: string };
  defendant: { id: number; name: string };
  plaintiff: { id: number; firstName: string; middleName: string; lastName: string };
  createdAt: string;
  paidAt: string;
  updatedAt: string;
  settledAmount: number;
};

export type DocumentData = {
  id: number;
  status: string;
  updatedAt: string;
  case: { id: number; name: string; referenceId: string };
  completedAt: string;
  createdAt: string;
  fileId: string;
  firm: { id: string; name: string };
  name: string;
  plaintiff: { id: number; firstName: string; middleName: string; lastName: string };
  sentAt: string;
};

export type SignatureData = {
  id: number;
  status: string;
  updatedAt: string;
  case: { id: number; name: string; referenceId: string };
  completedAt: string;
  createdAt: string;
  documentId: string;
  firm: { id: string; name: string };
  name: string;
  plaintiff: { id: number; firstName: string; middleName: string; lastName: string };
};

export type TimelineType = {
  status: string;
  date: string;
}[];

export type SearchCaseData = {
  status: 'Draft' | 'Active' | 'Pending' | 'Closed' | 'Abandoned';
  filedAt: string;
  sol: string;
  closedAt: null | string;
  createdAt: string;
  updatedAt: string;
  id: number;
  referenceId: string;
  firm: {
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  };
  secondaryFirms: Array<{
    role: 'CoCounsel' | 'ReferringCounsel';
    id: number;
    name: string;
    logoImageId: string | null;
    logoImageUrl: string | null;
  }>;
  plaintiff: {
    id: number;
    firstName: string;
    middleName: string | null;
    lastName: string;
  };
  defendants: Array<{
    id: number;
    name: string;
    grossSettlement: number;
  }>;
  name: string;
  isArchived: boolean;
  type: {
    id: number;
    name: string;
  };
};

export interface SortableFieldsObject {
  [key: string]: {
    order: string;
    fieldName: string;
  };
}

export interface ActiveSortField {
  order: string;
  fieldName: string;
}

export type EntityFlexibleRecord = { entityData: { [key: string]: unknown } };

export interface ClientTransactionsData {
  accountId: string;
  amount: string;
  lastUpdatedOn: string;
  memo: string;
  method: string;
  runningBalance: string;
  scheduleClass: string;
  transactionId: string;
  type: string;
}

export type CaseTypeData = {
  category: CaseTypesCategories;
  id: number;
  name: string;
  firmId: number | null;
  trustAccounts: Array<{
    id: number;
    firmId: number;
    name: string;
    accountNumber: string;
    routingNumber: string;
  }>;
};

export type ErrorData = {
  error: string;
  message: string;
  statusCode: number;
};

export type QuestionnaireBooleanAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Boolean;
  value?: boolean | null;
};

export type QuestionnaireDateAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Date;
  value?: string | null;
};

export type QuestionnaireDateRangeAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.DateRange;
  value?: { from?: string | null; to?: string | null } | null;
};

export type QuestionnaireTextAnswer = {
  itemId: string;
  format: QuestionnaireItemFormat.Text;
  value?: string | null;
};

export type QuestionnaireAnswer =
  | QuestionnaireBooleanAnswer
  | QuestionnaireDateAnswer
  | QuestionnaireDateRangeAnswer
  | QuestionnaireTextAnswer;

export type DataRequestShortData = {
  status: string;
  sentAt: string | null;
  completedAt: string | null;
  id: number;
  name: string;
  case: {
    id: number;
    name: string;
    referenceId: string;
    type: {
      id: number;
      name: string;
    };
  };
  firm: {
    id: number;
    name: string;
  };
  plaintiff: {
    id: number;
    firstName: string;
    middleName: string | null;
    lastName: string;
  };
  message: string | null;
  formItemsCount: number;
  formItemAnswersCount: number;
};

export type DataRequestData = DataRequestShortData & {
  form: QuestionnaireForm | null;
  answers: QuestionnaireAnswer[];
  createdAt: string;
  updatedAt: string;
  notes: string | null;
};

export enum QuestionnaireItemFormat {
  Boolean = 'boolean',
  Date = 'date',
  DateRange = 'date_range',
  Text = 'text',
}

type QuestionnaireItemCommonProps = {
  id: string;
  code: string;
  systemName: string;
  questionText: string;
  immutable?: boolean | null;
  dependsOnItem?: {
    booleanItemId: string;
    expectedValue: boolean;
  } | null;
};

export type QuestionnaireBooleanItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Boolean;
};

export type QuestionnaireDateItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Date;
};

export type QuestionnaireDateRangeItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.DateRange;
  allowPartial?: boolean | null;
};

export type QuestionnaireTextItem = QuestionnaireItemCommonProps & {
  format: QuestionnaireItemFormat.Text;
  placeholder?: string | null;
};

export type QuestionnaireItem =
  | QuestionnaireBooleanItem
  | QuestionnaireDateItem
  | QuestionnaireDateRangeItem
  | QuestionnaireTextItem;

export type QuestionnaireSection = {
  title: string;
  items: QuestionnaireItem[];
  immutable?: boolean | null;
};

export type QuestionnaireForm = {
  sections: QuestionnaireSection[];
};

export type QuestionnaireShortData = {
  firm: {
    id: number;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  caseType: {
    id: number;
    name: string;
  };
};

export type QuestionnaireData = QuestionnaireShortData & {
  form: QuestionnaireForm;
};

export type FormSnippetShortData = {
  firm: {
    id: number;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
};

export type FormSnippetData = FormSnippetShortData & {
  data: {
    items: QuestionnaireItem[];
  };
};

export interface FirmTrustAccountData {
  caseType: {
    id: number;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  accountNumber: string;
  routingNumber: string;
  isVerified: boolean;
  firm: {
    id: number;
    name: string;
  };
}
