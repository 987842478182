/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, CircularProgress, Paper, TextField, Theme } from '@mui/material';
import { BaseSyntheticEvent, SyntheticEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { FilterTypes, PaginationQueryParams, SelectOption } from '../../types';

const useStyles = makeStyles<{
  maxWidth?: string;
  autocompleteValue?: string;
  disabled?: boolean;
  minWidth?: string;
  width?: string;
}>()((theme: Theme, { maxWidth, autocompleteValue, disabled, minWidth, width }) => ({
  root: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    ...(width && { width }),

    margin: 0,
    justifyContent: 'flex-end',
    background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',

    '@media (max-width: 550px)': {
      width: '100%',
    },

    '&:hover': {
      '& .MuiAutocomplete-clearIndicator': {
        color: theme.palette.common.white,

        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
      },
    },

    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white,
      padding: '12px 16px !important',
      borderRadius: '12px',

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        WebkitTextFillColor: theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.disabled,
      borderRadius: '12px',
      padding: 0,

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        borderRadius: '12px',

        '&.Mui-disabled': {
          borderColor: theme.palette.secondary.dark,
          borderRadius: '12px',
        },
      },

      '&:hover': {
        border: 'none',

        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            borderColor: 'none',
          },
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiInputLabel-root[data-shrink="false"]': {
      color: theme.palette.text.disabled,
      top: '-3px',

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiInputLabel-root[data-shrink="true"]': {
      color: theme.palette.text.disabled,

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiCircularProgress-root': {
      color: theme.palette.common.white,
    },

    '& .MuiAutocomplete-clearIndicator': {
      color: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiAutocomplete-popupIndicator': {
      color: autocompleteValue ? theme.palette.common.white : theme.palette.text.disabled,

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
  },
  paperDropdown: {
    background: theme.palette.secondary.dark,

    '& .MuiAutocomplete-noOptions': {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter',
    },

    '& .MuiAutocomplete-loading': {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter',
    },

    '& .MuiAutocomplete-option': {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter',
    },
  },
}));

interface FormAutocompleteFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxWidth?: string;
  filters?: FilterTypes;
  optionsQuery: ({ page, size, count, match, filters }: PaginationQueryParams) => Promise<any>;
  mapQueryResults: (array: any[]) => any;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  onValueChange?: (item: any) => void;
  minWidth?: string;
  width?: string;
}

const FormAutocompleteField = ({
  name,
  label,
  disabled,
  maxWidth,
  readOnly,
  filters,
  optionsQuery,
  mapQueryResults,
  isOptionEqualToValue,
  onValueChange,
  minWidth,
  width,
}: FormAutocompleteFieldProps) => {
  const { control, watch } = useFormContext();
  const autocompleteValue = watch(name);

  const styles = useStyles({ maxWidth, autocompleteValue, disabled, minWidth, width });

  const [page, setPage] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [optionsData, setOptionsData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [totalCount, setTotalCount] = useState();

  const handleChangeValue = (value: string) => {
    setInputValue(value);
    setIsDataLoading(true);

    if (value.length < 1) {
      setOptionsData([]);
      setPage(1);
      setIsDataLoading(false);
    } else {
      optionsQuery({ page, size: 10, count: true, match: value, filters })
        .then((res) => {
          if (res.data) {
            setTotalCount(res.data.totalCount);
            const mappedData = mapQueryResults(res.data.resultSet);
            setOptionsData(mappedData);
            setIsDataLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setIsDataLoading(false);
        });
    }
  };

  const loadMoreResults = async () => {
    if (totalCount !== optionsData.length) {
      setIsDataLoading(true);
      optionsQuery({
        page: page + 1,
        size: 10,
        count: false,
        match: inputValue,
        filters,
      })
        .then((res) => {
          if (res.data) {
            setPage(page + 1);
            const mappedData = mapQueryResults(res.data.resultSet);
            setOptionsData([...optionsData, ...(mappedData as [])]);
            setIsDataLoading(false);
          }
        })
        .catch((err) => {
          setIsDataLoading(false);
          console.error(err);
        });
    }
  };

  const handleScroll = (event: BaseSyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const handleBlur = () => {
    setPage(1);
    setOptionsData([]);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          noOptionsText="Start typing..."
          loading={isDataLoading}
          value={value || null}
          className={styles.classes.root}
          disabled={disabled}
          onChange={(_: SyntheticEvent, newValue: SelectOption | null) => {
            onChange(newValue);
            onValueChange && onValueChange(newValue);
          }}
          onBlur={handleBlur}
          getOptionLabel={(option: SelectOption) => option.label}
          isOptionEqualToValue={isOptionEqualToValue}
          options={optionsData}
          readOnly={readOnly}
          PaperComponent={({ children }) => (
            <Paper className={styles.classes.paperDropdown}>{children}</Paper>
          )}
          ListboxProps={{
            role: 'list-box',
            onScroll: handleScroll,
            style: { maxHeight: '200px' },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              onChange={(e) => handleChangeValue(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default FormAutocompleteField;
