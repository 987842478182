export const parseQueryFilters = (filters: unknown) => {
  let resultFiltersQueryString = '';

  if (filters) {
    Object.entries(filters).map(([key, value]) => {
      if (key === 'date' && typeof value === 'object' && value.from && value.to) {
        resultFiltersQueryString += `&filter[date][from]=${value.from}&filter[date][to]=${value.to}`;
      } else if (value !== '') {
        resultFiltersQueryString += `&filter[${key}]=${value}`;
      }
    });
  }

  return resultFiltersQueryString;
};
