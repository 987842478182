import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Loader from '../../components/Loader';
import {
  CaseTypeData,
  ErrorData,
  PaginationQueryParams,
  QuestionnaireShortData,
} from '../../types';
import useSnackBar from '../../hooks/useSnackBar';
import Popup from '../../components/Popup';
import { POPUP_DESCRIPTIONS } from '../../constants/popupDescriptions';
import {
  createQuestionnaire,
  deleteQuestionnaire,
  getQuestionnaires,
  updateQuestionnaire,
} from '../../api/questionnairesApi/questionnairesApi';
import QuestionnaireFormBuilderPopup from '../../components/QuestionnaireFormBuilderPopup';

import QuestionnaireList from './QuestionnaireList';
import { QuestionnaireEditFormSubmitProps } from './types';
import { mapQuestionnaireData } from './helpers/mapQuestionnaireData';
import FormSearchField from '../../components/formFields/FormSearchField';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '16px',
  },
}));

interface QuestionnaireEditSectionProps {
  readonly caseType: CaseTypeData;
  readonly firmId: string;
}

interface FormSubmitProps {
  name: string;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required('Required *'),
  })
  .required();

const QuestionnaireEditSection = ({ caseType, firmId }: QuestionnaireEditSectionProps) => {
  const { classes } = useStyles();
  const { setAlert } = useSnackBar();

  const [questionnaires, setQuestionnaires] = useState<Array<QuestionnaireShortData>>([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireShortData | null>(
    null,
  );
  const [isFormBuilderPopupOpen, setIsFormBuilderPopupOpen] = useState(false);
  const [deletePopupData, setDeletePopupData] = useState<QuestionnaireShortData | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const PAGE_SIZE = 15;

  const formMethods = useForm<FormSubmitProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { name: '' },
  });

  const { setValue, reset, watch } = formMethods;

  const searchValue = watch('name');

  const getFetchParams = ({
    page,
    count,
    match,
  }: {
    page?: number;
    count?: boolean;
    match?: string;
    includeGlobal?: boolean;
  }) => {
    const queryParams: PaginationQueryParams = {
      page: page || 1,
      size: PAGE_SIZE,
      count: count !== undefined ? count : true,
      match: match?.trim() || '',
    };

    return queryParams;
  };

  const handleCancelButtonClick = () => {
    setIsLoading(true);
    reset();
    fetchQuestionnaires(getFetchParams({}));
  };

  const fetchQuestionnaires = (queryParams: PaginationQueryParams) => {
    getQuestionnaires({
      page: queryParams.page,
      size: queryParams.size,
      count: queryParams.count,
      match: queryParams.match,
      filters: {
        caseTypeId: caseType.id,
        firmId,
      },
    })
      .then((response) => {
        if (response.data) {
          setPage(queryParams.page);
          if (queryParams.page === 1) {
            setQuestionnaires(response.data.resultSet);
            setTotalCount(response.data.totalCount);
          } else {
            setQuestionnaires(Array.from(new Set([...questionnaires, ...response.data.resultSet])));
          }
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
      })
      .finally(() => {
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  const handleLoadMoreButtonClick = () => {
    if (totalCount !== questionnaires.length) {
      setIsFetching(true);
      fetchQuestionnaires(getFetchParams({ page: page + 1, count: false, match: searchValue }));
    }
  };

  const handleSearchQuestionnaires = (value: string) => {
    setValue('name', value, { shouldDirty: true });

    if (value.length === 0 || value.trim().length > 2) {
      setIsLoading(true);
      fetchQuestionnaires(getFetchParams({ match: value }));
    }
  };

  const handleCreateQuestionnaire = (values: QuestionnaireEditFormSubmitProps) => {
    setIsLoading(true);

    const data = {
      form: mapQuestionnaireData(values.form),
      firmId: Number(firmId),
      name: values.name.trim(),
      caseTypeId: caseType.id,
    };

    createQuestionnaire(data)
      .then((response) => {
        if (response.status === 201) {
          setAlert((prev) => ({
            ...prev,
            message: 'Questionnaire created successfully.',
            type: 'success',
          }));

          setIsFormBuilderPopupOpen(false);
          fetchQuestionnaires(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleUpdateQuestionnaire = (values: QuestionnaireEditFormSubmitProps) => {
    if (!selectedQuestionnaire) return;

    setIsLoading(true);
    setSelectedQuestionnaire(null);

    const data = {
      name: values.name.trim(),
      form: mapQuestionnaireData(values.form),
    };

    updateQuestionnaire(selectedQuestionnaire.id, data)
      .then((response) => {
        if (response.status === 200) {
          setAlert((prev) => ({
            ...prev,
            message: 'Questionnaire updated successfully.',
            type: 'success',
          }));

          setIsFormBuilderPopupOpen(false);
          fetchQuestionnaires(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  const handleDeleteQuestionnaire = () => {
    if (!deletePopupData) return;

    setIsLoading(true);
    setDeletePopupData(null);

    deleteQuestionnaire(deletePopupData.id)
      .then((response) => {
        if (response.status === 204) {
          setAlert((prev) => ({
            ...prev,
            message: 'Questionnaire deleted successfully.',
            type: 'success',
          }));

          fetchQuestionnaires(getFetchParams({ match: searchValue }));
        }
      })
      .catch((error: AxiosError<ErrorData>) => {
        setAlert((prev) => ({
          ...prev,
          message: error.response?.data.message || 'Error. Something went wrong...',
          type: 'error',
        }));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchQuestionnaires(getFetchParams({}));
  }, [firmId, caseType]);

  return (
    <Box className={classes.container}>
      <FormProvider {...formMethods}>
        <form>
          <FormSearchField
            name="name"
            searchValue={searchValue}
            handleChange={handleSearchQuestionnaires}
            handleResetSearch={handleCancelButtonClick}
          />
        </form>
      </FormProvider>

      {isLoading ? (
        <Loader colorType="warning" />
      ) : (
        <QuestionnaireList
          data={questionnaires}
          isFetching={isFetching}
          totalCount={totalCount}
          handleCreateNewQuestionnaireClick={() => setIsFormBuilderPopupOpen(true)}
          handleDeleteQuestionnaireClick={(questionnaire) => setDeletePopupData(questionnaire)}
          handleEditQuestionnaireClick={(questionnaire) => {
            setSelectedQuestionnaire(questionnaire);
            setIsFormBuilderPopupOpen(true);
          }}
          handleLoadMoreButtonClick={handleLoadMoreButtonClick}
        />
      )}

      <QuestionnaireFormBuilderPopup
        isOpen={isFormBuilderPopupOpen}
        data={selectedQuestionnaire}
        isFormSubmitting={isLoading}
        onMainButtonClick={
          selectedQuestionnaire ? handleUpdateQuestionnaire : handleCreateQuestionnaire
        }
        onSecondaryButtonClick={() => {
          setIsFormBuilderPopupOpen(false);
          setSelectedQuestionnaire(null);
        }}
      />
      <Popup
        isOpen={deletePopupData !== null}
        headlineText={POPUP_DESCRIPTIONS.questionnaireDelete.headlineText}
        contentText={POPUP_DESCRIPTIONS.questionnaireDelete.contentText}
        onMainButtonClick={handleDeleteQuestionnaire}
        onSecondaryButtonClick={() => setDeletePopupData(null)}
      />
    </Box>
  );
};

export default QuestionnaireEditSection;
