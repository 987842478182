import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Theme, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import CasesFeesSummary from './CasesFeesSummary';
import { mapFeesFormValues } from './helpers';
import {
  CaseAttorneyFeesConfigSubmitProps,
  CaseFeesBillCheck,
  FIRM_ROLES,
  SecondaryFirmAttorneysFeesConfig,
} from './types';

import {
  calculateCaseBillCheck,
  createCaseFeesConfig,
  getCaseFeesConfig,
} from '../../api/casesApi/casesApi';
import FormSubmitButton from '../../components/buttons/FormSubmitButton';
import FormCancelButton from '../../components/buttons/FormCancelButton';
import FormTextField from '../../components/formFields/FormTextField';
import { caseAttorneysFeesFormSchema } from '../../helpers/formSchemas/formSchemas';
import { CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES } from '../../helpers/formSchemas/formDefaultValues';
import useSnackBar from '../../hooks/useSnackBar';

const useStyles = makeStyles()((theme: Theme) => ({
  feesPageContainer: {
    display: 'flex',
    gap: '16px',
    width: '100%',
    flexBasis: '100%',
    height: '100%',

    '@media (max-width: 1000px)': {
      maxWidth: '600px',
      flexDirection: 'column',
      gap: '30px',
    },
  },
  feesFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '58px',

    '@media (max-width: 1300px)': {
      flexBasis: '600px',
    },

    '@media (max-width: 1000px)': {
      marginTop: '12px',
    },
  },
  feesFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    background: theme.palette.primary.light,
    padding: '32px 12px',
    borderRadius: '16px',
  },
  feesFormFieldsWrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',

    '@media (max-width: 1300px)': {
      flexDirection: 'column',
    },
  },
  feesFormContainerDivider: {
    width: '100%',
    height: '1px',
    background: '#515151',
  },
  feesFormButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',

    button: {
      flexBasis: '115px',
    },
  },
  secondaryFirmHeadline: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19.2px',
    color: theme.palette.common.white,
  },
}));

const CaseAttorneyFeesView = () => {
  const styles = useStyles();
  const { caseId } = useParams();
  const { setAlert } = useSnackBar();

  const [isFormSubmitting] = useState(false);
  const [billCheck, setBillCheck] = useState<CaseFeesBillCheck>();
  const [isBillCheckUpdating, setIsBillCheckUpdating] = useState<boolean>(false);
  const [caseSystemName, setCaseSystemName] = useState<string>('');

  // eslint-disable-next-line quotes
  const configLabel = `Participating Firms' Compensation`;

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(caseAttorneysFeesFormSchema),
    defaultValues: CASE_ATTORNEYS_FEES_FORM_DEFAULT_VALUES,
  });

  const {
    formState: { isValid, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
    getValues,
  } = formMethods;

  const { fields } = useFieldArray({
    name: 'secondaryFirms',
    control,
  });

  const handleCalculateCheckBill = (data: unknown) => {
    setIsBillCheckUpdating(true);

    calculateCaseBillCheck(caseId as string, data)
      .then((res) => {
        if (res.data) {
          setBillCheck(res.data);

          setTimeout(() => {
            setIsBillCheckUpdating(false);
            setAlert({
              type: 'info',
              message: 'Bill check was successfully recalculated.',
            });
          }, 3000);
        }
      })
      .catch((err) => {
        setIsBillCheckUpdating(false);
        setAlert({
          message: err.response.data.message,
          type: 'error',
        });
      });
  };

  const onSubmitForm = (values: CaseAttorneyFeesConfigSubmitProps) => {
    const submitData = mapFeesFormValues(values);

    createCaseFeesConfig(caseId as string, submitData)
      .then((res) => {
        if (res.data) {
          setAlert({
            message: 'Attorneys’ fees config updated successfully.',
            type: 'success',
          });

          handleCalculateCheckBill(mapFeesFormValues(values));
        }
      })
      .catch((err) => {
        setAlert({
          message: err.response.data.message,
          type: 'error',
        });
      });
  };

  const handleReset = () => {
    reset();

    const values = getValues();

    handleCalculateCheckBill(mapFeesFormValues(values));
  };

  useEffect(() => {
    getCaseFeesConfig(caseId as string).then((res) => {
      if (res.data) {
        setCaseSystemName(res.data.case.name);

        const secondaryFirms = res.data?.config.secondaryFirms?.length
          ? res.data?.config.secondaryFirms?.map((firm: SecondaryFirmAttorneysFeesConfig) => ({
              ...firm,
              config: {
                fixedFeeAmount: firm.config?.fixedFeeAmount || '',
                variableFeePercent: firm.config?.variableFeePercent || '',
                variableFeeMaximumAmount: firm.config?.variableFeeMaximumAmount || '',
                variableFeeBasis: 'Attorneys’ Fees',
              },
            }))
          : [];

        reset({
          caseFeesConfig: {
            maximumAmount: res.data.config?.maximumAmount || '',
            variableFeePercent: res.data.config?.variableFeePercent || '',
            variableFeeBasis: 'Gross Settlement',
          },
          secondaryFirms: secondaryFirms,
        });
      }
    });
  }, [caseId]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  return (
    <Box className={styles.classes.feesPageContainer}>
      <FormProvider {...formMethods}>
        <Box className={styles.classes.feesFormWrapper}>
          <Box className={styles.classes.feesFormContainer}>
            <Typography className={styles.classes.secondaryFirmHeadline}>{configLabel}</Typography>
            <Box className={styles.classes.feesFormFieldsWrapper}>
              <FormTextField
                name="caseFeesConfig.variableFeePercent"
                label="Variable Fee"
                valueDefinition="%"
              />
              <FormTextField
                name="caseFeesConfig.variableFeeBasis"
                label="Variable Fee Basis"
                readOnly
              />
              <FormTextField
                name="caseFeesConfig.maximumAmount"
                label="Maximum"
                valueDefinition="$"
              />
            </Box>
          </Box>

          {fields.map((field, idx) => (
            <Box key={field.id} className={styles.classes.feesFormContainer}>
              <Typography className={styles.classes.secondaryFirmHeadline}>{`${field.name} - ${
                FIRM_ROLES[field.role as keyof typeof FIRM_ROLES]
              }`}</Typography>

              <Box className={styles.classes.feesFormFieldsWrapper}>
                <FormTextField
                  name={`secondaryFirms.${idx}.config.fixedFeeAmount`}
                  label="Fixed Fee"
                  valueDefinition="$"
                />
                <FormTextField
                  name={`secondaryFirms.${idx}.config.variableFeePercent`}
                  label="Variable Fee"
                  valueDefinition="%"
                />
                <FormTextField
                  name={`secondaryFirms.${idx}.config.variableFeeBasis`}
                  label="Variable Fee Basis"
                  readOnly
                />
                <FormTextField
                  name={`secondaryFirms.${idx}.config.variableFeeMaximumAmount`}
                  label="Maximum"
                  valueDefinition="$"
                />
              </Box>
            </Box>
          ))}

          <Box className={styles.classes.feesFormButtonsContainer}>
            {isDirty && <FormCancelButton onClick={handleReset} />}
            <FormSubmitButton
              disabled={!isValid || !isDirty || isFormSubmitting}
              buttonIcon={<SaveIcon />}
              buttonText="Save"
              onClick={handleSubmit(onSubmitForm)}
            />
          </Box>
        </Box>
        <CasesFeesSummary
          isBillCheckUpdating={isBillCheckUpdating}
          billCheck={billCheck}
          setBillCheck={setBillCheck}
          handleCalculateCheckBill={handleCalculateCheckBill}
          caseSystemName={caseSystemName}
        />
      </FormProvider>
    </Box>
  );
};

export default CaseAttorneyFeesView;
