import api from '../service/api.service';

import { ActiveSortField, PaginationQueryParams } from '../../types';
import { parseQueryFilters } from '../../helpers/parseQueryFilters';
import { parseQuerySortFields } from '../../helpers/parseQuerySortFields';

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/v4/cases`;
const BASE_CASE_FEES_CONFIG_URL = `${process.env.REACT_APP_BASE_API_URL}/v3/cases`;

export const createCaseRecord = async (data: unknown) => {
  return await api.post(BASE_URL, data);
};

export const getCasesList = async ({
  size,
  page,
  count,
  match,
  filters,
  activeSortField,
}: PaginationQueryParams) => {
  const queryFilters = parseQueryFilters(filters);
  const sortFilters = parseQuerySortFields(activeSortField as ActiveSortField);

  const targetUrl = `${BASE_URL}?size=${size}&page=${page}&count=${count}&match=${match || ''}${
    queryFilters || ''
  }${sortFilters}`;

  return await api.get(targetUrl);
};

export const getCaseRecord = async (caseId: string) => {
  const targetUrl = `${BASE_URL}/${caseId}`;

  return await api.get(targetUrl);
};

export const updateCaseRecord = async (caseId: string, data: unknown) => {
  const targetUrl = `${BASE_URL}/${caseId}`;

  return await api.put(targetUrl, data);
};

export const getCaseFeesConfig = async (caseId: string) => {
  const targetUrl = `${BASE_CASE_FEES_CONFIG_URL}/${Number(caseId)}/atty-fees-config`;

  return await api.get(targetUrl);
};

export const createCaseFeesConfig = async (caseId: string, data: unknown) => {
  const targetUrl = `${BASE_CASE_FEES_CONFIG_URL}/${Number(caseId)}/atty-fees-config`;

  return await api.put(targetUrl, data);
};

export const getCaseFeesBillCheck = async (caseId: string) => {
  const targetUrl = `${BASE_CASE_FEES_CONFIG_URL}/${Number(
    caseId,
  )}/atty-fees-config/payment-preview`;

  return await api.get(targetUrl);
};

export const calculateCaseBillCheck = async (caseId: string, data: unknown) => {
  const targetUrl = `${BASE_CASE_FEES_CONFIG_URL}/${Number(
    caseId,
  )}/atty-fees-config/payment-preview-by-config`;

  return await api.post(targetUrl, data);
};

export const submitCase = async (caseId: string) => {
  const targetUrl = `${BASE_URL}/${caseId}/submit`;

  return await api.put(targetUrl);
};

export const abandonCase = async (caseId: string) => {
  const targetUrl = `${BASE_URL}/${caseId}/abandon`;

  return await api.put(targetUrl);
};
