import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { InputBaseProps } from '@mui/material/InputBase';
import {
  FilledInputProps,
  IconButton,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
  Theme,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { validateTextFieldValue } from '../../helpers/validateTextFieldValue';
import { getTextFieldErrorMessage } from '../../helpers/getTextFieldErrorMessage';

export const useStyles = makeStyles<{
  maxWidth?: string;
  disabled?: boolean;
  minWidth?: string;
  isEncrypted?: boolean;
}>()((theme: Theme, { maxWidth, disabled, minWidth, isEncrypted }) => ({
  defaultTextField: {
    ...(maxWidth && { maxWidth }),
    ...(minWidth && { minWidth }),
    background: disabled ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '12px',
    input: {
      padding: '12px 16px',
      color: theme.palette.common.white,

      '&.Mui-disabled': {
        color: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
        WebkitTextFillColor: isEncrypted ? theme.palette.common.white : theme.palette.text.disabled,
      },
    },

    '& .MuiOutlinedInput-root': {
      color: theme.palette.text.disabled,
      borderRadius: '12px',

      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.error.main,
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
        borderRadius: '12px',
      },

      '&:hover': {
        border: 'none',

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.info.main,
        },
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
          borderColor: 'none',
        },
      },
    },

    '& .MuiInputLabel-root': {
      color: theme.palette.text.disabled,

      '&[data-shrink="false"]': {
        top: '-3px',
      },

      '&[data-shrink="true"]': {
        color: theme.palette.text.disabled,
      },

      '&.Mui-error': {
        color: theme.palette.error.main,
      },

      '&.Mui-focused': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: '14px',
    },
  },
  readOnlyText: {
    '& .MuiInput-root': {
      maxWidth: '470px',
      color: theme.palette.primary.light,
    },
  },
  multilinePadding: {
    textarea: {
      color: theme.palette.common.white,
    },
  },
}));

interface FormSSNFieldProps {
  name: string;
  label: string;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
  handleChange?: (value: string) => void;
  isMultiline?: boolean;
  inputLabelProps?: Partial<InputLabelProps>;
  inputProps?: InputBaseProps['inputProps'];
  maxRows?: number;
  minWidth?: string;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  valueDefinition?: string;
  isOnlyText?: boolean;
  onBlur?: (value: string) => void;
  isEncrypted?: boolean;
  getEncryptedFieldValue?: () => void;
  isValueVisible?: boolean;
  setIsValueVisible?: () => void;
}

const FormSSNField = ({
  name,
  label,
  handleChange,
  disabled,
  maxWidth,
  inputLabelProps,
  inputProps,
  InputProps,
  minWidth,
  onBlur,
  isEncrypted,
  getEncryptedFieldValue,
  isValueVisible,
  setIsValueVisible,
}: FormSSNFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const styles = useStyles({ maxWidth, disabled, minWidth, isEncrypted });

  const textFieldKeyName = `${name}.message`;

  const helperText = errors && getTextFieldErrorMessage(errors, textFieldKeyName);

  const getVisiblePropValue = () => {
    if (isEncrypted) {
      if (isValueVisible) {
        return 'text';
      } else {
        return 'password';
      }
    }
  };

  useEffect(() => {
    isValueVisible && getEncryptedFieldValue && getEncryptedFieldValue();
  }, [isValueVisible]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            fullWidth
            type={getVisiblePropValue()}
            variant="outlined"
            className={styles.classes.defaultTextField}
            label={label}
            onBlur={(e) => onBlur?.(e.target.value)}
            onChange={(e) =>
              handleChange
                ? handleChange(e.target.value)
                : onChange(validateTextFieldValue(e.target.value))
            }
            helperText={helperText}
            value={value}
            error={!!helperText}
            disabled={disabled}
            inputProps={inputProps}
            InputProps={{
              ...InputProps,
              disabled: isEncrypted,
              endAdornment: isEncrypted && (
                <IconButton
                  disableRipple
                  sx={{
                    color: 'white',
                  }}
                  onClick={setIsValueVisible}
                >
                  {isValueVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            InputLabelProps={inputLabelProps}
          />
        );
      }}
    />
  );
};

export default FormSSNField;
